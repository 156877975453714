import * as React from 'react';
import { StyleSheet, ImageBackground, Text as Inline } from 'react-native';
import { Box, useTheme, useThemeProps } from 'native-base';
import { LinearGradient } from 'expo-linear-gradient';

export const WarmBox = ({style, backgroundImage, onPress, ...props}) => {
  // TODO: See if the inline styles here can be moved to the component config file as variants
  const { colors, components } = useTheme();
  const { shadow, ...resolvedProps} = useThemeProps('WarmBox', props);
  if (backgroundImage) resolvedProps.style.background = 'none';
  resolvedProps.style.height = '100%';
  const getColorValueArray = colors => {
    return Object.keys(colors).map(colorKey => {
      return colors[colorKey];
    });
  };
  const warmColors = getColorValueArray(colors.warm);
  return (
    <Box style={style} shadow={shadow} onPress={onPress}>
      <LinearGradient style={styles.gradient} colors={warmColors} start={[0, 0]} end={[1, 0]} />
      <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
        <Box {...props} {...resolvedProps} />
      </ImageBackground>
    </Box>
  );
};

const styles = StyleSheet.create({
  gradient: {
    width: '100%',
    height: 5, // This is 1px in Figma, but needs to be bigger to show up
    marginBottom: 0,
    marginTop: -5,
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
});
