import * as React from 'react';
import { StyleSheet, Text as Inline } from 'react-native';
import { Heading, Text, Column, Row, Box, Center, useColorMode } from 'native-base';

import ScreenWrapper from '../ScreenWrapper';
import { WarmBox } from './WarmBox';

export const Boxes = () => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark';
  return (
    <ScreenWrapper>
      <Row space={4} direction='column'>
        <Column space={1} bg={isDark ? 'primary.700' : 'white'} style={styles.content}>
          <Heading size='sm'>On Default Background</Heading>
          <WarmBox innerStyle={styles.content} style={styles.box}>
            <Heading style={styles.heading}>Hello</Heading>
            <Text>I am a box!</Text>
          </WarmBox>
          <WarmBox variant='alt' innerStyle={styles.content} style={styles.box}>
            <Heading variant='alt' style={styles.heading}>
              Hello
            </Heading>
            <Text variant='alt'>I am an alt box!</Text>
          </WarmBox>
        </Column>
        <Column space={1} bg={isDark ? 'white' : 'primary.700'} style={styles.content}>
          <Heading color={isDark ? 'primary.700' : 'white'} size='sm'>
            On Alt Background
          </Heading>
          <WarmBox variant='alt-alt-bg' innerStyle={styles.content} style={styles.box}>
            <Heading variant='alt' style={styles.heading}>
              Hello
            </Heading>
            <Text variant='alt'>I am an alt box!</Text>
          </WarmBox>
          <WarmBox variant='alt-bg' innerStyle={styles.content} style={styles.box}>
            <Heading style={styles.heading}>Hello</Heading>
            <Text>I am a box!</Text>
          </WarmBox>
        </Column>
      </Row>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  box: {
    marginBottom: 20
  },
  content: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  heading: {
    marginTop: 0,
  },
});
