import { extendTheme } from 'native-base';
import components from './components';
import colors from './colors';

const theme = extendTheme({
  components,
  colors,
  shadows: {
    'cc-button': {
      'box-shadow': '-10px 10px 20px rgba(255, 255, 255, 0.1)',
    },
    'cc-button-hover': {
      'box-shadow': '-10px 10px 20px rgba(255, 255, 255, 0.2)',
    },
    'cc-button-light': {
      'box-shadow': '-10px 10px 20px rgba(3, 40, 73, 0.1)',
    },
    'cc-button-light-hover': {
      'box-shadow': '-10px 10px 20px rgba(3, 40, 73, 0.2)',
    },
    'cc-card-dark-bg': {
      'box-shadow': '-10px 10px 30px 0px rgba(255, 255, 255, 0.05)',
    },
    'cc-card-light-bg': {
      'box-shadow': '-10px 10px 30px 0px rgba(1, 40, 68, 0.15)',
    },
    'google-maps': {
      'box-shadow': '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    },
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
});

export default theme;
