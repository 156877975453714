import React, { useContext, useEffect, useRef } from 'react';
import { Linking, StyleSheet } from 'react-native';
import { NavigationContainer, DefaultTheme, useNavigationContainerRef } from '@react-navigation/native';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
import { useQuery } from 'react-query';
import { useTheme, useColorModeValue, Icon, Text, IconButton, View } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import ReactGA from 'react-ga4';
import UiContext from './UiContext';
import ApiContext from './ApiContext';
import { MapScreen } from './components/MapScreen';
import { ExploreScreen } from './components/ExploreScreen';
import { MapOnlyScreen } from './components/MapOnlyScreen';
import { ButtonExample } from './components/Buttons';
import { Colors } from './components/Colors';
import { TextStyles } from './components/TextStyles';
import { Boxes } from './components/Boxes';
import LogoIcon from './components/LogoIcon';
import { Header } from './components/Header';
import RiskEstimateModal from './components/modals/RiskEstimateModal';
import SlrProjectionModal from './components/modals/SlrProjectionModal';
import FloodProjectionModal from './components/modals/FloodProjectionModal';
import SolutionModal from './components/modals/SolutionModal';
import { fetchEndpoint } from './api';
import { getURLParamsFromNestedSettings, validateSettings, useIsTabLayout, isWithinUSA } from './utils';
import { Footer } from './components/Footer';

const TRACKING_ID = process.env.CRF_GA_ID;
if (TRACKING_ID) {
  ReactGA.initialize([
    {
      trackingId: TRACKING_ID,
      // gaOptions: {...},
      // gtagOptions: {...},
    }
  ]);
} else {
  console.warn('No Google Analytics tracking ID found. Tracking is disabled.');
}

const Drawer = createDrawerNavigator();

// NOTE: This comes after the drawer's children
const CustomDrawerContent = (props) => {
  const isTabLayout = useIsTabLayout();
  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
      {isTabLayout && (
        <>
          <DrawerItem
            label="About Us"
            onPress={() => Linking.openURL('https://www.climatecentral.org/what-we-do')}
          />
          <DrawerItem
            label="Newsletter Signup"
            onPress={() => Linking.openURL('https://www.climatecentral.org/list-signup')}
          />
          <DrawerItem
            label="Support Our Work"
            onPress={() => Linking.openURL('https://giving.climatecentral.org/give/155650/#!/donation/checkout')}
          />
          <DrawerItem
            label="Commercial Solutions"
            onPress={() => Linking.openURL('https://go.climatecentral.org/products/')}
          />
          <DrawerItem
            label="Disclaimer"
            onPress={() => Linking.openURL('https://sealevel.climatecentral.org/about/disclaimer')}
          />
          <DrawerItem
            label="Terms of Use"
            onPress={() => Linking.openURL('https://www.climatecentral.org/what-we-do/legal#content_licensing')}
          />
          <DrawerItem
            label="Privacy Policy"
            onPress={() => Linking.openURL('https://www.climatecentral.org/privacy-policy')}
          />
          <DrawerItem
            label="Contact Us"
            onPress={() => Linking.openURL('https://sealevel.climatecentral.org/about/contact')}
          />
        </>
      )}
      <Text style={styles.copyright} color='primary.700'>Copyright &copy; {new Date().getFullYear()} Climate Central</Text>
    </DrawerContentScrollView>
  );
}

const Router = ({ linking }) => {
  const uiContext = useContext(UiContext);
  const theme = useTheme();
  const textColor = useColorModeValue(theme.colors.primary[700], theme.colors.white);
  const isTabLayout = useIsTabLayout();
  const { latitude: lat, longitude: lng } = uiContext.explore.region;
  const isUSA = isWithinUSA(lng, lat);
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef();
  const routePathRef = useRef();
  const route = (navigationRef.isReady() && navigationRef.getCurrentRoute()) || {};
  // Default to New Jersey if no region is set
  const currentRegion = uiContext.explore.defaultRegion || 'usa.nj';
  // Default to bounding box of New Jersey if no bbox is set (used for Stats page)
  const currentBbox = uiContext.bbox || [
    -73.25370423296341,
    41.250887325393954,
    -74.68879336703655,
    40.31191466935272
  ];

  const handleError = (msg, err) => {
    const errorMessage = `${msg} (${err.message || err}) Please refresh the page.`;
    console.error('API error:', { msg, err });
    if (uiContext.banners.find(banner => banner.text === errorMessage)) return;
    uiContext.addBanner({
      type: 'error',
      text: errorMessage,
    });
  };

  useEffect(() => {
    routeNameRef.current = navigationRef.current.getCurrentRoute().name;
    routePathRef.current = navigationRef.current.getCurrentRoute().path;
    // Track initial page view
    if (TRACKING_ID) {
      console.log('Tracking initial page view:', routeNameRef.current);
      ReactGA.send({ hitType: "pageview", page: routePathRef.current, title: routeNameRef.current });
    }
    uiContext.addBanner({
      type: 'info',
      text: 'Welcome to the Beta Version of Coastal Risk Finder! All analyses are preliminary and under embargo. Please do not share any information, screenshots, or content related to Coastal Risk Finder outside of this platform. Your feedback is valuable to us! If you encounter an issue or have a suggestion, please let us know at',
      link: {
        href: 'mailto:slr-contact@climatecentral.org',
        label: 'slr-contact@climatecentral.org.',
      }
    });
  }, []);

  // NOTE: This links the unit_system parameter to the water_unit and temperature_unit settings so they are always matching
  const water_unit = uiContext.contentCard.unitSystem === 'metric' ? 'm' : 'ft';
  const temperatureUnit = uiContext.contentCard.unitSystem === 'metric' ? 'C' : 'F';

  const waterUnitSetting = uiContext.explore.mapType.includes('water_level') && !uiContext.explore.mapSettings.water_unit && { water_unit };
  const temperatureUnitSetting = uiContext.explore.mapTheme.includes('warming') && !uiContext.explore.mapSettings.temperature_unit && { temperature_unit: temperatureUnit };

  const mapTypes = useQuery(['maptypes', { lat, lng, enabled: true, crf_enabled: true }], fetchEndpoint, {
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading map types.', e)
  });
  const mapSettings = useQuery(['mapsettings', {
      lat,
      lng,
      theme: uiContext.explore.mapTheme,
      map_type: uiContext.explore.mapType,
      ...waterUnitSetting,
      ...temperatureUnitSetting,
      ...uiContext.explore.mapSettings
    }], fetchEndpoint, {
    enabled: !!(lat && lng), // This request will return incorrect results if lat and lng aren't passed
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onSuccess: response => {
      const validSettings = validateSettings(response, uiContext.explore.mapSettings, uiContext.addBanner);
      uiContext.setExplore({ ...uiContext.explore, mapSettings: validSettings });
    },
    onError: e => handleError('Error loading map settings.', e),
  });
  const mapInfo = useQuery(
    ['mapinfo', { theme: uiContext.explore.mapTheme, map_type: uiContext.explore.mapType, ...uiContext.explore.mapSettings, isUSA }],
    fetchEndpoint,
    {
      meta: {
        // We want to use lat/lng in the query, but they shouldn't be included in the queryKey
        // This is so that a refetch doesn't happen when the user pans the map
        ...(lat && lng && { lat, lng }),
      },
      refetchOnWindowFocus: false,
      onError: e => handleError('Error loading map info.', e),
    }
  );
  const levees = useQuery(['levees'], fetchEndpoint, {
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading levee data.', e)
  });
  const waterbodies = useQuery(['waterbodies', { color: '0d2846' }], fetchEndpoint, {
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading waterbodies.', e),
  });
  const mapScenario = useQuery(['map-scenario', {
      theme: uiContext.explore.mapTheme,
      map_type: uiContext.explore.mapType,
      ...waterUnitSetting,
      ...temperatureUnitSetting,
      ...uiContext.explore.mapSettings
    }], fetchEndpoint, {
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onSuccess: response => {
      uiContext.setExplore({ ...uiContext.explore, scenarioId: response.id });
    },
    onError: e => handleError('Error loading map scenario from back end.', e),
  });
  const defaultRegion = useQuery(['default-region', {
    bbox: currentBbox,
    region_type: uiContext.explore.regionType,
  }], fetchEndpoint, {
    refetchOnWindowFocus: false, retry: false,
    meta: {
      url: process.env.CRF_API_URL,
    },
    enabled: !!uiContext.bbox || !!( route.name === 'Stats' && currentBbox ),
    onError: e => handleError('Error loading region slug from back end.', e),
    onSuccess: response => {
      const name = response.name;
      if (name && name.length > 3) {
        uiContext.setExplore({ ...uiContext.explore, defaultRegion: name });
      }
    },
  });
  const risks = useQuery(['risks', {
    scenario_id: uiContext.explore.scenarioId,
    unit_system: uiContext.contentCard.unitSystem,
    region: currentRegion,
    contig_adjusted_dem: true,
    }], fetchEndpoint, {
    enabled: !!uiContext.explore.scenarioId,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading risks from back end.', e),
  });
  const riskEstimate = useQuery(['risk-estimate', {
    region_risk_estimate_id: uiContext.explore.riskEstimateId,
    unit_system: uiContext.contentCard.unitSystem,
    region: currentRegion,
    }], fetchEndpoint, {
    enabled: route.name === 'RiskEstimate' && !!uiContext.explore.riskEstimateId,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading risk estimate from back end.', e),
  });
  const choroplethData = useQuery(['choropleth-data', {
    region_risk_estimate_id: uiContext.explore.riskEstimateId,
    unit_system: uiContext.contentCard.unitSystem,
  }], fetchEndpoint, {
    enabled: route.name === 'RiskEstimate' && !!uiContext.explore.riskEstimateId,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading choropleth data from back end.', e),
  });
  const floodProjectionQuery = {
    scenario_id: uiContext.explore.scenarioId,
    unit_system: uiContext.contentCard.unitSystem,
    region: currentRegion,
    projection_type: uiContext.contentCard.projectionType,
    projection_format: uiContext.contentCard.projectionFormat,
    min_probability: uiContext.contentCard.minProbability,
    lang: 'en'
  };
  const floodProjectionQueryString = Object.keys(floodProjectionQuery).map(key => `${key}=${floodProjectionQuery[key]}`).join('&');
  const floodProjection = useQuery(['flood-projection', {
    scenario_id: uiContext.explore.scenarioId,
    unit_system: uiContext.contentCard.unitSystem,
    region: currentRegion,
    projection_type: uiContext.contentCard.projectionType,
    projection_format: uiContext.contentCard.projectionFormat,
    min_probability: uiContext.contentCard.minProbability,
    }], fetchEndpoint, {
    enabled: route.name === 'FloodProjection' && !!uiContext.explore.scenarioId,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError(`Error loading flood projection data from back end: /flood-projection/?${floodProjectionQueryString}`, e),
  });
  const floodTimeSeriesQuery = {
    unit_system: uiContext.contentCard.unitSystem,
    region: currentRegion,
    scenario_id: uiContext.explore.scenarioId,
    projection_type: uiContext.contentCard.projectionType,
    projection_format: uiContext.contentCard.projectionFormat,
    min_probability: uiContext.contentCard.minProbability,
    lang: 'en'
  };
  const floodTimeSeriesQueryString = Object.keys(floodTimeSeriesQuery).map(key => `${key}=${floodTimeSeriesQuery[key]}`).join('&');
  const floodTimeseries = useQuery(['flood-timeseries', floodTimeSeriesQuery], fetchEndpoint, {
    enabled: route.name === 'FloodProjection' && !!uiContext.explore.scenarioId,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError(`Error loading flood timeseries data from back end: /flood-timeseries/?${floodTimeSeriesQueryString}`, e),
    onSuccess: response => {
      if (!response || !response.data) {
        const errorMessage = `Back end query /flood-timeseries/?${floodTimeSeriesQueryString} returned no data`;
        console.log(errorMessage)
        uiContext.setExplore({ ...uiContext.explore, floodTimeseriesError: errorMessage });
      }
    }
  });
  const slrProjection = useQuery(['slr-projection', {
    scenario_id: uiContext.explore.scenarioId,
    unit_system: uiContext.contentCard.unitSystem,
    region: currentRegion,
    }], fetchEndpoint, {
    enabled: route.name === 'SlrProjection' && !!uiContext.explore.scenarioId,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading slr projection data from back end.', e),
  });
  const slrTimeseries = useQuery(['slr-timeseries', {
    unit_system: uiContext.contentCard.unitSystem,
    region: currentRegion,
    scenario_id: uiContext.explore.scenarioId,
  }], fetchEndpoint, {
    enabled: route.name === 'SlrProjection' && !!uiContext.explore.scenarioId,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading slr timeseries data from back end.', e),
  });
  const projections = useQuery(['projections', {
    scenario_id: uiContext.explore.scenarioId,
    unit_system: uiContext.contentCard.unitSystem,
    region: currentRegion,
    }], fetchEndpoint, {
    enabled: !!uiContext.explore.scenarioId,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading projections from back end.', e),
  });
  const solutions = useQuery(['solutions', {
    region: currentRegion,
    }], fetchEndpoint, {
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading solutions from back end.', e),
  });
  const solution = useQuery(['solution', {
    name: uiContext.solutionName,
    }], fetchEndpoint, {
    enabled: !!uiContext.solutionName,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading solution from back end.', e),
  });
  const isValidRiskEstimateId = !isNaN(parseInt(uiContext.explore.riskEstimateId));
  const relatedRiskEstimates = useQuery(['related-risk-estimates', {
    unit_system: uiContext.contentCard.unitSystem,
    region_risk_estimate_id: uiContext.explore.riskEstimateId,

  }], fetchEndpoint, {
    enabled: !!uiContext.explore.riskEstimateId && isValidRiskEstimateId,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading related risk estimates from back end.', e),
  });
  const relatedFloodProjections = useQuery(['related-flood-projections', {
    unit_system: uiContext.contentCard.unitSystem,
    region: currentRegion,
    scenario_id: uiContext.explore.scenarioId,
    projection_type: uiContext.contentCard.projectionType,
    projection_format: uiContext.contentCard.projectionFormat,
    min_probability: uiContext.contentCard.minProbability,
  }], fetchEndpoint, {
    enabled: route.name === 'FloodProjection' && !!uiContext.explore.scenarioId,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading related flood projections  from back end.', e),
  });
  const relatedSLRProjections = useQuery(['related-slr-projections', {
    unit_system: uiContext.contentCard.unitSystem,
    region: currentRegion,
    scenario_id: uiContext.explore.scenarioId,
  }], fetchEndpoint, {
    enabled: route.name === 'SlrProjection' && !!uiContext.explore.scenarioId,
    meta: {
      url: process.env.CRF_API_URL,
    },
    refetchOnWindowFocus: false, retry: false,
    onError: e => handleError('Error loading related SLR projections from back end.', e),
  });
  const apiContext = {
    mapTypes,
    mapSettings,
    mapInfo,
    levees,
    waterbodies,
    mapScenario,
    risks,
    riskEstimate,
    choroplethData,
    floodProjection,
    floodTimeseries,
    slrProjection,
    slrTimeseries,
    projections,
    solutions,
    solution,
    defaultRegion,
    relatedRiskEstimates,
    relatedFloodProjections,
    relatedSLRProjections,
  };

  const screenOptions = ({ navigation }) => {
    return {
      headerStyle: {
        backgroundColor: isTabLayout ? theme.colors.white : theme.colors.primary[700],
      },
      headerBackgroundContainerStyle: {
        boxShadow: '-10px 10px 20px rgba(3, 40, 73, 0.1)',
      },
      headerTintColor: textColor,
      header: props => <Header {...props} onLayout={uiContext.setHeader} headerShadowVisible={true} />,
      headerLeft: props => <LogoIcon color={isTabLayout ? 'tertiary.700' : theme.colors.white} {...props} />,
      headerRight: props => {
        const showMenu = isTabLayout || __DEV__;
        return showMenu ? <IconButton
          icon={<Icon as={MaterialCommunityIcons} name='menu' color={'primary.700'} size={25} />}
          onPress={navigation.toggleDrawer}
          {...props}
        /> : null;
      },
      drawerPosition: 'right',
      drawerType: 'back',
    };
  };
  return (
    <ApiContext.Provider value={apiContext}>
      <NavigationContainer
        ref={navigationRef} 
        onReady={() => {
          routeNameRef.current = navigationRef.current.getCurrentRoute().name;
        }}
        onStateChange={() => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.current.getCurrentRoute().name;
          const currentRoutePath = navigationRef.current.getCurrentRoute().path;

          if (previousRouteName !== currentRouteName) {
            if (TRACKING_ID) {
              console.log('Tracking page view:', currentRouteName);
              ReactGA.send({ hitType: "pageview", page: currentRoutePath, title: currentRouteName });
            }
            routeNameRef.current = currentRouteName;
          }
        }}
        theme={DefaultTheme}
        linking={linking}
        fallback={<Text testID="router-placeholder">Loading...</Text>}>
        <Drawer.Navigator initialRouteName='Explore' screenOptions={screenOptions} initialParams={getURLParamsFromNestedSettings(uiContext.explore)} drawerContent={(props) => <CustomDrawerContent {...props} />}>
          <Drawer.Screen name='Explore' component={ExploreScreen} options={{ title: 'Explore' }} />
          <Drawer.Screen name="RiskEstimate" component={RiskEstimateModal} screenOptions={{ presentation: 'modal' }} options={{ drawerItemStyle: { display: 'none' } }} />
          <Drawer.Screen name="SlrProjection" component={SlrProjectionModal} screenOptions={{ presentation: 'modal' }} options={{ drawerItemStyle: { display: 'none' } }} />
          <Drawer.Screen name="FloodProjection" component={FloodProjectionModal} screenOptions={{ presentation: 'modal' }} options={{ drawerItemStyle: { display: 'none' } }} />
          <Drawer.Screen name="Solution" component={SolutionModal} screenOptions={{ presentation: 'modal' }} options={{ drawerItemStyle: { display: 'none' } }} />
          <Drawer.Screen options={{ headerShown: false, drawerItemStyle: { display: 'none' } }} name="screenshot" component={MapOnlyScreen} />
          <Drawer.Screen options={{ headerShown: false, drawerItemStyle: { display: 'none' } }} name="embed" component={MapScreen} />
          {/* If development mode */}
          {__DEV__ && (<>
            <Drawer.Screen
              name='Colors'
              component={Colors}
              options={{
                title: 'Colors',
              }}
            />
            <Drawer.Screen
              name='Text'
              component={TextStyles}
              options={{
                title: 'Text',
              }}
            />
            <Drawer.Screen
              name='Buttons'
              component={ButtonExample}
              options={{
                title: 'Buttons',
              }}
            />
            <Drawer.Screen
              name='Boxes'
              component={Boxes}
              options={{
                title: 'Boxes',
              }}
            />
            <Drawer.Screen
              name='Map Screen'
              component={MapScreen}
              options={{
                title: 'Map',
              }}
            />
          </>)}
        </Drawer.Navigator>
        {!isTabLayout && <Footer />}
      </NavigationContainer>
    </ApiContext.Provider>
  );
};

export default Router;

const styles = StyleSheet.create({
  copyright: {
    padding: '8px',
    margin: '10px',
  },
});
