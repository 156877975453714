import { NativeBaseProvider, Popover, Icon, IconButton, Text, View, Radio, Heading, useDisclose } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet } from 'react-native';
import theme from '../nativebase/theme';
import { baseMaps } from '../fixtures/mapStyles';

function BasemapControl({ explore, setExplore, setMapType, isOpen, onOpen, onClose }) {
  return (
    <View style={{ marginRight: 10 }} >
      <NativeBaseProvider theme={theme}>
        <Popover
          trigger={(triggerProps) => {
            return (
              <View>
                <IconButton variant='googlemaps' icon={<Icon as={MaterialCommunityIcons} name='map-legend' size='md' />} {...triggerProps} />
              </View>
            );
          }}
          placement='left top'
          offset={10}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        >
          <Popover.Content w="150" accessibilityLabel="Select layers" style={styles.container} >
            <Heading size='sm' variant='menuhead' color='primary.700'><Icon color='primary.700' as={MaterialCommunityIcons} name='map-legend' size={18} /> Basemap</Heading>
            <Radio.Group value={explore.basemap} accessibilityLabel="Select Basemap" onChange={(mapType)=>{
              onClose();
              setMapType(mapType);
              setExplore({...explore, basemap: mapType});
            }}>
              {baseMaps.map((base) => (
                <Radio
                  _stack={{ backgroundColor: 'primary.100', borderBottomColor: 'primary.300', w: 200, borderBottomWidth: 1, paddingLeft: 3, paddingTop: 1, paddingBottom: 1 }}
                  key={base.slug}
                  value={base.slug}
                  my={1}
                  colorScheme={'none'}
                  icon={<Icon as={MaterialCommunityIcons} name='check-bold' size={24} />}
                  _checked={{ bg: 'tertiary.600', borderColor: 'tertiary.600' }}
                  _icon={{ color: 'white' }}
                  _dark={{ bg: 'primary.100', borderWidth: 1 }}
                  size='sm'
                >
                  <Text variant='layer-label' mx={2} color={'black'}>{base.name}</Text>
                </Radio>
              ))}
            </Radio.Group>
          </Popover.Content>
        </Popover>
      </NativeBaseProvider>
    </View>
  );
}

export default BasemapControl;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(0, 0, 0)',
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    borderRadius: 2,
    borderWidth: 0,
    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    width: 150,
  }
});
