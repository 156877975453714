import { Modal, Button, Icon, Text, Select, Heading } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet } from 'react-native';

const imageSizes = {
  '1920x1080': '16:9',
  '1600x1200': '4:3',
  '1080x1080': 'Square',
  '1650x1275': 'US Letter Landscape',
  '1275x1650': 'US Letter Portrait',
  '1754x1240': 'A4 Landscape',
  '1240x1754': 'A4 Portrait',
};

function DownloadCustomizeModal({ show, toggle, back, next, downloadDimensions, setDownloadDimensions }) {
  return (
    <Modal isOpen={show} onClose={toggle} borderRadius={0}>
      <Modal.Content maxWidth="350" bg='primary.100' borderRadius={0}>
        <Modal.CloseButton />
        <Modal.Header bg='primary.300' color='primary.900' _text={{
          color: 'primary.900',
        }}>Customize Your Map Image</Modal.Header>
        <Modal.Body>
          <Heading variant='label' size='xs' color='primary.700'>Image Dimensions</Heading>
          <Select  color='primary.700' selectedValue={downloadDimensions} accessibilityLabel="Choose Download Dimensions" placeholder="Choose Download Dimensions" mt={1} onValueChange={d => setDownloadDimensions(d)}>
            {Object.entries(imageSizes).map(([size, name]) => <Select.Item key={size} label={name} value={size} color='primary.700' />)}
        </Select>
        </Modal.Body>
        <Modal.Footer bg='primary.100'>
          <Button.Group space={2}>
            <Button
              variant='cclink-light'
              startIcon={<Icon as={MaterialCommunityIcons} name='arrow-left' size={18} />}
              style={styles.button}
              onPress={back}
            >
              Back
            </Button>
            <Button
              variant='cclink-light'
              startIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={18} />}
              style={styles.button}
              onPress={next}
            >
              Continue
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

export default DownloadCustomizeModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(0, 0, 0)',
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    borderRadius: 2,
    borderWidth: 0,
    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    left: 10,
  }
});
