import React, { useState, useRef, useEffect } from 'react';
import { Box, Pressable, Text, Icon, Row, Heading } from 'native-base';
import { Animated, View, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useIsPhoneSized } from '../utils';

const Accordion = ({ title, children, isSettings }) => {
  const [expanded, setExpanded] = useState(false);
  const animationController = useRef(new Animated.Value(0)).current;
  const contentRef = useRef(null);
  const isPhoneSized = useIsPhoneSized();

  const toggleAccordion = () => {
    if (!expanded) {
      // Expand the accordion
      contentRef.current.measure((x, y, width, height) => {
        Animated.timing(animationController, {
          toValue: height,
          duration: 300,
          useNativeDriver: false,
        }).start();
      });
    } else {
      // Collapse the accordion
      Animated.timing(animationController, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start();
    }
    setExpanded(!expanded);
  };

   useEffect(() => {
    // Update the container height if children change
    if (expanded) {
      contentRef.current.measure((x, y, width, height) => {
        Animated.timing(animationController, {
          toValue: height,
          duration: 300,
          useNativeDriver: false,
        }).start();
      });
    }
  }, [children]);

  return (
    <Box overflow="hidden" marginLeft={isSettings?"-60":'0'} marginRight={isSettings?isPhoneSized?'-60':"120":'auto'} marginTop={isSettings?isPhoneSized?'50':"150":'auto'}>
      {isSettings?<Pressable onPress={toggleAccordion}>
        <Box px="4" py="2" style={styles.header}>
          <Row justifyContent={isPhoneSized?'flex-start':'flex-end'}>
            <Icon as={MaterialCommunityIcons} name={expanded ? 'chevron-down' : 'chevron-left'} size="xl" style={styles.collapseIcon} />
            <Text color="white" bold fontSize='xl'>{title}</Text>
          </Row>
        </Box>
      </Pressable>:<Pressable onPress={toggleAccordion}>
        <Box px="4" py="2" style={styles.smallHeader}>
          <Row justifyContent={'flex-start'}>
            <Heading variant='subhead' size='md'>{title}</Heading>
            <Icon as={MaterialCommunityIcons} name={expanded ? 'chevron-down' : 'chevron-right'} size="xl" style={styles.collapseIconBody} />
          </Row>
        </Box>
      </Pressable>}
      <Animated.View style={{ height: animationController }}>
        <View ref={contentRef}>
          <Box px="4" py="2" style={(!isSettings || isPhoneSized)?styles.phoneBody:styles.body}>
            {children}
          </Box>
        </View>
      </Animated.View>
    </Box>
  );
};

export default Accordion;

const styles = StyleSheet.create({
  header: {
    backgroundColor: 'rgba(3,40,73, 0.5)',
    padding: 15,
  },
  smallHeader: {
    padding: 10,
  },
  collapseIcon: {
    color: 'white',
  },
  collapseIconBody: {
    color: 'white',
    lineHeight: 64,
  },
  body: {
    color: 'white',
    padding: 20,
    paddingLeft: 120,
    backgroundColor: 'rgba(3,40,73, 0.5)',
  },
  phoneBody: {
    color: 'white',
    padding: 20,
    backgroundColor: 'rgba(3,40,73, 0.5)',
  },
});

