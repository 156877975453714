import React, { useState } from 'react';
import { Tooltip, Button, Icon } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useIsPhoneSized } from '../utils';

// This wrapper is required so that the Tooltip can be toggled on press for mobile
function TooltipWrapper(props) {
  const [isOpen, setIsOpen] = useState(false);
  const isPhoneSized = useIsPhoneSized();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Tooltip
      {...props}
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      openDelay={isPhoneSized?0:500}
      closeDelay={isPhoneSized?5000:500}
    >
      <Button
        variant='cclink-light'
        startIcon={<Icon as={MaterialCommunityIcons} name='information-outline' size={18} />}
        onPress={handleToggle}
      />
    </Tooltip>
  );
}

export default TooltipWrapper;