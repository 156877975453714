import React, { useContext, useState, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { Heading, Button, Column, Row, View, Icon, ScrollView, Select, Text } from 'native-base';
import { useNavigation, useRoute } from '@react-navigation/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import get from 'lodash/get';

import FocusAwareStatusBar from './FocusAwareStatusBar';
import ScreenWrapper from '../ScreenWrapper';
import UiContext from '../UiContext';
import ApiContext from '../ApiContext';
import ContentCard from './ContentCard';
import { useIsPhoneSized } from '../utils';

const getCardKey = (q)=>Object.keys(q).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(q[key])).join('&')

export const ContentCards = ({ }) => {
  const apiContext = useContext(ApiContext);
  const uiContext = useContext(UiContext);
  const navigation = useNavigation();
  const route = useRoute();

  const isPhoneSized = useIsPhoneSized();

  const risks = get(apiContext, 'risks');
  const risksStatus = get(risks, 'status');
  const riskCards = get(risks, 'data.items') || [];
  const projections = get(apiContext, 'projections');
  const projectionsStatus = get(projections, 'status');
  const projectionCards = get(projections, 'data.items') || [];
  const solutions = get(apiContext, 'solutions');
  const solutionsStatus = get(solutions, 'status');
  const solutionsCards = get(solutions, 'data.items') || [];

  const [riskScrollPos, setRiskScrollPos] = useState(0);
  const risksRowRef = useRef();
  const risksContentRef = useRef();
  const [risksRowWidth, setRisksRowWidth] = useState(0);

  const [moreScrollPos, setMoreScrollPos] = useState(0);
  const moreRowRef = useRef();
  const moreContentRef = useRef();
  const [moreRowWidth, setMoreRowWidth] = useState(0);

  const [solutionsScrollPos, setSolutionsScrollPos] = useState(0);
  const solutionsRowRef = useRef();
  const solutionsContentRef = useRef();
  const [solutionsRowWidth, setSolutionsRowWidth] = useState(0);

  const showRiskEstimate = async (id) => {
    await uiContext.setExplore({ ...uiContext.explore, riskEstimateId: id }); // Set riskEstimateId for the current session
    // delay to allow the explore context to update before navigating
    setTimeout(() => {
      navigation.navigate('RiskEstimate', { ...route.params, riskEstimateId: id }); // Set riskEstimateId in the URL
    }, 200);
  };
  const showProjection = async ({ type, scenario_id, query_params: { min_probability, projection_format, projection_type, unit_system } }) => {
    await uiContext.setExplore({ ...uiContext.explore, scenarioId: scenario_id });
    const contentCardParams = {
        ...(projection_type && { projectionType: projection_type }),
        ...(projection_format && { projectionFormat: projection_format }),
        ...(min_probability && { minProbability: min_probability }),
        ...(unit_system && { unitSystem: unit_system }),
    };
    uiContext.setContentCard({ ...uiContext.contentCard, ...contentCardParams});
    navigation.navigate(type === 'slr_projection' ? 'SlrProjection' : 'FloodProjection', { ...route.params, scenarioId: scenario_id, ...contentCardParams });
  };
  const showSolution = async ({ type, query_params }) => {
    const solutionName = type === 'solution' ? query_params.name : null;
    uiContext.setSolutionName(solutionName);
    navigation.navigate('Solution', { ...route.params, solutionName });
  };
  const showContentCard = () => { console.log('TODO: Implement other content card types') };
  const scrollRow = (ref, scrollPosition, setPosition, isBack) => {
  if (ref.current) {
    ref.current.measure((fx, fy, width, height, px, py) => {
      const stepWidth = width - 210; // Scroll the width of the container, minus one card
      const newScrollPosition = isBack
        ? Math.max(0, scrollPosition - stepWidth)
        : scrollPosition + stepWidth;
      ref.current.scrollTo({ x: newScrollPosition, animated: true });
      setPosition(newScrollPosition);
    });
  }
};
const hasRiskEstimates = risksStatus === 'success' && riskCards && riskCards.length > 0;
const hasProjections = projectionsStatus === 'success' && projectionCards && projectionCards.length > 0;
const hasSolutions = solutionsStatus === 'success' && solutionsCards && solutionsCards.length > 0;
  const regionTypes = [
    { name: 'State', value: 'state' },
    { name: 'County', value: 'county' },
    { name: 'City/Town', value: 'place' },
    { name: 'Congressional District', value: 'cd118' },
    { name: 'State Legislative District — Upper Chamber', value: 'sldu2023' },
    { name: 'State Legislative District — Lower Chamber', value: 'sldl2023' },
  ]
  return (
    <ScreenWrapper>
      <FocusAwareStatusBar barStyle='dark-content' backgroundColor='white' />
      <View>
        <Row space={4} direction={'row'} variant='primary'>
          <Column style={styles.content} flex={1}>
            <Row style={isPhoneSized?styles.phoneSelectRow:{}}>
            <Heading variant='h5' style={styles.heading}>What's at risk?</Heading>
            <Row style={isPhoneSized?styles.phoneHeadingSelect:styles.headingSelect} >
              <Text style={isPhoneSized?styles.phoneHeadingSelectLabel:styles.headingSelectLabel}>Region Type</Text>
              <Select selectedValue={uiContext.explore.regionType} onValueChange={value => uiContext.setExplore({ regionType: value })}>
                {regionTypes.map((regionType) => (
                  <Select.Item label={regionType.name} value={regionType.value} key={regionType.value} />
                ))}
              </Select>
            </Row>
            </Row>
            <Row
              style={styles.cardRow}
              onLayout={(event) => {
                const layout = event.nativeEvent.layout;
                setRisksRowWidth(layout.width);
              }}>
              <ScrollView
                horizontal={true}
                ref={risksRowRef}
                style={styles.scrollContainer}
                onContentSizeChange={(contentWidth) => {
                  risksContentRef.current = contentWidth;
                }}
              >
                
                {['idle', 'loading'].includes(risksStatus) && Array(5).fill().map((_, i) => <ContentCard key={i} background='none' />)}
                {hasRiskEstimates
                  ? riskCards.map(card => <ContentCard key={getCardKey(card.query_params)} card={card} show={()=>showRiskEstimate(card.query_params.region_risk_estimate_id)} />)
                  : <Text>No risk data available for {uiContext.explore.defaultRegion}</Text>
                }
                {risksStatus === 'error' && <Text>Error loading risks</Text>}
              </ScrollView>
              {hasRiskEstimates && riskScrollPos > 1 && <View style={styles.scrollLeftContainer}>
                <Button
                  style={styles.scrollLeftButton}
                  onPress={() => scrollRow(risksRowRef, riskScrollPos, setRiskScrollPos, true)}
                  variant='cclink'
                  backgroundColor='#E6EAED30'
                  startIcon={<Icon as={MaterialCommunityIcons} name='arrow-left' size={18} />}
                />
              </View>}
              {hasRiskEstimates && riskScrollPos < (risksContentRef.current - risksRowWidth) && <View style={styles.scrollButtonContainer}>
                <Button
                  style={styles.scrollButton}
                  onPress={() => scrollRow(risksRowRef, riskScrollPos, setRiskScrollPos)}
                  variant='cclink'
                  backgroundColor='#E6EAED30'
                  startIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={18} />}
                />
              </View>}
            </Row>
            <Heading variant='h5' style={styles.heading}>When are the risks?</Heading>
            <Row style={styles.cardRow}>
              <ScrollView
                horizontal={true}
                ref={moreRowRef}
                style={styles.scrollContainer}
                onContentSizeChange={(contentWidth) => {
                  moreContentRef.current = contentWidth;
                }}
              >
                {['idle', 'loading'].includes(projectionsStatus) && Array(5).fill().map((_, i) => <ContentCard key={i} background='none' />)}
                {hasProjections
                  ? projectionCards.map(card => <ContentCard key={getCardKey(card.query_params)} card={card} show={()=>showProjection(card)} />)
                  : <Text>No projection data available for {uiContext.explore.defaultRegion}</Text>
                }
                {projectionsStatus === 'error' && <Text>Error loading projections</Text>}
              </ScrollView>
              {hasProjections && moreScrollPos > 1 && <View style={styles.scrollLeftContainer}>
                <Button
                  style={styles.scrollLeftButton}
                  onPress={() => scrollRow(moreRowRef, moreScrollPos, setMoreScrollPos, true)}
                  variant='cclink'
                  backgroundColor='#E6EAED30'
                  startIcon={<Icon as={MaterialCommunityIcons} name='arrow-left' size={18} />}
                />
              </View>}
              {hasProjections && moreScrollPos < (moreContentRef.current - moreRowWidth) && <View style={styles.scrollButtonContainer}>
                <Button
                  style={styles.scrollButton}
                  onPress={() => scrollRow(moreRowRef, moreScrollPos, setMoreScrollPos)}
                  variant='cclink'
                  backgroundColor='#E6EAED30'
                  startIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={18} />}
                />
              </View>}
            </Row>
            <Heading variant='h5' style={styles.heading}>Solutions</Heading>
            <Row style={styles.cardRow}>
              <ScrollView
                horizontal={true}
                ref={solutionsRowRef}
                style={styles.scrollContainer} 
                onContentSizeChange={(contentWidth) => {
                  solutionsContentRef.current = contentWidth;
                }}
              >
                {['idle', 'loading'].includes(solutionsStatus) && Array(5).fill().map((_, i) => <ContentCard key={i} background='none' />)}
                {hasSolutions
                  ? solutionsCards.map(card => <ContentCard key={getCardKey(card.query_params)} card={card} show={()=>showSolution(card)} />)
                  : <Text>No solutions data available for {uiContext.explore.defaultRegion}</Text>
                }
                {solutionsStatus === 'error' && <Text>Error loading solutions</Text>}
              </ScrollView>
              {hasSolutions && solutionsScrollPos > 1 && <View style={styles.scrollLeftContainer}>
                <Button
                  style={styles.scrollLeftButton}
                  onPress={() => scrollRow(solutionsRowRef, solutionsScrollPos, setSolutionsScrollPos, true)}
                  variant='cclink'
                  backgroundColor='#E6EAED30'
                  startIcon={<Icon as={MaterialCommunityIcons} name='arrow-left' size={18} />}
                />
              </View>}
              {hasSolutions && solutionsScrollPos < (solutionsContentRef.current - solutionsRowWidth) && <View style={styles.scrollButtonContainer}>
                <Button
                  style={styles.scrollButton}
                  onPress={() => scrollRow(solutionsRowRef, solutionsScrollPos, setSolutionsScrollPos)}
                  variant='cclink'
                  backgroundColor='#E6EAED30'
                  startIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={18} />}
                />
              </View>}
            </Row>
          </Column>
        </Row>
      </View>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  content: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  heading: {
    marginRight: 10,
  },
  headingSelect: {
    marginTop: 20,
  },
  headingSelectLabel: {
    marginRight: 10,
    marginTop: 10,
    marginLeft: 10,
  },
  phoneSelectRow: {
    flexDirection: 'column',
  },
  phoneHeadingSelect: {
    marginTop: 0,
    marginBottom: 10,
  },
  phoneHeadingSelectLabel: {
    marginRight: 10,
    marginTop: 6,
    marginLeft: 0,
  },
  text: {
    marginBottom: 40
  },
  scrollContainer: {
    paddingTop: 5,
    overflow: 'hidden',
  },
  cardRow: {
    maxWidth: '100%',
    overflow: 'hidden',
  },
  cardContent: {
    color: 'white',
  },
  scrollButtonContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    paddingRight: 20,
    paddingLeft: 20,
    backgroundImage: 'linear-gradient(270deg, rgba(14, 39, 71, 1.0), rgba(14, 39, 71, 0))'
  },
  scrollButton: {
    borderRadius: 0,
  },
  scrollLeftContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    paddingRight: 20,
    paddingLeft: 20,
    backgroundImage: 'linear-gradient(270deg, rgba(14, 39, 71, 0), rgba(14, 39, 71, 1.0))'
  },
  scrollLeftButton: {
    borderRadius: 0,
  },
});
