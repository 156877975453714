import React, { useEffect, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { View, Column, Row, Heading, Text } from 'native-base';
import { LinearGradient } from 'expo-linear-gradient';
import * as d3 from 'd3';
import get from 'lodash/get';
import { generateScaleLabels } from '../utils';

const ChoroplethMap = ({ choroplethData, isNarrow, anchorRef }) => {
  if (!choroplethData) return;
  const hasManyFeatures = choroplethData.features.length > 100; // NOTE: This is a somewhat arbitrary number, and we may want to adjust it
  const wrapperRef = useRef();
  const svgRef = useRef();
  const feat = get(choroplethData, 'features');
  const rankings = get(choroplethData, 'properties.rankings');
  const units = get(choroplethData, 'properties.units');
  const minValue = feat ? d3.min(feat, d => d.properties.value) : 0;
  const maxValue = feat ? d3.max(feat, d => d.properties.value): 10;

  const colorScale = d3.scaleSequential().domain([minValue, maxValue]).interpolator(d3.interpolateBlues);
  const startColor = colorScale(colorScale.domain()[0]);
  const endColor = colorScale(colorScale.domain()[1]);
  const scaleLabels = generateScaleLabels(minValue, maxValue);
  useEffect(() => {
    if (!choroplethData || !svgRef.current) return;
    const svg = d3.select(svgRef.current);

    const update = () => {
      d3.select(svgRef.current).selectAll("*").remove(); // Clear existing SVG elements to reset before rendering
      const bounds = wrapperRef.current.getBoundingClientRect();
      const width = bounds.width;
      const height = width; // * (4 / 3); // NOTE: We can fix the aspect ratio here
      const projection = d3.geoMercator().fitSize([width, height], choroplethData);
      const pathGenerator = d3.geoPath().projection(projection);
      const paths = svg.selectAll('path')
        .data(choroplethData.features)
        .enter()
        .append('path')
        .attr('d', pathGenerator)
        .attr('fill', d => colorScale(d.properties.value))
        .attr('stroke', d => hasManyFeatures ? 'rgba(0,0,0,0.1)' : 'rgb(3,40,73)')
        .attr('class', 'choropleth-path')
        .attr('id', (d) => {
          return d.properties.name;
        })
        .attr('title', (d) => {
          return d.properties.label;
        })
        .attr('stroke-width', hasManyFeatures ? 0 : 2);

      svg.attr('height', height).attr('width', width);
    }
    update();
    window.addEventListener('resize', update);
    return () => {
      window.removeEventListener('resize', update);
    };
  }, [choroplethData]);

  return (
    <Column justifyContent='flex-start'>
      <Row pt="40px">
        <Heading ref={anchorRef} variant='h4' size='md'>{get(choroplethData, 'properties.title')}</Heading>
      </Row>
      <Row flexDirection={isNarrow?'column':'row'} style={{maxWidth: 800, margin: '0 auto', width: '100%'}} marginX={'auto'} marginY={0}>
        <Column ref={wrapperRef} mb='20px' style={{ width: "100%", maxWidth: isNarrow?'100%':'60%', height: "auto", borderRightWidth: isNarrow?0:2, borderColor: 'rgba(255,255,255,0.2)' }}>
          <svg ref={svgRef} style={{ width: "100%", height: "auto" }} />
        </Column>
        <Column width='170px' ml="40px" mb='20px'>
          {rankings && <Row width='100%'><Column width='100%'>
            <Heading variant='h5' size='sm' style={styles.rankHead}>{rankings.title}</Heading>
            {rankings.items.map(item => <Row key={item.label} style={styles.rankRow} justifyContent={'space-between'}>
              <Text style={styles.labelText} mr='4'>{item.label}</Text>
              <Text style={styles.labelText} >{item.value}{units}</Text>
            </Row>)}
          </Column></Row>}
          <Row width='100%'><Column width='100%' mt='20px'>
            <Heading variant='h5' size='sm' style={styles.rankHead}>Key</Heading>
            <Row style={styles.legendContainer}>
              <LinearGradient
                start={{ x: 0, y: 1 }}
                end={{ x: 0, y: 0 }}
                colors={[startColor, endColor]}
                style={styles.gradient}
              />
              <View style={styles.labels}>
                {scaleLabels.reverse().map((value, index) => (
                  <Text mb='3' key={index} style={styles.labelText}>{value}{units}</Text>
                ))}
              </View>
            </Row>
          </Column></Row>
        </Column>
      </Row>
    </Column>
  );
};

export default ChoroplethMap;

const styles = StyleSheet.create({
  rankHead: {
    borderBottomColor: 'rgba(255,255,255,0.5)',
    borderBottomWidth: 2,
    width: '100%',
    margin: 0,
    marginBottom: 10,
  },
  rankRow: {
    borderBottomColor: 'rgba(255,255,255,0.5)',
    borderBottomWidth: 1,
    paddingTop: 5,
    paddingBottom: 5,
    margin: 0,
  },
  gradient: {
    width: 30,
    height: 175,
  },
  labels: {
    marginLeft: 10,
    justifyContent: 'space-between',
  },
  labelText: {
    fontFamily: 'WorkSans_600SemiBold',
    fontWeight: 'bold',
    margin: 0,
  }
});