import { Modal, Row, Button, Icon } from 'native-base';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import React, { useRef, useContext, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import get from 'lodash/get';
import ContentDetail from '../ContentDetail';
import { MapScreen } from '../MapScreen';
import { ContentCards } from '../ContentCards';
import ApiContext from '../../ApiContext';
import LoadingModal from './LoadingModal';
import ErrorModal from './ErrorModal';
import { useIsTabLayout } from '../../utils';

function RiskEstimateModal(props) {
  const initialRef = useRef(null);
  const apiContext = useContext(ApiContext);
  const navigation = useNavigation();
  const route = useRoute();
  const [showModal, setShowModal] = useState(true);
  const isTabLayout = useIsTabLayout();
  navigation.addListener('focus', () => {
    setShowModal(true);
  });
  navigation.addListener('blur', () => {
    setShowModal(false);
  });
  const closeModal = ()=>navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Explore', { ...route.params });
  const card = get(apiContext, 'riskEstimate.data', null);
  const cardStatus = get(apiContext, 'riskEstimate.status', null);
  const choroplethData = get(apiContext, 'choroplethData', null);
  if (['idle', 'loading'].includes(cardStatus)) return <LoadingModal />;
  if (cardStatus === 'success' && card) {
    const shareData = {
      title: card.headline.text.long.as_percent,
      text: card.headline.text.long.as_percent,
      url: window.location.href,
    };
    const relatedRiskEstimates = get(apiContext, 'relatedRiskEstimates.data', []);
    return (
      <Row style={styles.main}>
        <style>
          {`div[role="dialog"] {
            max-height: none !important;
          }`}
        </style>
        <MapScreen {...props} />
        <ContentCards {...props} />
        <Modal initialFocusRef={initialRef} isOpen={showModal} onClose={closeModal} borderRadius={0} size="full" safeAreaTop={true} style={styles.wrapper} bg='primary.700' color='primary.300' _text={{
              color: 'primary.300',
            }}>
          <Modal.Content bg='primary.700' color='primary.300' _text={{
              color: 'primary.300',
            }} borderRadius={0} style={isTabLayout ? styles.contentSmall : styles.content}>
            <Modal.Header style={styles.modalHeader} bg='primary.700' _text={{
              color: 'primary.300',
            }}>
              <Row style={styles.headerButtons}>
                <Button
                  variant='cclink'
                  startIcon={<Icon as={MaterialCommunityIcons} name='share' size={18} />}
                  onPress={() => {
                    if (!navigator.canShare || !navigator.canShare(data)) {
                      // setShowShareModal(true);
                    } else {
                      navigator.share(shareData);
                    }
                  }}
                >
                  Share
                </Button>
                <Button
                  variant='cclink'
                  startIcon={<Icon as={MaterialCommunityIcons} name='code-tags' size={18} />}
                  onPress={() => {}}
                >
                  Embed
                </Button>
                <Button
                  variant='cclink'
                  startIcon={<Icon as={MaterialCommunityIcons} name='download' size={18} />}
                  onPress={() => {}}
                >
                  Download
                </Button>
              </Row>
              <Modal.CloseButton style={styles.closeButton} />
            </Modal.Header>
            <Modal.Body style={styles.modalBody} bg='primary.700' color='primary.300' _text={{
              color: 'primary.300',
            }}>
            <ContentDetail card={card} ref={initialRef} relatedCards={relatedRiskEstimates} choroplethData={choroplethData} />
            </Modal.Body>
          </Modal.Content>
        </Modal>
      </Row>
    );
  };
  return <ErrorModal />;
}

export default RiskEstimateModal;

const styles = StyleSheet.create({
  main: {
    height: '100%'
  },
  wrapper: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
    borderTopColor: 'transparent',
  },
  modalHeader: {
    height: 40,
    padding: 0,
    paddingTop: 0,
  },
  closeButton: {
    padding: 0,
  },
  content: {
    position: 'absolute',
    top: 20,
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '980px',
    margin: 'auto',
  },
  contentSmall: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    maxHeight: '100%',
  },
  modalBody: {
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  headerButtons: {
    justifyContent: 'flex-start',
    left: 10,
    top: 0,
    position: 'absolute',
    pointerEvents: 'auto'
  },
});

