import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { Alert, IconButton, Text, Column, Row, CloseIcon } from 'native-base';
import { useRoute } from '@react-navigation/native';
import { Map } from './Map';
import UiContext from '../UiContext';
import FocusAwareStatusBar from './FocusAwareStatusBar';

export const MapScreen = () => {
  const uiContext = useContext(UiContext);
  const route = useRoute();
  const { banners, removeBanner } = uiContext;
  // We also want to show banners that don't have a location set here
  const mapBanners = banners.filter(banner => banner.location === 'map' || !banner.location);
  const borderStyle =  route.name === 'Explore' ? { borderRight: '3px solid #d4dbe0' } : {}
  return (
    <View style={styles.page}>
      <FocusAwareStatusBar barStyle='dark-content' backgroundColor='white' />
      <View style={{...styles.container, ...borderStyle}}>
        <Column style={styles.headerContainer}>
          {mapBanners &&
            mapBanners.length > 0 &&
            mapBanners.map((banner, i) => {
              return (
                <Alert style={styles.banner} status={banner.type || 'info'} key={banner.text + i}>
                  <Column space={2} flexShrink={1} w='100%'>
                    <Row flexShrink={1} space={2} justifyContent='space-between'>
                      <Row space={2} flexShrink={1}>
                        <Alert.Icon mt='1' />
                        <Text fontSize='md' color='coolGray.800'>
                          {banner.text} {banner.link&&<a href={banner.link.href}>{banner.link.label}</a>}
                        </Text>
                      </Row>
                      <IconButton
                        onPress={() => {
                          removeBanner(banner.text);
                        }}
                        variant='unstyled'
                        _focus={{
                          borderWidth: 0,
                        }}
                        icon={<CloseIcon size='3' />}
                        _icon={{
                          color: 'coolGray.600',
                        }}
                      />
                    </Row>
                  </Column>
                </Alert>
              );
            })}
        </Column>
        <Map />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(14, 39, 71)',
  },
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgb(14, 39, 71)',
  },
  headerContainer: {
    alignItems: 'stretch',
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    left: 0,
    pointerEvents: 'none'
  },
  banner: {
    pointerEvents: 'auto',
    marginTop: 10,
    marginLeft: 60,
    marginRight: 60,
    width: 'calc(100% - 120px)',
  },
});
