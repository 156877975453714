import React, { useEffect } from 'react';
import { useNavigation, useRoute, getFocusedRouteNameFromRoute } from '@react-navigation/native';
import isEqual from 'lodash/isEqual';
import { usePrevious, useIsTabLayout } from '../utils';

const URLUpdater = ({params, children}) => {
  const prevParams = usePrevious(params);
  const navigation = useNavigation();
  const route = useRoute();
  const isTabLayout = useIsTabLayout();
  const haveParamsChanged = !isEqual(params, prevParams);
  useEffect(() => {
    if (haveParamsChanged) {
      // TODO: This might break if the breakpoint changes while the app is running
      // So we might want to figure out how to pass the params to a child component
      // instead of using a separate navigation function
      if (isTabLayout) {
        const focusedRoute = getFocusedRouteNameFromRoute(route) || 'Maps';
        navigation.navigate(focusedRoute, params);
      } else {
        navigation.setParams(params);
      }
    }
  }, [params]);
  return children;
};

export default URLUpdater;
