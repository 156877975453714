import { Modal, Button, IconButton, Icon, Input, InputGroup, InputLeftAddon, InputRightAddon, FormControl } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import qs from 'query-string';

function ShareModal({ show, toggle, data }) {
  const open = (url) => window.open(url, '_blank').focus();
  const [mastodonInstance, setMastodonInstance] = useState('mastodon.social');
  const [showMastodonModal, setShowMastodonModal] = useState(false);
  const mastodonArgs = {
    text: `${data.title} ${data.url}`,
  }
  const fbArgs = {
    app_id: process.env.FACEBOOK_APP_ID,
    href: data.url,
    display: 'page',
    // quote: shortText,
    redirect_uri: data.url,
    hashtag: '#climatecentral', // Facbook only allows a single hashtag
  };
  const liArgs = {
    url: data.url,
  };
  const twArgs = {
    text: data.title,
    url: data.url,
    hashtags: 'ClimateCentral,CoastalRiskFinder,SeaLevelRise',
    via: 'ClimateCentral',
  };
  const emailArgs = {
    subject: data.title,
    body: `${data.title} ${data.url}`,
  };
  return (
    <Modal isOpen={show} onClose={toggle} borderRadius={0}>
      <Modal.Content maxWidth="350" bg='primary.100' borderRadius={0}>
        <Modal.CloseButton />
        <Modal.Header bg='primary.300' color='primary.900' _text={{
          color: 'primary.900',
        }}>Share</Modal.Header>
        <Modal.Body>
          <Button.Group space={2} style={styles.shareButtons}>
            {/* NOTE: It seems that a .social TLD is not supported */}
            <IconButton variant='share-light-bg' icon={<Icon as={MaterialCommunityIcons} name='mastodon' size='md' onPress={() => setShowMastodonModal(true)} />} />
            <IconButton variant='share-light-bg' icon={<Icon as={MaterialCommunityIcons} name='facebook' size='md' onPress={() => open(`https://www.facebook.com/dialog/share?${qs.stringify(
              fbArgs,
            )}`)} />} />
            <IconButton variant='share-light-bg' icon={<Icon as={MaterialCommunityIcons} name='linkedin' size='md' onPress={() => open(`https://www.linkedin.com/sharing/share-offsite/?${qs.stringify(
              liArgs,
            )}`)} />} />
            <IconButton variant='share-light-bg' icon={<Icon as={MaterialCommunityIcons} name='twitter' size='md' onPress={() => open(`https://twitter.com/intent/tweet?${qs.stringify(twArgs)}`)} />} />
            <IconButton variant='share-light-bg' icon={<Icon as={MaterialCommunityIcons} name='email' size='md' onPress={() => open(`mailto:?${qs.stringify(emailArgs)}`)} />} />
          </Button.Group>
          {showMastodonModal && 
          <>
            <FormControl.Label>Enter Your Mastodon Instance</FormControl.Label>
            <InputGroup>
              <InputLeftAddon children={'https://'} />
              <Input color="black" onChangeText={(v)=>setMastodonInstance(v)} value={mastodonInstance} />
              <InputRightAddon style={{padding: 0}} children={<Button
                onPress={()=>open(`https://${mastodonInstance}/share?${qs.stringify( mastodonArgs )}`)}
                style={{borderRadius: 0}}
                startIcon={<Icon as={MaterialCommunityIcons} name='share' size={18} />}
              >
                Share
              </Button>} />
            </InputGroup>
          </>}
        </Modal.Body>
        <Modal.Footer bg='primary.100'>
          <Button.Group space={2}>
            <Button
              variant='cclink-light'
              startIcon={<Icon as={MaterialCommunityIcons} name='content-copy' size={18} />}
              style={styles.button}
              onPress={()=>navigator.clipboard.writeText(data.url)}
            >
              Copy Link
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

export default ShareModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(0, 0, 0)',
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    borderRadius: 2,
    borderWidth: 0,
    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    left: 10,
  },
  shareButtons: {
    marginBottom: 20
  }
});
