import React, { useContext, useState, useRef } from 'react';
import { StyleSheet, ImageBackground } from 'react-native';
import { Text, View, Button, Modal, Heading, Row, Column, Switch, useBreakpointValue, Link, ScrollView } from 'native-base';
import { Remark } from 'react-remark';
import { renderTemplate, scrollToRef } from '../utils';
import get from 'lodash/get';
import Accordion from './Accordion';
import ChoroplethMap from './ChoroplethMap';
import DataSources from './DataSources';
import BarChart from './BarChart';
import cardBackground from '../assets/card-bg.png';
import UiContext from '../UiContext';
import ContentCardTile from './ContentCard';

const CollapsibleListItem = ({ item }) => {
  const hasLink = item.link && item.link_text;
  return (
    <Text variant='body' fontSize='md'>
        <style>
          {`
            .mdStyle2 {
              color: white;
              font-family: 'Maitree_400Regular';
              max-width: 100%;
            }
            .mdStyle2 p {
              margin: 0;
            }
            .mdStyle2 a, .mdStyle a:visited, .mdStyle a:active, .mdStyle a:hover, .mdStyle a:focus, .mdStyle a:link {
              color: #FEE003;
            }
          `}
        </style>
        <div className='mdStyle2'>
          <Remark 
            rehypeReactOptions={{
              components: {
                a: (props) => <a target="_blank" rel="noopener noreferrer" {...props} />,
              },
            }}
          >
            {item.body}
          </Remark>
        </div>
      <br />
      {item.image_url && <img style={{maxWidth: '100%', margin: '20px auto'}} src={item.image_url} alt={item.heading} />}
      {item.video_url && <iframe style={{maxWidth: '100%', margin: '20px auto'}} src={item.video_url} title={item.heading} />}
      {hasLink && <Link href={item.link} isExternal>{item.link_text}</Link>}
    </Text>
  );
};

const BulletListItem = ({ item }) => {
  const hasLink = item.link && item.heading;
  return (
    <li>
      {hasLink && <Heading variant='subhead' size='sm'><Link href={item.link} isExternal>{item.heading}</Link> {item.body}</Heading>}
    </li>
  );
};


const ContentCard = ({ card, show, background, relatedCards, choroplethData, slrTimeseries, floodTimeseries, isFullSize }) => {
  // NOTE: We can't use the `useContext` hook here because this component is not a child of the `ApiProvider`
  // Instead, we pass the data we need as a props to this component.
  if (!card) return null;
  const { explore, setExplore, contentCard, setContentCard, setSolutionName } = useContext(UiContext);
  const [asPercent, setAsPercent] = useState(false);
  const [height, setHeight] = useState(0);
  const isNarrow = useBreakpointValue({ base: true, sm: false });
  const vizualizationRef = useRef();
  const sourcesRef = useRef();
  // These tab refs are just used for dynamic tabs
  const tabRefs = Array(10).fill(useRef());

  const solutionsRowRef = useRef();
  const solutionsContentRef = useRef();
  const isLong = isFullSize || !!relatedCards; // if relatedCards is present, use long headline
  const headline = get(card, `headline.text.${isLong?'long':'short'}`);
  const hasHeadlineOptions = headline && headline.as_value && headline.as_percent;
  const isSolution = card.type === 'solution';
  const toggleHeadline = () => {
    setAsPercent(!asPercent);
  }
  const timeseries = slrTimeseries || floodTimeseries;

  const showSolution = async ({ type, name }) => {
   if (type !== 'solution') return;
   console.log('n', name);
    setSolutionName(name);
    // Note: Unfortunately, we can't use React Navigation here because this modal is not a child of a navigator
    window.location = `/Solution?solutionName=${name}`;
  };
  return (
    <View style={styles.wrapper}>
      <Modal.Content style={styles.content} borderRadius={0}>
        <Modal.Body style={styles.contentBody} _text={{
          color: 'primary.300',
        }}>
          <ImageBackground source={background || cardBackground} style={{ ...styles.backgroundImage, height: height }}>
            <View style={styles.mainText}  onLayout={(event) => {
              const { height } = event.nativeEvent.layout;
              setHeight(height + 20);
            }}>
              {renderTemplate(card, isLong, asPercent)}
              {!isSolution && <Accordion title={`Change Location or Settings`} isSettings={true}>
                  {hasHeadlineOptions && (
                    <View>
                      <Row>
                        <Heading variant='h5' size='sm'>Show As</Heading>
                      </Row>
                      <Row>
                        <Text>Number</Text>
                        <Switch offTrackColor="primary.100" onTrackColor="primary.200" size="sm" isChecked={asPercent} onToggle={toggleHeadline} />
                        <Text>Percent</Text>
                      </Row>
                    </View>
                  )}
                  {!!contentCard.projectionType && ( // NOTE: Risk period is called projection_type on the back end
                    <View>
                      <Row>
                        <Heading variant='h5' size='sm'>Risk Period</Heading>
                      </Row>
                      <Row>
                        <Text>Single-year Likelihood</Text>
                        <Switch offTrackColor="primary.100" onTrackColor="primary.200" size="sm" isChecked={contentCard.projectionType === 'by-year'} onToggle={()=>setContentCard({projectionType: contentCard.projectionType === 'by-year'? 'in-year' : 'by-year'})} />
                        <Text>Cumulative Likelihood</Text>
                      </Row>
                    </View>
                  )}
                  {card.type !== 'risk_estimate' && !!contentCard.unitSystem && (
                    <View>
                      <Row>
                        <Heading variant='h5' size='sm'>Unit System</Heading>
                      </Row>
                      <Row>
                        <Text>Metric (meters, °C)</Text>
                        <Switch offTrackColor="primary.100" onTrackColor="primary.200" size="sm" isChecked={contentCard.unitSystem === 'english'} onToggle={()=>setContentCard({unitSystem: contentCard.unitSystem === 'english'? 'metric' : 'english'})} />
                        <Text>Imperial (feet, °F)</Text>
                      </Row>
                    </View>
                  )}
                  {relatedCards?.map(related=>{
                    return (
                      <View key={related.section_label}>
                        <Heading variant='h5' size='sm'>{related.section_label}</Heading>
                        <Row style={styles.optionRow}>
                          {related?.choices?.map(choice => <Button
                            key={choice.region_risk_estimate_id || choice.scenario_id + choice.label}
                            variant='solid'
                            style={styles.optionButton}
                            onPress={() => {
                              if (choice.region_risk_estimate_id) {
                                setExplore({...explore, riskEstimateId: choice.region_risk_estimate_id});
                              } else if (choice.scenario_id) {
                                const isLocationChange = related.section_label.toLowerCase().includes('location');
                                setExplore({
                                  ...explore,
                                  scenarioId: choice.scenario_id,
                                  ...(isLocationChange && choice.region?{defaultRegion: choice.region}:{}),
                                  ...(choice.map_settings?{mapSettings: choice.map_settings}:{})
                                });
                              }
                            }}
                          >
                            {choice.label}
                          </Button>)}
                        </Row>
                      </View>
                    )
                  })}
              </Accordion>}
            </View>
          </ImageBackground>
          <View style={styles.tabContent}>
            {isSolution && 
              <View style={styles.summaryContainer}><Text variant='body' fontSize='xl'>{card.summary}</Text></View>
            }
            <Row>
              {(!!choroplethData || !!timeseries) && <Button variant='cctab' onPress={()=>scrollToRef(vizualizationRef)}>Visualizations</Button>}
              {/* <Button variant='cctab'>Rankings?</Button>
              <Button variant='cctab'>Related Risks?</Button>
              <Button variant='cctab'>Solutions?</Button> */}
              {!!card.data_sources && <Button variant='cctab' onPress={()=>scrollToRef(sourcesRef)}>Data & Sources</Button>}
              {isSolution && card.content && card.content.map((item, i)=>{
                return (
                  <Button key={item.label} variant='cctab' onPress={()=>scrollToRef(tabRefs[i])}>{item.label}</Button>
                );
              })}
            </Row>
            {!!choroplethData ? <View>
              {['idle', 'loading'].includes(choroplethData.status) ? <Text>Loading choropleth...</Text> : choroplethData.status === 'success' && choroplethData.data ? <ChoroplethMap anchorRef={vizualizationRef} choroplethData={choroplethData.data} isNarrow={isNarrow} /> : <Text>Failed to load choropleth data.</Text>}
            </View> : null}
            {!!timeseries ? <View>
              {['idle', 'loading'].includes(timeseries.status) ? <Text>Loading bar chart...</Text> : timeseries.status === 'success' && timeseries.data ? <BarChart anchorRef={vizualizationRef} timeseries={timeseries.data} /> : <Text>Failed to load bar chart data.<pre style={{ background: 'black', whiteSpace: 'pre-wrap', maxWidth: '100%', wordWrap: 'break-word'}}>{explore.floodTimeseriesError || JSON.stringify(timeseries)}</pre></Text>}
            </View> : null}
            {!!card.data_sources && <DataSources content={card.data_sources} anchorRef={sourcesRef} />}
            {isSolution && 
            <View>
                <View>
                  {card.content && card.content.map((content, i)=>{
                    return (
                      <View key={content.title + i}>
                        <Heading variant='h3' size='xl' ref={tabRefs[i]}>{content.title}</Heading>
                        <hr style={styles.rule} />
                        {content.subtitle && <Heading variant='subhead' size='md'>{content.subtitle}</Heading>}
                        <Text variant='body' fontSize='md'>{content.description}</Text>
                        {content.list_type === 'bulleted' ? <ul>
                          {content.list_items.map((item, i)=>{
                            return <BulletListItem key={item.heading+i} item={item} />;
                          })}</ul> :
                          <Column>
                            {content.list_items.map((item, i)=>{
                              return (
                                <Accordion title={item.heading} key={item.heading + i}>
                                  <CollapsibleListItem item={item} />
                                </Accordion>
                              );
                            })}
                          </Column>
                        }
                      </View>
                    );
                  })}
                </View>
                {card.choices && card.choices.length > 0 &&
                <View>
                  <Heading variant='h3' size='xl'>More Solutions</Heading>
                  <Row style={styles.cardRow}>
                    <ScrollView
                      horizontal={true}
                      ref={solutionsRowRef}
                      style={styles.scrollContainer} 
                      onContentSizeChange={(contentWidth) => {
                        solutionsContentRef.current = contentWidth;
                      }}
                    >
                      {card.choices && card.choices.map((choice, i)=>{
                        return (
                          <ContentCardTile key={i+choice.section_label} card={choice} show={()=>showSolution(choice)} />
                        );
                      })}
                    </ScrollView>
                  </Row>
                </View>
                }
              </View>
            }
          </View>
        </Modal.Body>
      </Modal.Content>
    </View>
  );
};

export default ContentCard;

const styles = StyleSheet.create({
  wrapper: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    padding: 0,
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  content: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderSize: 0,
    boxShadow: 'none',
  },
  tabContent: {
    padding: 20,
  },
  rule: {
    border: 0,
    borderTopWidth: 1,
    borderTopColor: 'rgba(255,255,255,0.3)',
    borderTopStyle: 'solid', 
    margin: '10px 0',
    width: '100%',
  },
  mainText: {
    padding: 60,
  },
  summaryContainer: {
    margin: -20,
    marginBottom: 0,
    padding: 60,
    backgroundColor: 'rgba(17,32,52, 1)',
  },
  contentBody: {
    padding: 0,
    backgroundColor: 'rgb(3,40,73)',
  },
  optionRow: {
    flexWrap: 'wrap',
  },
  optionButton: {
    marginLeft: 0,
    marginTop: 0,
    marginRight: 10,
    marginBottom: 10,
  },
  scrollContainer: {
    paddingTop: 5,
    overflow: 'hidden',
  },
  cardRow: {
    maxWidth: '100%',
    overflow: 'hidden',
  },
});
