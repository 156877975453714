import { Modal, Row, Skeleton, View } from 'native-base';
import React, { useRef, useState } from 'react';
import { StyleSheet, ImageBackground } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import cardBackground from '../../assets/card-bg.png';
import { useIsTabLayout } from '../../utils';
import { MapScreen } from '../MapScreen';
import { ContentCards } from '../ContentCards';

function LoadingModal(props) {
  const initialRef = useRef(null);
  const navigation = useNavigation();
  const route = useRoute();
  const [showModal, setShowModal] = useState(true);
  const isTabLayout = useIsTabLayout();
  navigation.addListener('focus', () => {
    setShowModal(true);
  });
  navigation.addListener('blur', () => {
    setShowModal(false);
  });
  const closeModal = ()=>navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Explore', { ...route.params });
  return (
      <Row style={styles.main}>
        <style>
          {`div[role="dialog"] {
            max-height: none !important;
          }`}
        </style>
        <MapScreen {...props} />
        <ContentCards {...props} />
        <Modal initialFocusRef={initialRef} isOpen={showModal} onClose={closeModal} borderRadius={0} size="full" safeAreaTop={true} style={styles.wrapper} bg='primary.700' color='primary.300' _text={{
            color: 'primary.300',
          }}>
        <Modal.Content bg='primary.700' color='primary.300' _text={{
            color: 'primary.300',
          }} borderRadius={0} style={isTabLayout ? styles.contentSmall : styles.content}>
          <Modal.Header style={styles.modalHeader} bg='primary.700' _text={{
            color: 'primary.300',
          }}>
            <Modal.CloseButton style={styles.closeButton} />
          </Modal.Header>
          <Modal.Body style={styles.modalBody} bg='primary.700' color='primary.300' _text={{
            color: 'primary.300',
          }}>
            <View style={styles.wrapper}>
              <ImageBackground source={cardBackground} style={styles.backgroundImage}>
                <View style={styles.mainText}>
                  <Skeleton.Text px="15" lines="4" />
                </View>
              </ImageBackground>
            </View>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Row>
    );
}

export default LoadingModal;

const styles = StyleSheet.create({
  main: {
    height: '100%'
  },
  wrapper: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
    borderTopColor: 'transparent',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    minHeight: '80vh'
  },
  mainText: {
    padding: 60,
    paddingBottom: 150,
    height: '100%',
  },
  modalHeader: {
    height: 40,
    padding: 0,
    paddingTop: 0,
  },
  closeButton: {
    padding: 0,
  },
  content: {
    position: 'absolute',
    top: 20,
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '980px',
    margin: 'auto',
  },
  contentSmall: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    maxHeight: '100%',
  },
  modalBody: {
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    height: '100%',
  }
});

