import { isArray } from 'lodash';

export const fetchEndpoint = async ({ queryKey, meta }) => {
  const endpoint = queryKey[0];
  const { lat, lng, url } = meta || {};
  const baseUrl = url || process.env.CC_API_URL;
  let qs = '';
  if (queryKey[1]) {
    const { isUSA, bbox, ...query } = queryKey[1]; // Remove isUSA, and bbox for manual handling
    const params = new URLSearchParams({ ...query, ...(lat && lng && { lat, lng }) });

    // Handle bbox separately to prevent encoding of commas
    if (bbox && isArray(bbox)) {
      bbox.forEach((coord) => {
        params.append(`bbox`, coord);
      })
    }

    qs = `/?${params.toString()}`;
  }
  const fetchUrl = `${baseUrl}${endpoint}${qs}`;
  const response = await fetch(fetchUrl);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  // if response code is between 400-600, throw error
  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Bad response from server: ${response.status}`);
  }

  return response.json();
};