import * as React from 'react';
import { StyleSheet, Text as Inline } from 'react-native';
import { Heading, Text, Column, Row, View, Container, Link } from 'native-base';

import ScreenWrapper from '../ScreenWrapper';

export const TextStyles = () => {
  return (
    <ScreenWrapper>
      <Row variant='hero' style={styles.content}>
        <Heading variant='hero' maxWidth='600' size='5xl'>
          Sea Levels Are Rising Rapidly.
        </Heading>
      </Row>
      <View>
        <Row space={4} direction={{ sm: 'column', md: 'row' }} variant='primary'>
          <Column style={styles.content} flex={{ sm: 'auto', md: '1' }}>
            <Heading variant='h1' size='4xl'>
              Heading 1
            </Heading>
            <Heading variant='h2' size='3xl'>
              Heading 2
            </Heading>
            <Heading variant='h3' size='xl'>
              Heading 3
            </Heading>
            <Heading variant='h4' size='lg'>
              Heading 4
            </Heading>
          </Column>
          <Column style={styles.content} flex={{ sm: 'auto', md: '1' }}>
            <Text variant='callout-bold' fontSize='3xl' style={styles.text}>
              Callout Bold
            </Text>
            <Text variant='callout' fontSize='3xl' style={styles.text}>
              Callout
            </Text>
            <Text variant='body' fontSize='xl' style={styles.text}>
              Body Large{' '}
              <Link href='https://google.com'>
                google
              </Link>{' '}
              lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi mus enim, malesuada sed.
            </Text>
            <Heading variant='subhead' size='md' style={styles.text}>
              Body Subhead
            </Heading>
            <Text variant='body' fontSize='md' style={styles.text}>
              Body Normal <Link href='/Colors'>colors page</Link> lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Morbi mus enim, malesuada sed. Eget arcu pellentesque felis, sed. Eget arcu pellentesque felis, sed. Eget arcu
              pellentesque felis, sed. Eget arcu pellentesque felis, sed.
            </Text>
            <Text variant='body-bold' fontSize='md' style={styles.text}>
              Body Normal Bold
            </Text>
          </Column>
        </Row>
        <Row>
          <Container style={styles.content} variant='card'>
            <Text variant='card' fontSize='5xl' style={styles.text}>
              Card Detail
            </Text>
            <Text variant='card-bold' fontSize='5xl' style={styles.text}>
              Emphasis (Variables)
            </Text>
            <Text variant='card-accent' fontSize='5xl' style={styles.text}>
              Emphasis (Impact or Risk)
            </Text>
            <Inline style={styles.text}>
              <Text variant='card-accent' fontSize='5xl'>
                40,000 football fields worth of land
              </Text>
              <Text variant='card' fontSize='5xl'>
                {' '}
                in{' '}
              </Text>
              <Text variant='card-bold' fontSize='5xl'>
                Miami-Dade County, FL
              </Text>
              <Text variant='card' fontSize='5xl'>
                {' '}
                could be saved from sea level rise by{' '}
              </Text>
              <Text variant='card-bold' fontSize='5xl'>
                2050
              </Text>
              <Text variant='card' fontSize='5xl'>
                {' '}
                if we make{' '}
              </Text>
              <Text variant='card-bold' fontSize='5xl'>
                sweeping emissions cuts
              </Text>
              <Text variant='card' fontSize='5xl'>
                {' '}
                now.
              </Text>
            </Inline>
          </Container>
        </Row>
      </View>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  content: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    marginBottom: 40
  }
});
