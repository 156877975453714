import React, { useState, useEffect, useCallback } from 'react';
import { NativeBaseProvider, Spinner } from 'native-base';
import { QueryClientProvider } from 'react-query';
import * as Linking from 'expo-linking';
import * as Location from 'expo-location';
import { useFonts, WorkSans_300Light, WorkSans_400Regular, WorkSans_600SemiBold, WorkSans_700Bold } from '@expo-google-fonts/work-sans';
import { Maitree_300Light, Maitree_400Regular, Maitree_600SemiBold } from '@expo-google-fonts/maitree';
import pickBy from 'lodash/pickBy';
import theme from './nativebase/theme';
import Router from './Router';
import config from './app.config';
import UiContext from './UiContext';
import { Platform } from 'react-native';
import { getDeltasFromZoom, queryClient } from './utils';
import { uniqBy, debounce } from 'lodash';

// Ignore warnings from react-aria which is a dependency of some native-base components
// See https://github.com/GeekyAnts/NativeBase/issues/4273 for more info
const originalWarn = console.warn;
console.warn = function (...args) {
  if (typeof args[0] === 'string' && args[0].startsWith('When server rendering')) {
    return;
  }
  originalWarn.apply(console, args);
};

// Ignore errors from native-base dependencies
const originalError = console.error;
console.error = function (...args) {
  if (typeof args[0] === 'string' && args[0].includes('removeListener')) {
    return;
  }
  originalError.apply(console, args);
};

const AppConfig = config.app;
const prefix = Linking.createURL('/');
const linking = {
  prefixes: [prefix],
};

const App = () => {
  const zoom = 10; // Default zoom level
  // Native uses deltas, web uses zoom
  const zoomObject = Platform.OS !== 'web' ? getDeltasFromZoom(zoom) : { zoom };
  const [user, setUser] = useState(null);
  const [banners, setBanners] = useState([]);
  const [header, setHeader] = useState(0);
  const [modal, setModal] = useState({ visible: false });
  const [bbox, setBbox] = useState(null);
  const [explore, setExplore] = useState({
    region: {
      ...zoomObject,
      // This default lat and lng is needed for the correct map settings to load
      latitude: 40.783056502673745,
      longitude: -73.97125276464078
    },
    regionType: 'state',
    mapTheme: 'sea_level_rise',
    mapType: 'year',
    basemap: 'default',
    layers: ['roads', 'cities', 'levees', 'labels', 'icons'],
    mapSettings: {
      forecast_year: '2050',
      pathway: 'ssp3rcp70',
      percentile: 'p50',
      return_level: 'return_level_1',
      rl_model: 'tebaldi_2012',
      slr_model: 'ipcc_2021_med'
    },
  });
  const [contentCard, setContentCard] = useState({ unitSystem: 'english'}); // imperial is called english in the backend
  const [solutionName, setSolutionName] = useState(null);

  useEffect(() => {

    async function getLocation() {
      // TODO: This function should only run once, even if the page is reloaded
      // We can do that by saving the location in local storage
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        // TODO: Add action buttons to banners
        // Note: this is disabled because location permission is not essential to the app and this is extra noise on top of the symbol in the browser's address bar
        // addBanner({ type: 'warning', text: 'Coastal Risk Finder does not have permission to access your location.', location: 'map' });
        return;
      }
      let position;
        try {
            position = await Location.getCurrentPositionAsync({ timeout: 3000 });
        } catch (error) {
            console.error("Error getting location: ", error);
        }
      return position;
    };

    async function handleInitialUrl(url) {
      if (!url) return;
      const userLocation = null; // await getLocation();
      const query = url.split('?')[1];
      let paramsObj = {};
      if (query && query.length > 0) {
        paramsObj = query.split('&').reduce(function (prev, curr) {
          const p = curr.split('=');
          prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
          return prev;
        }, {});
      }
      const {
        latitude,
        longitude,
        mapTheme,
        mapType,
        zoom,
        basemap,
        layers,
        defaultRegion,
        riskEstimateId,
        projectionType,
        projectionFormat,
        minProbability,
        unitSystem,
        solutionName,
        ...settings
      } = paramsObj;
      const validSettings = pickBy(settings, function (value, key) {
        const isValid = AppConfig.mapSettings.includes(key);
        if (!isValid) console.warn('Ignoring invalid URL parameter: ', key);
        return isValid;
      })
      // When not on the web, we need to use deltas instead of zoom
      const zoomObject = Platform.OS !== 'web' ? getDeltasFromZoom(zoom) : { zoom: parseFloat(zoom) };
      let initialLatitude = latitude;
      let initialLongitude = longitude;
      if (!initialLatitude || !initialLongitude) {
        if (userLocation) {
          // This only happens if the user had previously granted location permissions
          // We need to add an event listener to handle the case where the user changes their location permissions
          initialLatitude = userLocation.coords.latitude;
          initialLongitude = userLocation.coords.longitude;
        }
      }
      const layersCSV = layers && layers.includes('%2C') ? decodeURIComponent(layers) : layers;
      const layersArray = layersCSV?.split(',');
      setExplore(prevState => ({
        ...prevState,
        region: {
          ...prevState.region,
          ...(initialLatitude && { latitude: parseFloat(initialLatitude) }),
          ...(initialLongitude && { longitude: parseFloat(initialLongitude) }),
          ...(zoom && zoomObject),
        },
        ...(mapTheme && { mapTheme }),
        ...(defaultRegion && { defaultRegion }),
        ...(riskEstimateId && { riskEstimateId }), // TODO: Move this to contentCard
        ...(mapType && { mapType }),
        ...(basemap && { basemap }),
        ...(layers && { layers: layersArray }),
        mapSettings: {
          ...prevState.mapSettings,
          ...validSettings,
        },
      }));
      setContentCard(prevState => {
        const newState = {
          ...prevState,
          // TODO: Move these here
          // ...(riskEstimateId && { riskEstimateId }),
          // ...(scenarioId && { scenarioId }),
          ...(projectionType && { projectionType }),
          ...(projectionFormat && { projectionFormat }),
          ...(minProbability && { minProbability }),
          ...(unitSystem && { unitSystem }),
        };
        return newState}
      );
      solutionName && setSolutionName(solutionName);
    }

    Linking.getInitialURL().then(url => {
      if (url) handleInitialUrl(url);
    });
  }, []);

  // Reload the page when the window is resized
  // This is a workaround for a bug with the react-navigation drawer menu getting stuck when the window is resized
  useEffect(() => {
    const handleResize = debounce(() => {
      const isDrawerMenuActive = window.innerWidth < 1280 && !(window.location.pathname.includes('RiskEstimate') || window.location.pathname.includes('FloodProjection') || window.location.pathname.includes('SlrProjection') || window.location.pathname.includes('Solution'));
      if (!isDrawerMenuActive) return;
      window.location.reload();
    }, 500);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const addBanner = useCallback((banner, timer) => {
    setBanners(prevBanners => {
      return uniqBy([...prevBanners, banner], 'text');
    });

    if (timer && Number.isInteger(timer)) {
      setTimeout(() => removeBanner(banner.text), timer);
    }
  }, [banners]);

  function removeBanner(text) {
    setBanners(banners.filter(banner => banner.text !== text));
  }

  function handleError(err) {
    // handle errors thrown anywhere in the app by passing them here, use:
    // uiContext.handleError(err)
    let message = 'Something went wrong, please try again';
    if (err) {
      message = err.toString();
      if (err.response?.status) {
        switch (err.response.status) {
          // TODO: Handle other status codes
          default:
            message = err.response.data.message;
            break;
        }
      }
    }
    console.warn(err);
    showBanner(message, 'error', true);
  }

  const uiContext = {
    auth: {
      user: user,
      // TODO: Implement login and logout, etc
    },
    banners,
    addBanner,
    removeBanner,
    setBanners,
    header,
    setHeader: e => setHeader({ height: e.nativeEvent.layout.height }),
    modal: {
      show: x => setModal({ ...modal, visible: true }),
      hide: x => setModal({ ...modal, visible: false }),
      visible: modal.visible,
    },
    bbox,
    setBbox,
    explore: explore,
    setExplore: x => {
      const newRegion = { ...explore.region, ...x.region };
      // This function makes sure that region object values are numbers
      const numericRegion = Object.fromEntries(Object.entries(newRegion).map(([k, v]) => [k, Number(v)]));
      const newExplore = {
        // Merge new values with existing explore state
        ...explore,
        ...x,
        region: numericRegion,
      };
      setExplore(newExplore);
    },
    contentCard,
    setContentCard: x => {
      setContentCard({ ...contentCard, ...x });
    },
    solutionName,
    setSolutionName: x => setSolutionName(x),
    handleError: handleError,
  };

  let [fontsLoaded] = useFonts({
    WorkSans_300Light,
    WorkSans_400Regular,
    WorkSans_600SemiBold,
    WorkSans_700Bold,
    Maitree_300Light,
    Maitree_400Regular,
    Maitree_600SemiBold,
  }) || [false];

  return (
    <QueryClientProvider client={queryClient} testID="query-component">
      <UiContext.Provider value={uiContext}>
        <NativeBaseProvider theme={theme}>
          {fontsLoaded
            ? <Router linking={linking} />
            : <Spinner
                testID="spinner-component"
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: 'white',
                  opacity: 0.3
                }}
                size='lg' />
          }
        </NativeBaseProvider>
      </UiContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
