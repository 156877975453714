const fg = {
  yellow: { _text: { color: 'warm.200' }, _icon: { color: 'warm.200' } },
  accent: { _text: { color: 'tertiary.600' }, _icon: { color: 'tertiary.600' } },
};

const hc = {
  yellow: {
    _text: { color: 'white' },
    _icon: { color: 'white' },
    _hover: fg.yellow,
  },
  accent: {
    _text: { color: 'primary.700' },
    _icon: { color: 'primary.700' },
    _hover: fg.accent,
  },
};

const darkBGButton = {
  shadow: 'cc-button',
  bg: 'tertiary.700',
  _text: { color: 'white' },
  _icon: { color: 'white' },
  _hover: { bg: 'tertiary.600', shadow: 'cc-button-hover' },
};

const darkBGButtonAlt = {
  shadow: 'cc-button',
  bg: 'white',
  _text: { color: 'tertiary.700' },
  _icon: { color: 'tertiary.700' },
  _hover: { bg: 'tertiary.100', shadow: 'cc-button-hover' },
};

const darkBGOutlineButton = {
  _text: { color: 'primary.100' },
  _icon: { color: 'primary.300' },
  borderColor: 'primary.500',
  borderStyle: 'solid',
  borderWidth: '1px',
  _hover: { _text: { color: 'warm.200' }, _icon: { color: 'warm.200' }, borderColor: 'warm.200', bg: 'transparent' },
  _disabled: { cursor: 'not-allowed', _text: { color: 'primary.400' }, _icon: { color: 'primary.400' }, borderColor: 'primary.400' },
};

const lightBGButton = {
  shadow: 'cc-button-light',
  bg: 'tertiary.700',
  _text: { color: 'white' },
  _icon: { color: 'white' },
  _hover: { bg: 'tertiary.600', shadow: 'cc-button-light-hover' },
};

const lightBGOutlineButton = {
  _text: { color: 'tertiary.700' },
  _icon: { color: 'tertiary.700' },
  borderColor: 'tertiary.700',
  borderStyle: 'solid',
  borderWidth: '1px',
  _hover: { _text: { color: 'tertiary.600' }, _icon: { color: 'tertiary.600' }, borderColor: 'tertiary.600', bg: 'transparent' },
  _disabled: { cursor: 'not-allowed', _text: { color: 'primary.700' }, _icon: { color: 'primary.600' }, borderColor: 'primary.700' },
};

const largeButtonPadding = {
  paddingTop: 13,
  paddingRight: 13,
  paddingBottom: 13,
  paddingLeft: 13,
};

const smallButtonPadding = {
  paddingTop: 0.75,
  paddingRight: 0.75,
  paddingBottom: 0.75,
  paddingLeft: 0.75,
};

const containerStyles = {
  baseStyle: {
    maxWidth: '100%',
  },
  variants: {
    primary: {
      _dark: {
        bg: 'primary.700',
      },
      _light: {
        bg: 'white',
      },
    },
    alt: {
      _dark: {
        bg: 'white',
      },
      _light: {
        bg: 'primary.700',
      },
    },
    hero: {
      _dark: {
        bg: 'tertiary.600',
      },
      _light: {
        bg: 'tertiary.600',
      },
    },
    card: {
      _dark: {
        bg: 'primary.900',
      },
      _light: {
        bg: 'primary.50',
      },
    },
    warming: {
      _dark: {
        bg: 'warm.200',
      },
      _light: {
        bg: 'warm.200',
      },
    },
  },
};

const components = {
  VStack: containerStyles,
  HStack: containerStyles,
  Container: containerStyles,
  Center: containerStyles,
  Link: {
    baseStyle: {
      cursor: 'pointer',
      _dark: {
        color: 'warm.200',
      },
      _light: {
        color: 'tertiary.500',
      },
    },
    variants: {
      alt: {
        _dark: {
          color: 'primary.600',
        },
        _light: {
          color: 'primary.600',
        }
      }
    }
  },
  WarmBox: {
    baseStyle: {
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 30,
      paddingRight: 30,
      _android: {
        elevation: 1,
      },
      _dark: {
        bg: 'primary.900',
        shadow: 'cc-card-dark-bg',
      },
      _light: {
        bg: 'white',
        shadow: 'cc-card-light-bg',
      },
    },
    variants: {
      alt: () => ({
        _dark: {
          bg: 'white',
        },
        _light: {
          bg: 'primary.900',
        },
      }),
      'alt-bg': () => ({
        _dark: {
          bg: 'primary.900',
          shadow: 'cc-card-light-bg',
        },
        _light: {
          bg: 'white',
          shadow: 'cc-card-dark-bg',
        },
      }),
      'alt-alt-bg': () => ({
        _light: {
          bg: 'primary.900',
          shadow: 'cc-card-dark-bg',
        },
        _dark: {
          bg: 'white',
          shadow: 'cc-card-light-bg',
        },
      }),
    },
  },
  Heading: {
    baseStyle: {
      fontFamily: 'WorkSans_700Bold',
      marginBottom: 4,
      marginTop: 4,
      _dark: {
        color: 'white',
      },
      _light: {
        color: 'primary.700',
      },
    },
    sizes: {
      md: {
        fontSize: '2xl',
      },
      '5xl': {
        fontSize: { base: '7xl', md: '8xl' },
        letterSpacing: '-1px',
        lineHeight: '1.04em',
      },
    },
    variants: {
      hero: {
        textTransform: 'uppercase',
        _dark: {
          color: 'white',
        },
        _light: {
          color: 'white',
        },
      },
      h1: {
        fontWeight: 'light',
        fontFamily: 'Maitree_300Light',
      },
      h2: {
        fontWeight: 'light',
        fontFamily: 'WorkSans_300Light',
      },
      h3: {
        fontWeight: 'light',
        fontFamily: 'WorkSans_300Light',
      },
      h4: {
        fontFamily: 'WorkSans_600SemiBold',
      },
      h5: {
        fontFamily: 'WorkSans_600SemiBold',
        textTransform: 'uppercase',
      },
      label: {
        fontFamily: 'WorkSans_600SemiBold',
        textTransform: 'uppercase',
      },
      subhead: {
        fontWeight: 'normal',
        fontFamily: 'WorkSans_400Regular',
      },
      menuhead: {
        fontFamily: 'WorkSans_600SemiBold',
        textTransform: 'uppercase',
        marginTop: 0,
        marginBottom: 0,
        padding: 3,
        _dark: {
          bg: 'primary.300',
        },
      },
      alt: {
        _dark: {
          color: 'primary.700',
        },
        _light: {
          color: 'white',
        },
      },
    },
  },
  Text: {
    baseStyle: {
      _dark: {
        color: 'white',
      },
      _light: {
        color: 'primary.700',
      },
    },
    variants: {
      body: {
        fontFamily: 'Maitree_400Regular',
      },
      'body-bold': {
        fontFamily: 'Maitree_600SemiBold',
      },
      callout: {
        fontFamily: 'Maitree_300Light',
      },
      'callout-bold': {
        fontFamily: 'Maitree_600SemiBold',
      },
      card: {
        fontFamily: 'WorkSans_400Regular',
      },
      'card-bold': {
        fontFamily: 'WorkSans_600SemiBold',
      },
      'card-accent': {
        fontFamily: 'WorkSans_600SemiBold',
        _dark: {
          color: 'warm.300',
        },
        _light: {
          color: 'warm.300',
        },
      },
      'layer-label': {
        fontFamily: 'WorkSans_600SemiBold',
      },
      alt: {
        _dark: {
          color: 'primary.700',
        },
        _light: {
          color: 'white',
        },
      },
    },
  },
  Button: {
    baseStyle: {
      _text: { fontWeight: 'bold' },
    },
    variants: {
      solid: {
        _dark: darkBGButton,
        _light: lightBGButton,
      },
      'solid-alt': {
        _dark: darkBGButtonAlt,
      },
      'solid-lightbg': {
        _dark: lightBGButton,
        _light: darkBGButton,
      },
      outline: {
        _dark: darkBGOutlineButton,
        _light: lightBGOutlineButton,
      },
      'outline-lightbg': {
        _dark: lightBGOutlineButton,
        _light: darkBGOutlineButton,
      },
      cclink: {
        _dark: hc.yellow,
        _light: hc.accent,
      },
      'cclink-alt': {
        _dark: {
          _text: { color: 'warm.300' },
          _icon: { color: 'warm.300' },
          _hover: fg.yellow,
        },
        _light: {
          _text: { color: 'tertiary.700' },
          _icon: { color: 'tertiary.700' },
          _hover: fg.accent,
        },
      },
      'cclink-footer': {
        _dark: {
          _text: { color: 'warm.300', fontWeight: 'normal' },
          _icon: { color: 'warm.300' },
          _hover: fg.yellow,
          borderRadius: '0px',
          borderRightColor: 'primary.700',
          borderRightWidth: '1px',
          borderRightStyle: 'solid',
        },
        _light: {
          _text: { color: 'tertiary.700', fontWeight: 'normal' },
          _icon: { color: 'tertiary.700' },
          _hover: fg.accent,
        },
      },
      'cclink-footer-small': {
        _dark: {
          _text: { color: 'white', fontWeight: 'normal' },
          _icon: { color: 'white' },
          _hover: fg.yellow,
        },
        _light: {
          _text: { color: 'tertiary.700', fontWeight: 'normal' },
          _icon: { color: 'tertiary.700' },
          _hover: fg.accent,
        },
      },
      'cclink-light': {
        _dark: hc.accent,
        _light: hc.yellow,
      },
      cctab: {
        _dark: {
          borderColor: 'transparent.white10',
          borderTopWidth: '5px',
          borderTopStyle: 'solid',
          borderRadius: 0,
          marginRight: 3,
          _hover: {
            borderColor: 'warm.300',
            borderTopWidth: '5px',
            borderTopStyle: 'solid',
          },
        },
      },
      'cc-tabbar': {
        paddingTop: '20px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        _dark: {
          _text: { color: 'primary.300' },
          _icon: { color: 'primary.300' },
          _hover: 'primary.100',
        },
        _light: {
          _text: { color: 'tertiary.700' },
          _icon: { color: 'tertiary.700' },
          _hover: 'tertiary.800',
        },
      },
      'cc-header-tab': {
        fontFamily: 'WorkSans_600SemiBold',
        borderRadius: 0,
        borderRightWidth: 1,
        borderColor: 'primary.300',
        paddingTop: 22,
        paddingBottom: 22,
        paddingLeft: 21,
        paddingRight: 21,
        _dark: {
          _text: { color: 'primary.700' },
          _hover: { _text: { color: 'tertiary.600' } },
        },
      },
      'cc-nav-tab': {
        fontFamily: 'WorkSans_600SemiBold',
        borderRadius: 0,
        paddingTop: 14,
        paddingBottom: 18,
        paddingLeft: 23,
        paddingRight: 23,
        borderTopWidth: 4,
        borderColor: 'primary.700',
        _dark: {
          _text: { color: 'white' },
          _hover: { _text: { color: 'secondary.50' }, borderTopColor: 'warm.500' },
        },
      },
      'cc-nav-tab-active': {
        fontFamily: 'WorkSans_600SemiBold',
        borderRadius: 0,
        paddingTop: 14,
        paddingBottom: 18,
        paddingLeft: 23,
        paddingRight: 23,
        borderTopWidth: 4,
        borderColor: 'primary.700',
        borderTopColor: 'warm.500',
        _dark: {
          _text: { color: 'white' },
        },
      },
    },
  },
  IconButton: {
    defaultProps: { size: null },
    variants: {
      'primary-light-bg': {
        ...largeButtonPadding,
        _icon: {
          color: 'white',
        },
        _hover: {
          bg: 'tertiary.600',
        },
        _dark: {
          bg: 'primary.700',
        },
        _light: {
          bg: 'tertiary.700',
        },
      },
      'share-light-bg': {
        ...largeButtonPadding,
        _icon: {
          color: 'white',
        },
        _hover: {
          bg: 'tertiary.600',
        },
        _dark: {
          bg: 'tertiary.700',
        },
        _light: {
          bg: 'tertiary.700',
        },
      },
      primary: {
        ...largeButtonPadding,
        _icon: {
          color: 'white',
        },
        _hover: {
          bg: 'tertiary.600',
        },
        _dark: {
          bg: 'tertiary.700',
        },
        _light: {
          bg: 'primary.700',
        },
      },
      'secondary-vivid': {
        ...smallButtonPadding,
        _icon: {
          color: 'primary.100',
          size: 'md',
        },
        bg: 'secondary.500',
        borderColor: 'secondary.500',
        borderRadius: 0,
        borderStyle: 'solid',
        borderWidth: '1px',
        _hover: {
          bg: 'warm.600',
          borderColor: 'warm.600',
          _icon: {
            color: 'white',
          },
        },
      },
      'secondary-dark': {
        ...smallButtonPadding,
        _icon: {
          color: 'primary.300',
          size: 'md',
        },
        shadow: 'cc-button',
        bg: 'primary.700',
        borderColor: 'primary.700',
        borderRadius: 0,
        borderStyle: 'solid',
        borderWidth: '1px',
        _hover: {
          bg: 'primary.900',
          borderColor: 'primary.900',
          _icon: {
            color: 'warm.200',
          },
        },
      },
      'secondary-light': {
        ...smallButtonPadding,
        _icon: {
          color: 'primary.700',
          size: 'md',
        },
        bg: 'primary.100',
        borderRadius: 0,
        borderColor: 'tertiary.700',
        borderStyle: 'solid',
        borderWidth: '1px',
        _hover: {
          bg: 'white',
          borderColor: 'tertiary.600',
          _icon: {
            color: 'tertiary.600',
          },
        },
      },
      'googlemaps': {
        paddingTop: 2,
        paddingRight: 2,
        paddingBottom: 2,
        paddingLeft: 2,
        width: '40px',
        height: '40px',
        _icon: {
          color: 'rgb(102,102,102)',
          size: 'md',
        },
        bg: 'white',
        borderRadius: 2,
        borderWidth: 0,
        boxShadow: 'google-maps',
        _hover: {
          bg: 'white',
          _icon: {
            color: 'rgb(51,51,51)',
          },
        },
      },
      'googlemaps-small': {
        paddingTop: 2,
        paddingRight: 2,
        paddingBottom: 2,
        paddingLeft: 2,
        width: '20px',
        height: '20px',
        _icon: {
          color: 'rgb(102,102,102)',
          size: 'md',
        },
        bg: 'white',
        borderRadius: 2,
        borderWidth: 1,
        borderColor: 'rgb(102,102,102)',
        boxShadow: 'google-maps',
        _hover: {
          bg: 'white',
          _icon: {
            color: 'rgb(51,51,51)',
          },
        },
      },
    },
  },
  Modal: {
    sizes: {
      vlg: {
        contentSize: {
          width: '95%',
          maxWidth: '2000',
        },
      },
    },
  },
};

export default components;
