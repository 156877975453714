import React, { useEffect, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { Column, Row, Heading, useTheme } from 'native-base';
import * as d3 from 'd3';

const BarChart = ({ timeseries, anchorRef }) => {
  if (!timeseries) return console.log('No timeseries data provided for BarChart');
  const data = timeseries.data;
  const { x: xLabel, y: yLabel } = timeseries.labels;
  const wrapperRef = useRef();
  const svgRef = useRef();
  const theme = useTheme();
  useEffect(() => {
    if (!data || !svgRef.current) return;
    const svg = d3.select(svgRef.current);
    const update = () => {

      d3.select(svgRef.current).selectAll("*").remove(); // Clear existing SVG elements to reset before rendering
      const bounds = wrapperRef.current.getBoundingClientRect();
      const wrapperWidth = bounds.width;
      const wrapperHeight = wrapperWidth * (1 / 2); // Set the aspect ratio here
      const margin = {top: 30, right: 30, bottom: 30, left: 60};
      const width = wrapperWidth - margin.left - margin.right;
      const height = wrapperHeight - margin.top - margin.bottom;

      svg.attr("viewBox", `0 0 ${wrapperWidth} ${wrapperHeight + margin.top + margin.bottom}`)
        .attr("preserveAspectRatio", "xMidYMid meet");

      const chart = svg.append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const x = d3.scaleBand()
        .range([0, width])
        .domain(data.map(d => d.x))
        .padding(0.1);

      const y = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.y)])
        .range([height, 0]);

      chart.append("g")
        .attr("transform", `translate(0,${wrapperHeight - margin.top - margin.bottom})`)
        .call(d3.axisBottom(x));

      chart.append("g").call(d3.axisLeft(y));

      chart.selectAll(".bar")
        .data(data)
        .enter().append("rect")
        .attr("class", "bar")
        .attr("x", d => x(d.x))
        .attr("y", d => y(d.y))
        .attr("width", x.bandwidth())
        .attr("height", d => height - y(d.y))
        .attr("fill", theme.colors.tertiary[600]);

      chart.append("text")
        .attr("text-anchor", "end")
        .attr("x", (width / 2 + margin.left) - xLabel.length * 10)
        .attr("y", height + margin.top + 20)
        .attr("fill", "white")
        .attr('font-weight', 'bold')
        .attr('font-family', 'WorkSans_600SemiBold')
        .text(xLabel);

      chart.append("text")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-90)")
        .attr("y", -margin.left + 20)
        .attr("x", ((-margin.top - height) / 2) + (yLabel.length * 5))
        .attr("fill", "white")
        .attr('font-weight', 'bold')
        .attr('font-family', 'WorkSans_600SemiBold')
        .text(yLabel);
    };
    update();
    window.addEventListener('resize', update);
    return () => {
      window.removeEventListener('resize', update);
    };
  }, [timeseries]);

  return (
    <Column justifyContent='flex-start'>
      <Row pt="40px">
        <Heading variant='h4' size='md' ref={anchorRef}>{timeseries.title}</Heading>
      </Row>
      <Row style={{maxWidth: 800, width: '100%'}} marginX={'auto'} marginY={0}>
        <Column ref={wrapperRef} mb='20px' style={{ width: "100%", height: "auto"}}>
          <svg ref={svgRef} style={{ width: "100%", height: "auto" }} />
        </Column>
      </Row>
    </Column>
  );
};

export default BarChart;

const styles = StyleSheet.create({
  rankHead: {
    borderBottomColor: 'rgba(255,255,255,0.5)',
    borderBottomWidth: 2,
    width: '100%',
    margin: 0,
    marginBottom: 10,
  },
  rankRow: {
    borderBottomColor: 'rgba(255,255,255,0.5)',
    borderBottomWidth: 1,
    paddingTop: 5,
    paddingBottom: 5,
    margin: 0,
  },
  gradient: {
    width: 30,
    height: 175,
  },
  labels: {
    marginLeft: 10,
    justifyContent: 'space-between',
  },
  labelText: {
    fontFamily: 'WorkSans_600SemiBold',
    fontWeight: 'bold',
    margin: 0,
  }
});