import React, { useContext, useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Row,
  Select,
  CheckIcon,
  CloseIcon,
  Column,
  Popover,
  Icon,
  IconButton,
  Heading,
  Slider,
  Switch,
  Text,
  Radio,
  View,
  Spinner,
  Tooltip,
} from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import { Platform, ScrollView, useWindowDimensions, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { decode } from 'he';
import TooltipWrapper from './TooltipWrapper';
import UiContext from '../UiContext';
import ApiContext from '../ApiContext';
import { useIsTabLayout } from '../utils';

const SettingWidget = ({ setting, isLast }) => {
  const { explore, setExplore, contentCard, setContentCard } = useContext(UiContext); // This contains information about current settings in the UI state
  const currentValue = explore.mapSettings[setting.name];
  const currentValueLabel = setting.choices.find(choice => choice.value === currentValue)?.text;
  const currentValueIndex = setting.choices.findIndex(choice => choice.value === currentValue);
  const settingStyle = isLast ? { marginBottom: 20 } : {};
  if (setting.widget === 'slider') {
    return (
      <View key={setting.name} style={settingStyle}>
        <Row justifyContent='space-between'>
          <Heading variant='label' size='xs' color='primary.700' mt={4} flexDirection='row' justifyContent='space-between'>
            {setting.label}
          </Heading>
          {setting.description && (
            <TooltipWrapper label={decode(setting.description)} placement='right top' maxW='72' />
          )}
        </Row>
        <Heading variant='h3' size='md' color='primary.700' mt={4}>
          {currentValueLabel}
        </Heading>
        <Slider
          onChangeEnd={v => {
            const newSelection = setting.choices[v];
            const newMapSettings = { ...explore.mapSettings };
            newMapSettings[setting.name] = newSelection.value;
            setExplore({ mapSettings: newMapSettings });
          }}
          w='3/4'
          maxW='300'
          _selectedValue={currentValueIndex}
          defaultValue={currentValueIndex}
          minValue={0}
          maxValue={setting.choices.length - 1}
          accessibilityLabel={setting.label}
          step={1}
        >
          <Slider.Track>
            <Slider.FilledTrack />
          </Slider.Track>
          <Slider.Thumb />
        </Slider>
      </View>
    );
  }

  if (setting.widget === 'comparison') {
    const currentValues = JSON.parse(currentValue);
    const valueLabels = currentValues.map(v => setting.choices.find(choice => choice.value === v)?.text);
    const temp1 = currentValues[0];
    const temp1Index = setting.choices.findIndex(choice => choice.value === temp1);
    const temp2 = currentValues[1];
    const temp2Index = setting.choices.findIndex(choice => choice.value === temp2);
    const updateRange = (v, i) => {
      const newSelection = setting.choices[v];
      const newMapSettings = { ...explore.mapSettings };
      const newValue = [...currentValues];
      newValue[i] = newSelection.value;
      newMapSettings[setting.name] = JSON.stringify(newValue);
      setExplore({ mapSettings: newMapSettings });
    }
    if (currentValues.length > 1) {
      return (<View key={setting.name} style={settingStyle}>
        <Row justifyContent='space-between'>
          <Heading variant='label' size='xs' color='primary.700' mt={4} flexDirection='row' justifyContent='space-between'>
            {setting.label}
          </Heading>
          {setting.description && (
            <TooltipWrapper label={decode(setting.description)} placement='right top' maxW='72' />
          )}
        </Row>
        <Heading variant='h3' size='md' color='primary.700' mt={4}>
          {valueLabels[0]}
        </Heading>
        <Slider
          key={setting.label + '0'}
          onChangeEnd={(v)=>updateRange(v, 0)}
          w='3/4'
          maxW='300'
          _selectedValue={temp1Index}
          defaultValue={temp1Index}
          minValue={0}
          maxValue={setting.choices.length - 1}
          accessibilityLabel={setting.label}
          step={1}
          colorScheme={'tertiary'}
          style={{ zIndex: 1 }}
        >
          <Slider.Track>
            <Slider.FilledTrack />
          </Slider.Track>
          <Slider.Thumb />
        </Slider>
        <Heading variant='h3' size='md' color='primary.700' mt={4}>
          vs {valueLabels[1]}
        </Heading>
        <Slider
          key={setting.label + '1'}
          onChangeEnd={(v)=>updateRange(v, 1)}
          w='3/4'
          maxW='300'
          _selectedValue={temp2Index}
          defaultValue={temp2Index}
          minValue={0}
          maxValue={setting.choices.length - 1}
          accessibilityLabel={setting.label}
          step={1}
          colorScheme={'warm'}
          style={{ zIndex: 0 }}
        >
          <Slider.Track>
            <Slider.FilledTrack />
          </Slider.Track>
          <Slider.Thumb />
        </Slider>
      </View>)
    }
  }

  if (setting.widget === 'switch') {
    const selectedIndex = setting.choices.findIndex(choice => choice.value === currentValue);
    return (
      <View key={setting.name} style={settingStyle}>
        <Heading variant='label' size='xs' color='primary.700' mt={4}>
          {setting.label}
        </Heading>
        {setting.description && (
          <TooltipWrapper label={decode(setting.description)} placement='right top' maxW='72' />
        )}
        <Row>
          <Text color='primary.700'>{startCase(setting.choices[0].text)} </Text>
          <Switch
            isChecked={!!selectedIndex}
            defaultIsChecked={!!selectedIndex}
            onTrackColor='primary.700'
            offTrackColor='primary.700'
            onToggle={v => {
              const newSelection = setting.choices[v ? 1 : 0];
              const newMapSettings = { ...explore.mapSettings };
              newMapSettings[setting.name] = newSelection.value;
              // NOTE: This ensures that if the user changes the water or temperature unit,
              // the unit_system parameter which is used everywhere else is updated as well
              if (['temperature_unit', 'water_unit'].includes(setting.name)) {
                const unitSystem = ['C', 'm'].includes(newSelection.value) ? 'metric' : 'english';
                setContentCard({ ...contentCard, unitSystem });
              }
              setExplore({ mapSettings: newMapSettings });
            }}
          />
          <Text color='primary.700'> {startCase(setting.choices[1].text)}</Text>
        </Row>
      </View>
    );
  }
  if (setting.widget === 'radio') {
    return (
      <View key={setting.name} style={settingStyle}>
        <Heading variant='label' size='xs' color='primary.700' mt={4}>
          {setting.label}
        </Heading>
        {setting.choices.map(choice => (
          <Radio.Group
            key={choice.value}
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            accessibilityLabel={setting.label}
            mt={2}
            onChange={value => {
              const newMapSettings = { ...explore.mapSettings };
              newMapSettings[setting.name] = value;
              setExplore({ mapSettings: newMapSettings });
            }}
            value={currentValue}
          >
            <Radio value={choice.value} size='sm' accessibilityLabel={choice.text}>
              <Text color='primary.700'>{startCase(choice.text)}</Text>
            </Radio>
          </Radio.Group>
        ))}
      </View>
    );
  }
  console.log('Unimplemented setting', setting.widget);
};

const ScenariosPopover = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const getFooterHeight = event => {
    setFooterHeight(event.nativeEvent.layout.height);
  };
  const windowDimensions = useWindowDimensions();
  const isTabLayout = useIsTabLayout();
  const insets = useSafeAreaInsets();
  const apiData = useContext(ApiContext); // This contains information about currently valid settings and default values
  const { explore, setExplore, header, banners, removeBanner } = useContext(UiContext); // This contains information about current settings in the UI state
  const mainSettings = get(apiData.mapSettings, 'data.main_settings', []);
  const otherSettings = get(apiData.mapSettings, 'data.other_settings', []);
  const themes = (apiData.mapTypes && apiData.mapTypes.data && apiData.mapTypes.data.filter(type => !type.geo_disabled)) || [];

  const settingBanners = banners.filter(banner => banner.location === 'scenarios-popover' || !banner.location);

  useEffect(() => {
    if (!contentHeight) {
      setContentHeight(windowDimensions.height - insets.top - insets.bottom - header.height - footerHeight + Platform.select({ web: 0, native: 14 }));
    }
  }, [contentHeight, insets.bottom, insets.top, windowDimensions.height, header, footerHeight]);
  return (
    <Popover
      trigger={props => (
        <Button
          {...props}
          variant='cc-header-tab'
          onPress={() => setIsOpen(true)}
          {...(isOpen && {
            _text: {
              color: '#0080b3',
            },
          })}
          style={{
            borderBottomWidth: 3,
            borderBottomColor: isOpen ? '#0080b3' : 'transparent',
          }}
        >
          Select Scenario
        </Button>
      )}
      isOpen={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      placement='bottom left'
    >
      <Popover.Content
        accessibilityLabel='Scenarios'
        width={Platform.OS === 'web' ? (isTabLayout ? '100vw' : '360') : 'full'}
        height={contentHeight}
        backgroundColor='primary.100'
        borderWidth='0'
        borderRadius='0'
        style={{
          boxShadow: 'inset 0px 5px 5px rgba(3, 40, 73, 0.11), 10px 10px 20px rgba(3, 40, 73, 0.1)',
          elevation: 1, // Android shadow
          ...(Platform.OS === 'android' && { marginTop: 23 }),
        }}
      >
        <ScrollView>
          <Popover.Body bg='transparent' style={{ boxShadow: 'none' }}>
            <Row justifyContent='space-between'>
              <Heading variant='label' size='xs' color='primary.700'>
                View Areas at Risk by
              </Heading>
              <TooltipWrapper label={'Choose Risk Type'} placement='right top' maxW='72' />
            </Row>
            <Select
              color='primary.700'
              _customDropdownIconProps={{
                color: 'primary.700',
              }}
              minWidth='200'
              accessibilityLabel='Risk Type'
              placeholder='Risk Type'
              _selectedItem={{
                endIcon: <CheckIcon size='5' />,
              }}
              mt={1}
              value={explore.mapType}
              _selectedValue={explore.mapType}
              defaultValue={explore.mapType}
              onValueChange={mapType => {
                const newMapTheme = themes.find(theme => theme.name === mapType);
                setShowMore(false);
                setExplore({ mapType, mapTheme: newMapTheme.theme });
              }}
            >
              {themes.map(theme => (
                <Select.Item label={startCase(theme.label)} value={theme.name} key={theme.name} />
              ))}
            </Select>
            {mainSettings.map((setting, index) => (
              <SettingWidget key={setting.name} setting={setting} isLast={index === mainSettings.length - 1} />
            ))}
            {otherSettings.length > 0 && (
              <Button
                onPress={() => setShowMore(!showMore)}
                variant='outline-lightbg'
                width='100%'
                startIcon={<Icon as={MaterialCommunityIcons} name='cog-outline' size={18} />}
              >
                More Settings
              </Button>
            )}
            {showMore && otherSettings.map(setting => <SettingWidget key={setting.name} setting={setting} />)}
            <Column style={styles.bannerContainer}>
              {settingBanners &&
                settingBanners.length > 0 &&
                settingBanners.map((banner, i) => {
                  return (
                    <Alert style={styles.banner} status={banner.type || 'info'} key={banner.text + i}>
                      <Column space={2} flexShrink={1} w='100%'>
                        <Row flexShrink={1} space={2} justifyContent='space-between'>
                          <Row space={2} flexShrink={1}>
                            <Alert.Icon mt='1' />
                            <Text fontSize='md' color='coolGray.800'>
                              {banner.text}
                            </Text>
                          </Row>
                          <IconButton
                            onPress={() => {
                              removeBanner(banner.text);
                            }}
                            variant='unstyled'
                            _focus={{
                              borderWidth: 0,
                            }}
                            icon={<CloseIcon size='3' />}
                            _icon={{
                              color: 'coolGray.600',
                            }}
                          />
                        </Row>
                      </Column>
                    </Alert>
                  );
                })}
            </Column>
            {apiData.mapSettings.isFetching && (
              <Row alignItems='center' justifyContent='center'>
                <Spinner size='sm' color='primary.700' mt={2} />
                <Text variant='card' color='primary.700' style={{ marginTop: 7 }}>
                  {' '}
                  Updating settings...
                </Text>
              </Row>
            )}
          </Popover.Body>
        </ScrollView>
        <Popover.Footer justifyContent='flex-start' bg='blue' onLayout={getFooterHeight} style={{ borderWidth: 0, borderTopWidth: 0, elevation: 0 }}>
          <Button.Group space={2}>
            <Button variant='solid-lightbg' startIcon={<Icon as={MaterialCommunityIcons} name='check' size={18} />} onPress={() => setIsOpen(false)}>
              Done
            </Button>
          </Button.Group>
        </Popover.Footer>
      </Popover.Content>
    </Popover>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    alignItems: 'stretch',
  },
  banner: {
    marginTop: 10,
  },
});


export default ScenariosPopover;
