import React, { useState, useContext } from 'react';
import { StyleSheet, View, Platform, Text } from 'react-native';
import { Icon } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import UiContext from '../UiContext';
import ApiContext from '../ApiContext';
import { getDeltasFromZoom } from '../utils';

const googleMapsApiKey = Platform.OS === 'web' ? process.env.GOOGLE_MAPS_API_KEY_WEB : process.env.GOOGLE_MAPS_API_KEY_IOS;

export const MapSearch = (props) => {
  const [errorMessage, setErrorMessage] = useState();
  const uiContext = useContext(UiContext);
  const apiContext = useContext(ApiContext);
  const { explore, setExplore } = uiContext;
  const placesAPIURL = process.env.GOOGLE_MAPS_API_URL; // If we try to access this directly in the component prop sometimes it doesn't work.
  const { data: mapInfo } = apiContext.mapInfo;
  const { locations } = mapInfo || {};
  const predefinedPlaces = locations && locations.map(location => ({
    description: location.name,
    geometry: { location: { lat: location.latitude, lng: location.longitude } },
  }));
  return (
    <View style={styles.wrapper} {...props}>
      <GooglePlacesAutocomplete
        // https://github.com/FaridSafi/react-native-google-places-autocomplete#default-styles
        styles={{
          textInputContainer: {
            borderColor: '#7F92A2', // Nativebase primary.500 doesn't work here
            borderWidth: 1,
            backgroundColor: 'white',
            height: 50,
          },
          textInput: {
            fontFamily: 'Maitree_400Regular',
            color: '#3C5871',
            fontSize: 16,
            borderRadius: 0,
            // TODO: This isn't the best for accessibility but react-native-google-places-autocomplete
            // has poor support for focus states and their isFocused function isn't working as expected
            outline: 'none',
            margin: 0,
            backgroundColor: 'white',
            maxWidth: 'calc(100% - 45px)',
          },
          listView: {
            backgroundColor: 'white',
            borderRadius: 0,
          },
          // These are the styles for each result in the results list
          description: {
            fontFamily: 'Maitree_400Regular',
            color: 'primary.600',
          },
        }}
        enablePoweredByContainer={false}
        renderLeftButton={() => (
          <Icon as={MaterialCommunityIcons} name='map-marker-outline' size={25} color='primary.500' style={styles.searchIcon} />
        )}
        predefinedPlacesAlwaysVisible={false}
        predefinedPlaces={predefinedPlaces}
        placeholder='Find location'
        minLength={2}
        returnKeyType={'search'}
        fetchDetails={true}
        listViewDisplayed={false}
        query={{
          key: googleMapsApiKey,
          language: 'en',
        }}
        requestUrl={{
          useOnPlatform: 'web',
          url: placesAPIURL,
        }}
        nearbyPlacesAPI='GooglePlacesSearch'
        onPress={(data, { geometry }) => {
          const zoom = 10;
          const zoomObject = Platform.OS === 'web' ? { zoom } : getDeltasFromZoom(zoom);
          setExplore({ region: { ...zoomObject, latitude: geometry.location.lat, longitude: geometry.location.lng } });
        }}
        onFail={error => {
          setErrorMessage('Network error: ' + error);
        }}
        onNotFound={data => {
          setErrorMessage('Not found: ' + data);
        }}
        onTimeout={data => {
          setErrorMessage('Network timeout: ' + data);
        }}
        debounce={200}
      />
      {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    // This is required to properly position the results list
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
  },
  searchIcon: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: 40,
    minHeight: 40,
  },
  error: {
    color: 'white',
    backgroundColor: 'red',
    borderRadius: 5,
    padding: 5,
  },
});
