import { Modal, Button, Icon, Text, Select, Row, Column, Checkbox, Input, Link } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useState } from 'react';

function GetInfoModal({ show, toggle, download, back }) {
  const [groupValues, setGroupValues] = useState([]);
  return (
    <Modal isOpen={show} onClose={toggle} borderRadius={0}>
      <Modal.Content maxWidth="550" bg='primary.100' borderRadius={0}>
        <Modal.CloseButton />
        <Modal.Header bg='primary.300' color='primary.900' _text={{
          color: 'primary.900',
        }}>Help Improve Our Products</Modal.Header>
        <Modal.Body>
          <Row justifyContent='space-between' space='md' mb={5}>
            <Text variant='body' color='primary.700'>Please help improve our products by filling in this form. Your answers will help us understand our audience and improve our offerings. We won't share your personal information.</Text></Row>
          <Row justifyContent='space-between' space='md' mb={5}>
            <Text variant='body-bold' color='primary.700'>All fields are optional.</Text>
          </Row>
          <Row justifyContent='space-between' space='md' mb={5}>
            <Column flex={1}>
              <Input color='primary.700' placeholder='First Name' />
            </Column>
            <Column flex={1}>
              <Input color='primary.700' placeholder='Last Name' />
            </Column>
          </Row>
          <Row justifyContent='space-between' space='md' mb={5}>
            <Column flex={1}>
              <Input color='primary.700' placeholder='Organization Name' />
            </Column>
            <Column flex={1}>
              <Select placeholder='Organization Type' color='primary.700'>
                <Select.Item label="Academic" value="Academic" />
                <Select.Item label="Corporate" value="Corporate" />
                <Select.Item label="Government" value="Government" />
                <Select.Item label="Non-Profit" value="Non-Profit" />
                <Select.Item label="Individual (No Affiliation)" value="Individual" />
                <Select.Item label="Other" value="Other" />
              </Select>
            </Column>
          </Row>
          <Row justifyContent='space-between' space='md' mb={5}>
            <Input color='primary.700' flex={1} placeholder='Intended Use' />
          </Row>
          <Row justifyContent='space-between' space='md' mb={5}>
            <Input color='primary.700' flex={1} placeholder='Email' />
          </Row>
          <Row justifyContent='space-between' space='md' mb={5}>
            <Checkbox.Group flex={1} onChange={setGroupValues} value={groupValues} accessibilityLabel="choose numbers">
              <Checkbox value="emailConsent" _text={{ color: 'primary.700', fontFamily: 'Maitree_400Regular', fontSize: 14 }}>
                I would like to sign up to receive email updates from Climate Central.
              </Checkbox>
              <Checkbox value="saveInfo" _text={{ color: 'primary.700', fontFamily: 'Maitree_400Regular', fontSize: 14 }}>Save my form entries for future downloads.</Checkbox>
            </Checkbox.Group>
          </Row>
          <Row justifyContent='space-between' space='md' mb={5}>
            <Text variant='body' color='primary.700'>We welcome you to use and share broadly!</Text>
          </Row>
          <Row justifyContent='space-between' space='md' mb={5}>
            <Text variant='body' color='primary.700'><b>PRIVACY</b> No personally identifying information entered in this form will be shared without written permission. See our <Link variant='alt' href='https://www.climatecentral.org/what-we-do/legal#privacy_policy'>Privacy Policy</Link> for more details.</Text>
          </Row>
          <Row justifyContent='space-between' space='md' mb={5}>
            <Text variant='body' color='primary.700'><b>PERMISSIONS</b> Please cite Climate Central in any publication utilizing them, include a link to <Link variant='alt' href='https://climatecentral.org'>climatecentral.org</Link> if they are used online, and adhere to our <Link variant='alt' href='https://climatecentral.org/what-we-do/legal#content_licensing'>Terms of Use</Link>.</Text>
          </Row>
        </Modal.Body>
        <Modal.Footer bg='primary.100'>
          <Button.Group space={2}>
            <Button
              variant='cclink-light'
              startIcon={<Icon as={MaterialCommunityIcons} name='arrow-left' size={18} />}
              onPress={back}
            >
              Back
            </Button>
            <Button
              variant='cclink-light'
              startIcon={<Icon as={MaterialCommunityIcons} name='file-download' size={18} />}
              onPress={download}
            >
              Download
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

export default GetInfoModal;