const colors = {
  primary: {
    50: '#f0f2f4',
    100: '#E6EAED', // "100 Navy" from Figma
    200: '#d4dbe0',
    300: '#C5CED5', // "300 Navy" from Figma
    400: '#97a7b4',
    500: '#7F92A2',
    600: '#3C5871', // "600 Navy" from Figma
    700: '#032849', // "700 Navy" Base Color
    800: '#031f38',
    900: '#031423', // "900 Navy" from Figma
  },
  warm: {
    200: '#FEE003', // Yellow 200 from Figma
    250: '#FFC503',
    300: '#F8BC0D', // Yellow 300 from Figma
    350: '#FF9350',
    400: '#FF8035',
    450: '#FE6C2D',
    500: '#F74F58',
    550: '#D04F53',
    600: '#9A3739',
  },
  secondary: {
    50: '#ffe1ef',
    100: '#ffb1cd',
    200: '#ff7eab',
    300: '#ff4c89',
    400: '#ff1a68',
    500: '#e6004e', // Radish from Figma
    600: '#b4003d',
    700: '#82002b',
    800: '#500019',
    900: '#21000a',
  },
  tertiary: {
    50: '#d9faff',
    100: '#adeaff',
    200: '#90CDE4', // Blue Tint from Figma
    300: '#4dccff',
    400: '#24befe',
    500: '#12a4e5',
    600: '#0080b3', // Sky Blue from Figma
    700: '#01527A', // Accent / Blue Shade from Figma
    800: '#003750',
    900: '#001320',
  },
  none: {
    50: 'transparent',
    100: 'transparent',
    200: 'transparent',
    300: 'transparent',
    400: 'transparent',
    500: 'transparent',
    600: 'transparent',
    700: 'transparent',
    800: 'transparent',
    900: 'transparent',
  },
  transparent: {
    none: 'transparent',
    gray65: 'rgba(3, 40, 73, 0.7)',
    gray10: 'rgba(3, 40, 73, 0.1)',
    blue50: 'rgba(0, 124, 173, 0.5)',
    white10: 'rgba(255, 255, 255, 0.1)',
  },
};

export default colors;