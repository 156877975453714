import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Heading, Button, IconButton, useColorMode, Column, Row, Icon, Tooltip } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import ScreenWrapper from '../ScreenWrapper';

export const ButtonExample = () => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark';
  return (
    <ScreenWrapper>
      <Column space={1} bg={isDark ? 'primary.700' : 'white'} style={styles.column}>
        <Heading size='sm'>On Default Background</Heading>
        <Row>
          <Button
            variant='cclink'
            startIcon={<Icon as={MaterialCommunityIcons} name='cog-outline' size={18} />}
            endIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={6} />}
            style={styles.button}
          >
            CC Link
          </Button>
          <Button
            variant='cclink-alt'
            startIcon={<Icon as={MaterialCommunityIcons} name='cog-outline' size={18} />}
            endIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={6} />}
            style={styles.button}
          >
            CC Link Alt
          </Button>
        </Row>
        <Row>
          <Button
            variant='solid'
            startIcon={<Icon as={MaterialCommunityIcons} name='cog-outline' size={18} />}
            endIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={6} />}
            style={styles.button}
          >
            Solid
          </Button>
          <Button
            variant='outline'
            startIcon={<Icon as={MaterialCommunityIcons} name='cog-outline' size={18} />}
            endIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={6} />}
            style={styles.button}
          >
            Outline
          </Button>
        </Row>
        <Row>
          <Button
            isDisabled
            variant='outline'
            startIcon={<Icon as={MaterialCommunityIcons} name='cog-outline' size={18} />}
            endIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={6} />}
            style={styles.button}
          >
            Disabled Outline
          </Button>
        </Row>
        <Row>
          <IconButton variant='primary' icon={<Icon as={MaterialCommunityIcons} name='arrow-right' size='6xl' />} style={styles.button} />
        </Row>
        <Row>
          <IconButton variant='secondary-vivid' icon={<Icon as={MaterialCommunityIcons} name='chevron-down' size='md' />} style={styles.button} />
          <IconButton variant='secondary-dark' icon={<Icon as={MaterialCommunityIcons} name='chevron-down' size='md' />} style={styles.button} />
          <IconButton variant='secondary-light' icon={<Icon as={MaterialCommunityIcons} name='chevron-down' size='md' />} style={styles.button} />
        </Row>
      </Column>

      <Column space={1} bg={!isDark ? 'primary.700' : 'white'} style={styles.column}>
        <Heading color={isDark ? 'primary.700' : 'white'} size='sm'>
          On Alt Background
        </Heading>
        <Row>
          <Button
            variant='cclink-light'
            startIcon={<Icon as={MaterialCommunityIcons} name='cog-outline' size={18} />}
            endIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={6} />}
            style={styles.button}
          >
            CC Link Light BG
          </Button>
        </Row>
        <Row>
          <Tooltip
            label={'This is a sample tooltip with filler text to make sure that the tooltip component is working correctly'}
            placement='right top'
            maxW='80'
          >
            <Button
              variant='cclink-light'
              startIcon={<Icon as={MaterialCommunityIcons} name='information-outline' size={18} />}
              style={styles.button}
            />
          </Tooltip>
        </Row>
        <Row>
          <Button
            variant='solid-lightbg'
            startIcon={<Icon as={MaterialCommunityIcons} name='cog-outline' size={18} />}
            endIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={6} />}
            style={styles.button}
          >
            Solid Light BG
          </Button>
          <Button
            variant='outline-lightbg'
            startIcon={<Icon as={MaterialCommunityIcons} name='cog-outline' size={18} />}
            endIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={6} />}
            style={styles.button}
          >
            Outline Light BG
          </Button>
        </Row>
        <Row>
          <Button
            isDisabled
            variant='outline-lightbg'
            startIcon={<Icon as={MaterialCommunityIcons} name='cog-outline' size={18} />}
            endIcon={<Icon as={MaterialCommunityIcons} name='arrow-right' size={6} />}
            style={styles.button}
          >
            Disabled Outline Light BG
          </Button>
        </Row>
        <Row>
          <IconButton variant='primary-light-bg' icon={<Icon as={MaterialCommunityIcons} name='arrow-right' size='6xl' />} style={styles.button} />
        </Row>
      </Column>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  column: {
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  button: {
    margin: 4,
  },
});
