import * as React from "react"
import Svg, { Path } from "react-native-svg"
const HorizontalLogo = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={120}
    height={9}
    preserveAspectRatio="xMinYMin"
    viewBox="0 0 683 52"
    {...props}
  >
    <Path
      d="m34.964 38.444-5.305-5.357c-2.975 2.706-5.63 4.436-9.958 4.436-6.496 0-10.987-5.413-10.987-11.908v-.107c0-6.495 4.601-11.799 10.987-11.799 3.789 0 6.767 1.623 9.689 4.275l5.303-6.117c-3.518-3.463-7.792-5.845-14.938-5.845C8.119 6.022 0 14.844 0 25.615v.11c0 10.88 8.281 19.486 19.43 19.486 7.306 0 11.638-2.599 15.534-6.767m36.049 6.117v-7.577H52.124V6.671h-8.336v37.89h27.225Zm17.158-37.89h-8.335v37.887h8.335V6.671Zm49.525 37.89V6.671h-8.987l-9.957 16.022-9.959-16.022h-8.985v37.89h8.174V19.986l10.553 16.023h.217l10.663-16.185v24.737h8.281Zm48.171 0L169.63 6.403h-7.685l-16.238 38.158h8.497l3.463-8.499h16.024l3.463 8.499h8.713Zm-15.154-15.859h-10.067l5.034-12.286 5.033 12.286Zm46.982-14.345V6.671h-31.394v7.686h11.53v30.204h8.336V14.357h11.528Zm37.4 30.204v-7.417h-20.566v-7.956h17.86v-7.414h-17.86v-7.686h20.297V6.671h-28.579v37.89h28.848Zm185.004-6.117-5.305-5.357c-2.978 2.706-5.63 4.436-9.959 4.436-6.495 0-10.989-5.413-10.989-11.908v-.107c0-6.495 4.601-11.799 10.989-11.799 3.789 0 6.764 1.623 9.688 4.275l5.305-6.117c-3.518-3.463-7.795-5.845-14.939-5.845-11.638 0-19.757 8.822-19.757 19.593v.11c0 10.88 8.282 19.486 19.431 19.486 7.308 0 11.637-2.599 15.536-6.767m37.671 6.117v-7.417h-20.567v-7.956h17.862v-7.414h-17.862v-7.686h20.296V6.671H448.92v37.89h28.85Zm43.248 0V6.671h-8.228v23.328L495.036 6.671h-7.685v37.89h8.228V20.474l18.349 24.087h7.09Zm39.943-30.204V6.671H529.57v7.686h11.527v30.204h8.336V14.357h11.528Zm41.3 30.204-9.258-13.531c4.818-1.787 8.123-5.629 8.123-11.747v-.108c0-7.793-5.36-12.504-14.291-12.504h-17.322v37.89h8.336V32.436h6.551l8.118 12.125h9.743Zm-9.581-24.843c0 3.191-2.327 5.356-6.385 5.356h-8.446V14.195h8.281c4.061 0 6.55 1.84 6.55 5.413v.11Zm55.047 24.843L631.489 6.403h-7.686l-16.24 38.158h8.5l3.463-8.499h16.021l3.466 8.499h8.714Zm-15.155-15.859h-10.068l5.034-12.286 5.034 12.286Zm50.391 15.859v-7.577h-18.889V6.671h-8.338v37.89h27.227"
      style={{
        fill: "#244b97",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    ></Path>
    <Path
      d="M316.121 35.974c-3.307 2.771-6.391 4.349-11.022 4.349-7.714 0-13.529-6.323-13.529-14.705v-.14c0-8.306 5.815-14.568 13.529-14.568 3.888 0 7.32 1.376 10.978 4.418a19.322 19.322 0 0 1 8.832-7.445l-.247-.241C319.299 2.361 313.287.005 305.167.005c-14.718 0-25.817 11.013-25.817 25.613v.137c0 6.918 2.568 13.334 7.235 18.067 4.713 4.776 11.165 7.405 18.165 7.405 8.206 0 13.951-2.373 19.595-8.126a19.326 19.326 0 0 1-8.224-7.127"
      style={{
        fill: "#244b97",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    ></Path>
    <Path
      d="M358.985.005c-7.656 0-14.447 3.019-19.22 7.919a19.334 19.334 0 0 1 8.779 7.485c2.519-2.799 6.144-4.499 10.3-4.499 8.07 0 14.155 6.322 14.155 14.707v.138c0 8.305-6.027 14.568-14.014 14.568-4.204 0-7.864-1.72-10.412-4.547a19.308 19.308 0 0 1-8.995 7.606c4.718 4.881 11.494 7.845 19.266 7.845 14.791 0 26.374-11.25 26.374-25.61v-.139c0-14.522-11.276-25.473-26.233-25.473"
      style={{
        fill: "#244b97",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    ></Path>
    <Path
      d="M317.292 25.616c0-8.279 6.712-14.991 14.992-14.991 8.28 0 14.993 6.712 14.993 14.991 0 8.282-6.713 14.993-14.993 14.993-8.28 0-14.992-6.711-14.992-14.993"
      style={{
        fill: "#0f83be",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    ></Path>
    <Path
      d="M332.283 40.609c-8.279 0-14.993-6.711-14.993-14.993 0-8.28 6.714-14.992 14.993-14.992 0 0-6.413 7.12-6.413 14.992 0 7.872 6.413 14.993 6.413 14.993"
      style={{
        fill: "#244b97",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
    ></Path>
  </Svg>
)
export default HorizontalLogo
