import React, { useContext } from 'react';
import { Button, Row, Column, IconButton, View, Popover, Alert, CloseIcon, Text } from 'native-base';
import { MapSearch } from './MapSearch';
import { Animated, Platform, StyleSheet, Linking } from 'react-native';
import { useSafeAreaFrame, useSafeAreaInsets } from 'react-native-safe-area-context';
import { HeaderBackground } from './HeaderBackground';
import ScenariosPopover from './ScenariosPopover';
import UiContext from '../UiContext';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { useIsTabLayout } from '../utils';

function NavLink(props) {
  const { to, children, ...rest } = props;
  const linkTo = useLinkTo();
  const route = useRoute();
  const activePath = route.path?.split('?')[0];
  // Sometimes route.path is undefined, so we need to check route.name as well, which doesn't include the slash
  const isActive = activePath ? activePath === to : route.name === to.split('/')[1];
  const isExternal = to.startsWith('http');
  return (
    <Button
      variant={isActive ? 'cc-nav-tab-active' : 'cc-nav-tab'}
      onPress={() => {
        if (isExternal) {
          Linking.openURL(to);
        } else {
          linkTo(to);
        }
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

function getDefaultHeaderHeight(layout, modalPresentation, statusBarHeight) {
  let headerHeight;
  const isLandscape = layout.width > layout.height;
  if (Platform.OS === 'ios') {
    if (Platform.isPad || Platform.isTV) {
      if (modalPresentation) {
        headerHeight = 56;
      } else {
        headerHeight = 50;
      }
    } else {
      if (isLandscape) {
        headerHeight = 32;
      } else {
        if (modalPresentation) {
          headerHeight = 56;
        } else {
          headerHeight = 68;
        }
      }
    }
  } else if (Platform.OS === 'android') {
    headerHeight = 56;
  } else {
    headerHeight = 64;
  }
  // TODO: Improve header spacing, the +40 is a hack
  return headerHeight + statusBarHeight + 40;
}

export function Header(props) {
  const insets = useSafeAreaInsets();
  const frame = useSafeAreaFrame();
  const uiContext = useContext(UiContext);
  const { banners, removeBanner } = uiContext;
  const isTabLayout = useIsTabLayout();
  const headerBanners = banners.filter((banner) => banner.location === 'header');

  const { layout = frame, modal = false, options, onLayout } = props;

  const {
    headerTitleAlign = Platform.select({
      ios: 'center',
      default: 'left',
    }),
    headerLeft,
    headerLeftLabelVisible,
    headerTransparent,
    headerTintColor,
    headerBackground,
    headerRight,
    headerLeftContainerStyle: leftContainerStyle,
    headerRightContainerStyle: rightContainerStyle,
    headerTitleContainerStyle: titleContainerStyle,
    headerBackgroundContainerStyle: backgroundContainerStyle,
    headerStyle: customHeaderStyle,
    headerShadowVisible,
    headerPressColor,
    headerPressOpacity,
    headerStatusBarHeight = insets.top,
  } = options;

  const defaultHeight = getDefaultHeaderHeight(layout, modal, headerStatusBarHeight);

  const {
    height = defaultHeight,
    minHeight,
    maxHeight,
    backgroundColor,
    borderBottomColor,
    borderBottomEndRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomStartRadius,
    borderBottomWidth,
    borderColor,
    borderEndColor,
    borderEndWidth,
    borderLeftColor,
    borderLeftWidth,
    borderRadius,
    borderRightColor,
    borderRightWidth,
    borderStartColor,
    borderStartWidth,
    borderStyle,
    borderTopColor,
    borderTopEndRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopStartRadius,
    borderTopWidth,
    borderWidth,
    boxShadow,
    elevation,
    shadowColor,
    shadowOffset,
    shadowOpacity,
    shadowRadius,
    opacity,
    transform,
    ...unsafeStyles
  } = StyleSheet.flatten(customHeaderStyle || {});

  const safeStyles = {
    backgroundColor,
    borderBottomColor,
    borderBottomEndRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomStartRadius,
    borderBottomWidth,
    borderColor,
    borderEndColor,
    borderEndWidth,
    borderLeftColor,
    borderLeftWidth,
    borderRadius,
    borderRightColor,
    borderRightWidth,
    borderStartColor,
    borderStartWidth,
    borderStyle,
    borderTopColor,
    borderTopEndRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopStartRadius,
    borderTopWidth,
    borderWidth,
    boxShadow,
    elevation,
    shadowColor,
    shadowOffset,
    shadowOpacity,
    shadowRadius,
    opacity,
    transform,
  };

  // Setting a property to undefined triggers default style
  // So we need to filter them out
  // Users can use `null` instead
  for (const styleProp in safeStyles) {
    if (safeStyles[styleProp] === undefined) {
      delete safeStyles[styleProp];
    }
  }

  const backgroundStyle = [
    safeStyles,
    headerShadowVisible === false && {
      elevation: 0,
      shadowOpacity: 0,
      borderBottomWidth: 0,
    },
  ];

  const leftButton = headerLeft
    ? headerLeft({
        tintColor: headerTintColor,
        pressColor: headerPressColor,
        pressOpacity: headerPressOpacity,
        labelVisible: headerLeftLabelVisible,
      })
    : null;

  const rightButton = headerRight
    ? headerRight({
        tintColor: headerTintColor,
        pressColor: headerPressColor,
        pressOpacity: headerPressOpacity,
      })
    : null;

  const centerStyle = [styles.title, titleContainerStyle, { marginVertical: isTabLayout ? 5 : 0}];
  return (
    <React.Fragment>
      <Animated.View pointerEvents='box-none' style={[StyleSheet.absoluteFill, { zIndex: 0 }, backgroundContainerStyle]}>
        {headerBackground ? headerBackground({ style: backgroundStyle }) : headerTransparent ? null : <HeaderBackground style={backgroundStyle} />}
      </Animated.View>
      <Animated.View pointerEvents='box-none' style={[{ opacity, transform, ...styles.wrapper }]} onLayout={onLayout}>
        <View pointerEvents='none' style={{ height: headerStatusBarHeight }} />
        <View pointerEvents='box-none' style={styles.upper}>
          <Animated.View pointerEvents='box-none' style={[styles.left, { marginStart: insets.left }, leftContainerStyle]}>
            {leftButton}
          </Animated.View>
          <Animated.View pointerEvents='box-none' style={centerStyle}>
            {isTabLayout ? (
              <MapSearch />
            ) : (
              <Row>
                <NavLink to='/Explore'>Explore</NavLink>
                {/* <NavLink to='https://www.climatecentral.org/'>Compare</NavLink> */}
                <NavLink to='https://www.climatecentral.org/'>Learn</NavLink>
                {/* <NavLink to='https://www.climatecentral.org/'>Solutions</NavLink> */}
              </Row>
            )}
          </Animated.View>
          <Animated.View pointerEvents='box-none' style={[styles.right, { marginEnd: insets.right }, rightContainerStyle]}>
            {rightButton}
          </Animated.View>
        </View>
        <View style={styles.lower} bg='primary.100'>
          <Row style={{ width: '100%' }}>
            <ScenariosPopover />
            {/* <Popover
              trigger={triggerProps => {
                return (
                  <Button {...triggerProps} variant='cc-header-tab'>
                    Risks
                  </Button>
                );
              }}
              placement='bottom left'
            >
              <Popover.Content accessibilityLabel='Risks' w='56'>
                <Popover.Body>Risks controls go here</Popover.Body>
                <Popover.Footer justifyContent='flex-end'>
                  <Button.Group space={2} style={{ margin: 0 }}>
                    <Button colorScheme='coolGray' variant='ghost'>
                      Cancel
                    </Button>
                  </Button.Group>
                </Popover.Footer>
              </Popover.Content>
            </Popover> */}
            {!isTabLayout && (
              <Column style={{ flex: 1, alignContent: 'flex-end', alignItems: 'flex-end' }}>
                <MapSearch style={{ marginTop: '8px', marginRight: '12px', position: 'absolute' }} />
              </Column>
            )}
          </Row>
        </View>
        {headerBanners &&
          headerBanners.length > 0 &&
          headerBanners.map((banner, i) => {
            return (
              <View style={styles.banner} key={banner.text + i}>
                <Alert w='100%' status={banner.type || 'info'}>
                  <Column space={2} flexShrink={1} w='100%'>
                    <Row flexShrink={1} space={2} justifyContent='space-between'>
                      <Row space={2} flexShrink={1}>
                        <Alert.Icon mt='1' />
                        <Text fontSize='md' color='coolGray.800'>
                          {banner.text}
                        </Text>
                      </Row>
                      <IconButton
                        onPress={() => {
                          removeBanner(banner.text);
                        }}
                        variant='unstyled'
                        _focus={{
                          borderWidth: 0,
                        }}
                        icon={<CloseIcon size='3' />}
                        _icon={{
                          color: 'coolGray.600',
                        }}
                      />
                    </Row>
                  </Column>
                </Alert>
              </View>
            );
          })}
      </Animated.View>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'column',
    alignContent: 'stretch',
  },
  upper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    position: 'relative',
    zIndex: 10,
  },
  title: {
    flexGrow: 1,
    flexBasis: 'auto',
    minHeight: 50,
  },
  left: {
    flexGrow: 0,
    flexBasis: 'auto',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingHorizontal: 20,
  },
  right: {
    flexGrow: 0,
    flexBasis: 'auto',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingHorizontal: 20,
  },
  lower: {
    flexDirection: 'row',
    alignItems: 'stretch',
    position: 'relative',
    zIndex: 1,
  },
  banner: {
    flexDirection: 'row',
    alignItems: 'stretch',
    position: 'relative',
    zIndex: 1,
  },
  expand: {
    flexGrow: 1,
    flexBasis: 0,
  },
});
