import { Icon, Text, View, Row, Button, Popover, Heading, Link, Column, IconButton } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/native';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import get from 'lodash/get';
import ApiContext from '../ApiContext';
import UiContext from '../UiContext';
import { download, useIsPhoneSized } from '../utils';
import ShareModal from './modals/ShareModal';
import EmbedModal from './modals/EmbedModal';
import { MapSearch } from './MapSearch';
import DownloadTypeModal from './modals/DownloadTypeModal';
import DownloadCustomizeModal from './modals/DownloadCustomizeModal';
import GetInfoModal from './modals/GetInfoModal';


function MapHeader({ mapInstance }) {
  const apiContext = useContext(ApiContext);
  const { addBanner } = useContext(UiContext);
  const route = useRoute();
  const { hideMapInfo, hideMapTitle, minimalHeader } = get(route, 'params', {});
  const isEmbed = route.name === 'embed';
  const mapTitle = get(apiContext, 'mapInfo.data.text.title', null);
  const mainSettings = get(apiContext, 'mapSettings.data.main_settings', []);
  const otherSettings = get(apiContext, 'mapSettings.data.other_settings', []);
  const allSettings = [...mainSettings, ...otherSettings];
  const [showShareModal, setShowShareModal] = useState(false);
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [showDownloadTypeModal, setShowDownloadTypeModal] = useState(false);
  const [showDownloadCustomizeModal, setShowDownloadCustomizeModal] = useState(false);
  const [downloadDimensions, setDownloadDimensions] = useState('1920x1080');
  const [showGetInfoModal, setShowGetInfoModal] = useState(false);
  const [viewportWidth, viewportHeight] = downloadDimensions.split('x');

  const isFullscreen = document.fullscreenElement !== null;
  if (isFullscreen) return null;

  const [shareData, setShareData] = useState({
    title: mapTitle,
    url: window.location.href,
  });

  useEffect(() => {
    setShareData({
      title: mapTitle,
      url: window.location.href,
    });
  }, [window.location.href, mapTitle]);

  const doShare = async () => {
    if (!navigator.canShare || !navigator.canShare(shareData)) {
      setShowShareModal(true);
    } else {
      try {
        await navigator.share({
          url: shareData.url,
          text: shareData.text || 'Climate Central',
          title: shareData.title
        });
      } catch (e) {
        console.warn('Error sharing:', e);
      }
    }
  };

  const screenshotURL = shareData.url.replace(/\/explore(\/maps)?/i, '/screenshot');
  const downloadURL =  `https://images.climatecentral.org/web-image?url=${encodeURIComponent(screenshotURL)}&format=png&saveAs=download.png&waitForNetwork=networkidle0&delay=3000&viewportHeight=${viewportHeight}&viewportWidth=${viewportWidth}`;
  const isPhoneSized = useIsPhoneSized();
  const titleFontSize = isEmbed ? 'lg' : isPhoneSized ? 'xl' : '3xl';
  return (<View style={isEmbed ? minimalHeader ? styles.minimalHeader : styles.embedHeader : isPhoneSized ? styles.phoneHeader : styles.mapHeader}>
    {!minimalHeader && <Text variant='callout' fontSize={titleFontSize}>
      {hideMapTitle ? ' ' : mapTitle}
    </Text>}
    {isEmbed ? <Row style={styles.headerButtons}>
      <Row style={{ flex: 1, alignContent: 'flex-end', alignItems: 'start' }}>
        {!minimalHeader && !hideMapInfo && <Popover trigger={triggerProps => {
          return <Button
            variant='cclink'
            startIcon={<Icon as={MaterialCommunityIcons} name='information-outline' size={18} />}
            style={{ marginTop: 10 }}
            {...triggerProps}
          />;
        }}>
          <Popover.Content
            accessibilityLabel='Map Info'
            backgroundColor='primary.100'
            borderWidth='0'
            borderRadius='0'
            style={{
              boxShadow: 'inset 0px 5px 5px rgba(3, 40, 73, 0.11), 10px 10px 20px rgba(3, 40, 73, 0.1)',
            }}>
            <Popover.Arrow borderColor='primary.100' bg='primary.100' />
            <Popover.Body bg='primary.100'>
              <Heading variant='label' size='xs' color='primary.700' mt={4} flexDirection='row' justifyContent='space-between'>
                {mapTitle}
              </Heading>
              {allSettings.map((setting, i) => {
                const valueText = setting.choices.find(choice => choice.value === setting.current_value).text;
                return <Row key={setting.label} style={{ borderBottomWidth: 1, borderBottomColor: 'primary.700', marginBottom: 10, paddingBottom: 10 }} justifyContent='space-between'>
                  <Text variant='body-bold' color='primary.900'>{setting.label}</Text>
                  <Text variant='body' color='primary.900' textAlign='right'>{valueText}</Text>
                </Row>;
              })}
              <Text color='primary.900'><Link variant='alt' target='_blank' href={shareData.url}>Open full map in a new tab</Link></Text>
            </Popover.Body>
          </Popover.Content>
        </Popover>}
        {minimalHeader && <Column>
          <IconButton onPress={() => { mapInstance.setZoom(mapInstance.getZoom() + 1) }} variant='googlemaps-small' icon={<Icon as={MaterialCommunityIcons} name='plus' size='xs' />} style={{ marginTop: 5, marginRight: 10, marginBottom: 10 }} />
          <IconButton onPress={() => { mapInstance.setZoom(mapInstance.getZoom() - 1) }} variant='googlemaps-small' icon={<Icon as={MaterialCommunityIcons} name='minus' size='sm' />} style={{ marginRight: 10, marginBottom: 0 }} />
        </Column>}
        <MapSearch style={{ marginTop: '5px', marginRight: '5px', position: 'relative' }} />
      </Row>
    </Row> : <Row style={styles.headerButtons}>
      <Button
        variant='cclink'
        startIcon={<Icon as={MaterialCommunityIcons} name='share' size={18} />}
        style={styles.button}
        onPress={()=>doShare()}
      >
        Share
      </Button>
      <Button
        variant='cclink'
        startIcon={<Icon as={MaterialCommunityIcons} name='download' size={18} />}
        style={styles.button}
        onPress={() => setShowDownloadTypeModal(true)}
      >
        Download
      </Button>
      {!isPhoneSized && <Button
        variant='cclink'
        startIcon={<Icon as={MaterialCommunityIcons} name='code-tags' size={18} />}
        style={styles.button}
        onPress={() => setShowEmbedModal(true)}
      >
        Embed
      </Button>}
    </Row>}
    <ShareModal show={showShareModal} toggle={() => setShowShareModal(!showShareModal)} data={shareData} />
    <EmbedModal show={showEmbedModal} toggle={() => setShowEmbedModal(!showEmbedModal)} data={shareData} />
    <DownloadTypeModal
      show={showDownloadTypeModal}
      toggle={() => setShowDownloadTypeModal(!showDownloadTypeModal)}
      data={shareData}
      next={() => {
        setShowDownloadTypeModal(false);
        setShowDownloadCustomizeModal(true);
      }}
      back={() => {
        setShowDownloadTypeModal(false);
        setShowDownloadCustomizeModal(true);
      }}
    />
    <DownloadCustomizeModal
      show={showDownloadCustomizeModal}
      toggle={() => setShowDownloadCustomizeModal(!showDownloadCustomizeModal)}
      data={shareData}
      downloadDimensions={downloadDimensions}
      setDownloadDimensions={setDownloadDimensions}
      next={() => {
        setShowDownloadCustomizeModal(false);
        setShowGetInfoModal(true);
      }}
      back={() => {
        setShowDownloadTypeModal(true);
        setShowDownloadCustomizeModal(false);
      }}
    />
    <GetInfoModal
      show={showGetInfoModal}
      toggle={() => setShowGetInfoModal(!showGetInfoModal)}
      download={()=>download(downloadURL, `${mapTitle}_${viewportWidth}x${viewportHeight}.png`)}
      back={() => {
        setShowGetInfoModal(false);
        setShowDownloadCustomizeModal(true);
      }}
    />
  </View>);
}

export default MapHeader;

const styles = StyleSheet.create({
  mapHeader: {
    backgroundImage: 'linear-gradient(180deg, rgba(3, 40, 73, 0.8) 76%, rgba(3, 40, 73, 0) 100%)',
    paddingTop: 60,
    paddingBottom: 60,
    paddingLeft: 60,
    paddingRight: 60,
    marginBottom: -40,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  phoneHeader: {
    backgroundImage: 'linear-gradient(180deg, rgba(3, 40, 73, 0.8) 76%, rgba(3, 40, 73, 0) 100%)',
    paddingTop: 60,
    paddingBottom: 40,
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: -40,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  embedHeader: {
    backgroundImage: 'linear-gradient(180deg, rgba(3, 40, 73, 0.8) 76%, rgba(3, 40, 73, 0) 100%)',
    paddingTop: 30,
    paddingBottom: 40,
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: -40,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  minimalHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  headerButtons: {
    justifyContent: 'flex-end',
    right: '10px',
    top: '10px',
    position: 'absolute',
    pointerEvents: 'auto'
  }
});
