import React, { useContext, useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { StyleSheet, View } from 'react-native';
import { Spinner } from 'native-base';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Wrapper } from '@googlemaps/react-wrapper';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import LayerControl from './LayerControl';
import BasemapControl from './BasemapControl';
import MapHeader from './MapHeader';
import Legend from './Legend';
import Attribution from './Attribution'
import OverlayLegend from './OverlayLegend';
import { baseMaps, overlays } from '../fixtures/mapStyles';
import UiContext from '../UiContext';
import ApiContext from '../ApiContext';
import config from '../app.config';
import { getLeveeOpacity, getFeatureStyle, getBoundsRestriction, queryClient } from '../utils';
const { defaultLocation, MAX_ZOOM, MIN_ZOOM } = config.expo.extra;
const AppConfig = config.app;

class MapDOM extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.map = null;
    this.idleListener = null;
    this.state = { isRemounted: false };
    this.activeOverlays = [];
  }
  getMapBounds = (map) => {
    const { setBbox } = this.props;
    if (!map) return;
    const bounds = map.getBounds();
    if (!bounds) return;
    const bbox = [
      bounds.getNorthEast().lng(),
      bounds.getNorthEast().lat(),
      bounds.getSouthWest().lng(),
      bounds.getSouthWest().lat(),
    ];
    setBbox(bbox);
  }
  handleMapIdle = () => {
    const { explore, setExplore, setBbox } = this.props;
    // Resize the title to match the map width
    this.mapTitle.style.width = this.ref.current.offsetWidth + 'px';
    const hasSetLatLong = explore.region.latitude && explore.region.longitude;
    const newRegion = {
      latitude: this.map.getCenter().lat(),
      longitude: this.map.getCenter().lng(),
      zoom: this.map.getZoom(),
    };
    const newRegionIsDefault = newRegion.latitude === defaultLocation.latitude && newRegion.longitude === defaultLocation.longitude;
    if (!hasSetLatLong && newRegionIsDefault) {
      delete newRegion.latitude;
      delete newRegion.longitude;
    }
    // IMPORTANT: Notify parent windows of iframe embeds when map location changes.
    // Used by En-ROADS map integration; DO NOT CHANGE without consulting Climate Interactive!
    if (Object.keys(newRegion).every(key => newRegion[key] !== undefined)) {
      window.parent.postMessage({ longitude: newRegion.longitude, latitude: newRegion.latitude, zoom: this.map.getZoom() }, '*');
    }
    setExplore({ ...explore, region: newRegion });
  };

  // IMPORTANT: Handle changes to settings passed down from parent windows of iframe embeds.
  // Used by En-ROADS map integration; DO NOT CHANGE without consulting Climate Interactive!
  handleMessage = (event) => {
    const { data } = event;
    const { explore, setExplore } = this.props;
    const validSettings = pickBy(data, (value, key) => {
      return AppConfig.mapSettings.includes(key);
    });
    if (Object.keys(validSettings).length > 0) {
      setExplore({ ...explore, mapSettings: { ...explore.mapSettings, ...validSettings } });
    }
  }
  componentDidMount() {
    if (!this.props) return;
    // NOTE: We may want to be more selective about which of this logic is skipped in future tests
    if (Wrapper.name === 'MockWrapper') return; // Skip if this is a test\
    this.setState({ isRemounted: true });
    const { explore, setExplore, route } = this.props;
    const { latitude, longitude, zoom } = explore.region;
    const basemap = get(explore, 'basemap');
    const isScreenshot = get(route, 'name') === 'screenshot';
    const isEmbed = get(route, 'name') === 'embed';
    const minimalHeader = get(route, 'params.minimalHeader');
    if (isScreenshot) {
      const styleEl = document.createElement('style');
      (document.head || document.getElementsByTagName('head')[0]).appendChild(styleEl);
      styleEl.appendChild(document.createTextNode(`
        a[href^="http://maps.google.com/maps"]{display:none !important}
        a[href^="https://maps.google.com/maps"]{display:none !important}
        .gmnoprint a, .gmnoprint span, .gm-style-cc { display:none; }
        .gmnoprint div { background:none !important; }
      `));
    }
    if (isEmbed) {
      const styleEl = document.createElement('style');
      (document.head || document.getElementsByTagName('head')[0]).appendChild(styleEl);
      styleEl.appendChild(document.createTextNode(`
        .gm-style-cc:nth-child(4) {
          display: none !important;
        }
        a[title="Report errors in the road map or imagery to Google"] {
          display: none !important;
        }
      `));
    }
    // Is this.map already a Google Maps instance?
    const isGoogleMapsInstance = this.map && this.map instanceof google.maps.Map;
    if (!isGoogleMapsInstance) {
      this.map = new window.google.maps.Map(this.ref.current, {
        mapId: '4da92bc37166a2f1', // This gets the map style from the Google Maps Platform
        center: { lat: latitude || defaultLocation.latitude, lng: longitude || defaultLocation.longitude },
        zoom,
        streetViewControl: false,
        mapTypeControl: false,
        mapTypeId: basemap,
        disableDefaultUI: isScreenshot,
        keyboardShortcuts: !isEmbed,
        scaleControl: true,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        zoomControl: !minimalHeader && !isScreenshot,
      });
    }
    this.setMapType = (mapTypeId) => {
      const newType = mapTypeId === 'satellite' ? google.maps.MapTypeId.SATELLITE : google.maps.MapTypeId.DEFAULT;
      this.map.setMapTypeId(newType);
    };
    baseMaps.forEach((basemap) => {
      this.map.mapTypes.set(basemap.slug, new google.maps.StyledMapType(basemap.style || basemap.slug, { name: basemap.name }));
    });
    this.map.overlayMapTypes.push(null); // Placeholder for map overlays
    this.idleListener = this.map.addListener('idle', this.handleMapIdle);
    this.boundsListener = this.map.addListener('bounds_changed', ()=>this.getMapBounds(this.map));
    this.mapTypeListener = this.map.addListener('maptypeid_changed', () => {
      const mapTypeId = this.map.getMapTypeId();
      setExplore({ ...explore, basemap: mapTypeId, layers: mapTypeId === 'satellite' ? ['cities', 'levees'] : ['roads', 'cities', 'levees', 'labels', 'icons'] });
    });
    this.dragListener = this.map.addListener('dragstart', () => {
      queryClient.cancelQueries();
    });

    if (isEmbed) {
      this.windowMessageListener = window.addEventListener('message', this.handleMessage);
    }

    if (!isScreenshot && !isEmbed) {
      this.basemapControlDiv = document.createElement('div');
      this.basemapControlDiv.index = 3;
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.basemapControlDiv);

      this.layerControlDiv = document.createElement('div');
      this.layerControlDiv.index = 3;
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.layerControlDiv);
    }

    this.overlayLegendDiv = document.createElement('div');
    this.overlayLegendDiv.index = 1;
    // this.overlayLegendDiv.style.height = '100px';
    this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(this.overlayLegendDiv);

    this.legendDiv = document.createElement('div');
    this.legendDiv.index = 2;
    this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(this.legendDiv);


    if (!isScreenshot) {
      this.mapTitle = document.createElement('div');
      this.mapTitle.index = 0;
      this.mapTitle.style.position = 'relative';
      this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.mapTitle);

      this.creditsDiv = document.createElement('div');
      this.creditsDiv.index = 5;
      this.map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].pop();
      this.map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(this.creditsDiv);
    }

    navigator.permissions.query({ name: 'geolocation' }).then(result => {
      result.addEventListener('change', () => {
        if (result.state === 'granted') {
          navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            if (latitude && longitude) this.map.setCenter({ lat: latitude, lng: longitude });
          });
        }
      });
    });
  }
  componentWillUnmount() {
    if (!this.map) return;
    this.idleListener.remove();
    this.boundsListener.remove();
    this.mapTypeListener.remove();
    this.windowMessageListener?.remove();
    this.tileLoadedListener?.remove ? this.tileLoadedListener.remove() : null;
    this.waterbodiesLoadedListener?.remove ? this.waterbodiesLoadedListener.remove() : null;
    this.leveesLoadedListener?.remove ? this.leveesLoadedListener.remove() : null;
    this.setState({ isRemounted: false });
  }
  componentDidUpdate(prevProps, prevState) {
    if (!this.map) return;
    const isRemounted = prevState.isRemounted !== this.state.isRemounted && this.state.isRemounted;
    const { explore, setExplore, setIsDataLayerLoading, setIsWaterbodiesLoading, setIsLeveesLoading, apiContext } = this.props;
    const mapData = get(apiContext, 'mapInfo.data', {});
    const { apiContext: prevApiContext, explore: prevExplore } = prevProps;
    const prevMapData = get(prevApiContext, 'mapInfo.data', {});
    const urlTemplate = get(mapData, 'tile_url.dynamic', '');
    const prevUrlTemplate = get(prevMapData, 'tile_url.dynamic', '');
    const satelliteUrlTemplate = get(mapData, 'tile_url.satellite_style', '');
    const prevSatelliteUrlTemplate = get(prevMapData, 'tile_url.satellite_style', '');
    const waterbodiesTemplate = get(apiContext, 'waterbodies.data.tileUrl', '');
    const prevWaterbodiesTemplate = get(prevApiContext, 'waterbodies.data.tileUrl', '');
    const leveeTemplate = get(apiContext, 'levees.data.tileUrl', '');
    const prevLeveeTemplate = get(prevApiContext, 'levees.data.tileUrl', '');
    const { mapType, region, basemap } = explore;
    const { mapType: prevMapType, region: prevRegion, basemap: prevBasemap } = prevExplore;
    const { latitude, longitude, zoom } = region;

    const template = basemap === 'satellite' ? satelliteUrlTemplate : urlTemplate;
    const prevTemplate = prevBasemap === 'satellite' ? prevSatelliteUrlTemplate : prevUrlTemplate;
    if (this.props.mapWidth > 0 && this.props.mapWidth !== prevProps.mapWidth) {
      // Hide scale control is map width is less than 600px
      this.map.setOptions({ scaleControl: this.props.mapWidth > 600 });
    }

    // If center has changed, update the map center and the URL
    if (latitude !== prevRegion.latitude || longitude !== prevRegion.longitude) {
      this.map.setCenter({ lat: latitude, lng: longitude });
    }

    // If zoom has changed, update the map zoom and the URL
    if (zoom !== prevRegion.zoom) {
      this.map.setZoom(zoom);
    }

    // If URL template has changed, update the SLR/Flood overlay
    if (template && (isRemounted || template !== prevTemplate)) {
      setIsDataLayerLoading(true);
      const layer = new google.maps.ImageMapType({
        name: `cc_layer_${mapType}`,
        getTileUrl: function (coord, zoom) {
          const url = template.replace('{x}', coord.x).replace('{y}', coord.y).replace('{z}', zoom);
          return url;
        },
        tileSize: new google.maps.Size(256, 256),
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM,
      });
      // replace the existing layer with the new one
      this.map.overlayMapTypes.setAt(2, null);
      this.map.overlayMapTypes.setAt(2, layer);

      this.tileLoadedListener = layer.addListener('tilesloaded', () => {
        setIsDataLayerLoading(false);
        this.getMapBounds(this.map);
      });
    }

    // Render waterbodies layer
    if (waterbodiesTemplate && basemap === 'default') {
      const waterbodiesChanged = isRemounted || waterbodiesTemplate !== prevWaterbodiesTemplate;
      const mapTypeChanged = mapType !== prevMapType;
      const basemapChanged = basemap !== prevBasemap;
      if (waterbodiesChanged || mapTypeChanged || basemapChanged) {
        setIsWaterbodiesLoading(true);
        const waterbodiesLayer = new google.maps.ImageMapType({
          name: `cc_waterbodies_${mapType}`,
          getTileUrl: function (coord, zoom) {
            const url = waterbodiesTemplate.replace('{x}', coord.x).replace('{y}', coord.y).replace('{z}', zoom);
            return url;
          },
          tileSize: new google.maps.Size(256, 256),
          minZoom: MIN_ZOOM,
          maxZoom: MAX_ZOOM,
        });
        // replace the existing layer with the new one
        this.map.overlayMapTypes.setAt(0, null);
        this.map.overlayMapTypes.setAt(0, waterbodiesLayer);

        this.waterbodiesLoadedListener = waterbodiesLayer.addListener('tilesloaded', () => {
          setIsWaterbodiesLoading(false);
        });
      }
    } else {
      this.map.overlayMapTypes.setAt(0, null);
    }

    // Set the basemap to match the value returned by the mapData API endpoint
    const mapDataHasBasemap = !!mapData.basemap;
    const basemapShouldUpdate = mapDataHasBasemap && mapData.basemap !== basemap;
    if (basemapShouldUpdate) {
      setExplore({ ...explore, basemap: mapData.basemap });
    }

    // Set restrictions on the map if there is a scope returned by the mapInfo API endpoint
    const mapDataHasScope = !!mapData.scope;
    const scopeShouldUpdate = mapDataHasScope && mapData.scope !== prevMapData.scope;
    if (mapDataHasScope && scopeShouldUpdate) {
      const restriction = getBoundsRestriction(mapData.scope);
      this.map.setOptions({ restriction });
    } else if (!mapDataHasScope) {
      this.map.setOptions({ restriction: null });
    }

    // Handle overlay layers
    [...overlays].reverse().forEach((overlay, i) => {
      const overlaysChanged = explore.layers.includes(overlay.slug) && !prevExplore.layers.includes(overlay.slug);
      const roadsChanged = explore.layers.includes('roads') !== prevExplore.layers.includes('roads')
      const basemapChanged = basemap !== prevBasemap;
      const roadsOrBasemapChanged = roadsChanged || basemapChanged;
      const isLayerOn = explore.layers.includes(overlay.slug);
      const isLayerActive = this.activeOverlays[overlay.slug];
      // Render layer if it's turned on, or if roads changed and it's an icon or label layer
      if (isLayerOn && (!isLayerActive || (roadsOrBasemapChanged && ['labels', 'icons', 'cities'].includes(overlay.slug)))) {
        if (overlay.style) {
          // If roads layer is active when icons/labels are turned on, add roads to the labels or icons layer
          let overlayStyle = [...overlay.style, ...(explore.layers.includes('roads') && ['labels', 'icons'].includes(overlay.slug) ? [{
            "featureType": "road",
            "elementType": overlay.slug === 'labels' ? "labels.text" : "labels.icon",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          }] : [])]

          // Set correct label color for mapType
          if (['labels', 'cities'].includes(overlay.slug)) {
            const labelColor = basemap === 'satellite' ? '#ffffff' : '#000000';
            const outlineColor = basemap === 'satellite' ? '#000000' : '#ffffff';
            overlayStyle = [...overlayStyle, ...[{
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": labelColor
                },
                {
                  "visibility": "on"
                }
              ]
            },
            {
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": outlineColor
                },
                {
                  "visibility": "on"
                },
                {
                  "weight": 2
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "labels.text",
              "stylers": [
                {
                  "color": "#ffffff"
                }
              ]
            }]];
          }

          this.activeOverlays[overlay.slug] = new google.maps.StyledMapType(overlayStyle, { name: overlay.name });
        } else if (overlay.isCC) {
          // TODO: Handle layers other than levees
          const templateChanged = leveeTemplate !== prevLeveeTemplate;
          const leveesChanged = templateChanged || overlaysChanged;
          if (leveeTemplate && (isRemounted || leveesChanged)) {
            setIsLeveesLoading(true);
            const leveeLayer = this.activeOverlays[overlay.slug] = new google.maps.ImageMapType({
              name: `cc_layer_levee_${mapType}`,
              getTileUrl: function (coord, zoom) {
                const url = leveeTemplate.replace('{x}', coord.x).replace('{y}', coord.y).replace('{z}', zoom);
                return url;
              },
              tileSize: new google.maps.Size(256, 256),
              opacity: getLeveeOpacity(zoom) || 0.5,
              minZoom: MIN_ZOOM,
              maxZoom: MAX_ZOOM,
            });

            this.leveesLoadedListener = leveeLayer.addListener('tilesloaded', () => {
              setIsLeveesLoading(false);
            });
          }
        }

        const position = overlay.slug === 'roads' ? 1 : i + 3;
        this.map.overlayMapTypes.setAt(position, null); // Reset overlay first
        this.map.overlayMapTypes.setAt(position, this.activeOverlays[overlay.slug]);
      }

      // Remove inactive overlays
      if (!explore.layers.includes(overlay.slug) && this.activeOverlays[overlay.slug]) {
        const overlayIndex = this.map.overlayMapTypes.getArray().indexOf(this.activeOverlays[overlay.slug]);
        if (overlayIndex !== -1) {
          this.map.overlayMapTypes.setAt(overlayIndex, null);
          this.activeOverlays[overlay.slug] = null;
        }
      }
    });

    // Remove old geoJson features
    if (this.geoJson && this.geoJson.forEach) {
      this.geoJson.forEach((feature) => {
        this.geoJson.remove(feature);
      });
    }

    // Add feature overlay for data in mapData.features
    const featureCollection = mapData && mapData.features;
    const hasFeatureCollection = featureCollection && featureCollection.type === 'FeatureCollection';
    if (hasFeatureCollection) {
      this.geoJson = new google.maps.Data({
        map: this.map,
        style: feature => getFeatureStyle(feature),
      });
      this.geoJson.addGeoJson(featureCollection);
    }

  }
  render() {
    // Need to pull off unsupported props so they don't get passed to the DOM element
    const { explore, setExplore, isLoadingMapTiles, setIsDataLayerLoading, setIsLeveesLoading, setIsWaterbodiesLoading, apiContext, route, isLayersPopoverOpen, openLayersPopover, closeLayersPopover, isBasemapPopoverOpen, openBasemapPopover, closeBasemapPopover, mapWidth, setBbox, ...rest } = this.props;
    const basemap = explore.basemap;
    const legend = get(apiContext, 'mapInfo.data.legend', {});
    const legendData = legend[basemap === 'satellite' ? 'satellite' : 'roadmap'];
    const overlayLegendData = get(apiContext, 'mapInfo.data.overlay_legend', {});
    const isScreenshot = get(route, 'name') === 'screenshot';
    return <div ref={this.ref} id='map-dom' {...rest}>
      {this.mapTitle && 
        createPortal(<MapHeader mapInstance={this.map} />, this.mapTitle)
      }
      {this.creditsDiv &&
        createPortal(<Attribution />, this.creditsDiv)
      }
      {
        this.map && this.layerControlDiv &&
        createPortal(<LayerControl isOpen={isLayersPopoverOpen} onOpen={openLayersPopover} onClose={closeLayersPopover} explore={explore} setExplore={setExplore} legend={legendData} setMapType={this.setMapType} />, this.layerControlDiv)
      }
      {
        this.map && this.basemapControlDiv &&
        createPortal(<BasemapControl isOpen={isBasemapPopoverOpen} onOpen={openBasemapPopover} onClose={closeBasemapPopover}  explore={explore} setExplore={setExplore} legend={legendData} setMapType={this.setMapType} />, this.basemapControlDiv)
      }
      {
        this.map && this.legendDiv && legendData &&
        createPortal(<Legend data={legendData} title={legend.title} isScreenshot={isScreenshot} hasOverlayLegend={!!overlayLegendData} />, this.legendDiv)
      }
      {
        this.map && this.overlayLegendDiv && overlayLegendData &&
        createPortal(<OverlayLegend data={overlayLegendData} isScreenshot={isScreenshot} />, this.overlayLegendDiv)
      }
    </div>;
  }
}

export const Map = () => {
  const mapKey = process.env.GOOGLE_MAPS_API_KEY_WEB;
  const navigation = useNavigation();
  const route = useRoute();
  const uiContext = useContext(UiContext);
  const apiContext = useContext(ApiContext);
  const { explore, setExplore, setBbox } = uiContext;
  const { layers, basemap } = explore;
  const { data: mapInfoData, isLoading, isSuccess } = apiContext.mapInfo;
  const { data: waterbodyData, isSuccess: waterbodySuccess } = apiContext.waterbodies;
  const { data: leveeData, isSuccess: leveeSuccess } = apiContext.levees;
  const dataLayerLoadingStatus = isSuccess && !!mapInfoData.tile_url;
  const leveesLoadingStatus = layers.includes('levees') && leveeSuccess && !!leveeData.tileUrl;
  const waterbodiesLoadingStatus = basemap === 'default' && waterbodySuccess && !!waterbodyData.tileUrl;
  const [isDataLayerLoading, setIsDataLayerLoading] = useState(dataLayerLoadingStatus);
  const [isLeveesLoading, setIsLeveesLoading] = useState(leveesLoadingStatus);
  const [isWaterbodiesLoading, setIsWaterbodiesLoading] = useState(waterbodiesLoadingStatus);
  const isLoadingMapTiles = isDataLayerLoading || isLeveesLoading || isWaterbodiesLoading;
  const [isLayersPopoverOpen, setLayersPopoverOpen] = useState(false);
  const [isBasemapPopoverOpen, setBasemapPopoverOpen] = useState(false);

  const [mapWidth, setMapWidth] = useState(0);
  const mapViewRef = useRef(null);
  useEffect(() => {
    const updateWidth = () => {
      setMapWidth(mapViewRef.current.offsetWidth);
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const openLayersPopover = () => {
    setBasemapPopoverOpen(false);
    setLayersPopoverOpen(true);
  };
  const closeLayersPopover = ()=>setLayersPopoverOpen(false);
  const openBasemapPopover = () => {
    setLayersPopoverOpen(false);
    setBasemapPopoverOpen(true);
  };
  const closeBasemapPopover = ()=>setBasemapPopoverOpen(false);
  const apiErrors = Object.entries(apiContext).reduce((acc, [key, context]) => {
    if (context.status === 'error') {
      acc[key] = context.error;
    }
    return acc;
  }, {});
  const showLoader = isLoading || isLoadingMapTiles;
  const isMapRoute = ['Maps', 'Explore', 'embed', 'screenshot'].includes(route.name);
  return (
    <View style={styles.mapView} ref={mapViewRef} testID="map-wrapper">
      {isMapRoute && <Wrapper apiKey={mapKey}>
        <MapDOM
          style={styles.map}
          navigation={navigation}
          route={route}
          isLoadingMapTiles={isLoadingMapTiles}
          setIsDataLayerLoading={setIsDataLayerLoading}
          setIsLeveesLoading={setIsLeveesLoading}
          setIsWaterbodiesLoading={setIsWaterbodiesLoading}
          explore={explore}
          setExplore={setExplore}
          apiContext={apiContext}
          isLayersPopoverOpen={isLayersPopoverOpen}
          openLayersPopover={openLayersPopover}
          closeLayersPopover={closeLayersPopover}
          isBasemapPopoverOpen={isBasemapPopoverOpen}
          openBasemapPopover={openBasemapPopover}
          closeBasemapPopover={closeBasemapPopover}
          mapWidth={mapWidth}
          setBbox={setBbox}
        />
      </Wrapper>}
      {isMapRoute && showLoader && (
        <View testID="map-spinner" style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
          <Spinner style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'white', opacity: 0.3 }} size='lg' />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mapView: {
    flex: 1,
  },
  map: { top: 0, left: 0, right: 0, bottom: 0, position: 'absolute' },
  status: {
    flex: 1,
    position: 'absolute',
    left: 10,
    top: 10,
    right: 10,
  },
});
