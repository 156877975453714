import { Row, Link } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import HorizontalLogo from './HorizontalLogo';

function Attribution() {
  return (
    <Row style={{...styles.container}}>
      <Link href='https://climatecentral.org' title='Climate Central' target="_blank" rel="noopener noreferrer">
        <HorizontalLogo />
      </Link>
    </Row>
  );
}

export default Attribution;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(245, 245, 245, 0.7)',
    color: 'rgb(0, 0, 0)',
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 2,
    borderWidth: 0,
  },
});
