import { Modal, Button, Icon, TextArea, Slider, Heading, Text, View, Row, Switch } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import ReactGA from 'react-ga4';

function EmbedModal({ show, toggle, data }) {
  const [embedWidth, setEmbedWidth] = useState(680);
  const [hideMapInfo, setHideMapInfo] = useState(false);
  const [hideMapTitle, setHideMapTitle] = useState(false);
  const [copyLabel, setCopyLabel] = useState('Copy Embed Code');
  const embedHeight = embedWidth * 0.5625;
  const hideParams = `${hideMapInfo ? '&hideMapInfo=true' : ''}${hideMapTitle ? '&hideMapTitle=true' : ''}`;
  const embedUrl = data.url.toLowerCase().replace('explore/maps', 'explore').replace('explore', 'embed') + hideParams;
  const embedCode = `<iframe src="${embedUrl}${hideParams}" width="${embedWidth}" height="${embedHeight}" frameBorder="0"></iframe>`;
  return (
    <Modal isOpen={show} onClose={toggle} borderRadius={0} size={'vlg'}>
      <Modal.Content bg='primary.100' borderRadius={0}>
        <Modal.CloseButton />
        <Modal.Header bg='primary.300' color='primary.900' _text={{
          color: 'primary.900',
        }}>Embed this Map</Modal.Header>
        <Modal.Body style={styles.modalBody}>
          <View style={{position: 'relative', width: '100%', height: embedHeight}}>
            <iframe
              style={styles.embedFrame}
              src={show?embedUrl:''}
              height={embedHeight}
              width={embedWidth}
              frameBorder={0}
            />
            <View
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'transparent',
                cursor: 'not-allowed'
              }} 
              onClick={e => e.preventDefault()}></View>
          </View>
          <Text color={'primary.700'} textAlign={'center'}>Note: to adjust the map settings and location, set them on the main map before opening this embed modal.</Text>
          <Heading variant='label' size='xs' color='primary.700' mt={4} flexDirection='row' justifyContent='space-between'>
            Map Width
          </Heading>
          <Heading variant='h3' size='md' color='primary.700' mt={4}>
            {embedWidth}px
          </Heading>
          <Slider
            onChangeEnd={v => setEmbedWidth(v)}
            _selectedValue={embedWidth}
            defaultValue={embedWidth}
            minValue={500}
            maxValue={1080}
            step={10}
          >
            <Slider.Track>
              <Slider.FilledTrack />
            </Slider.Track>
            <Slider.Thumb />
          </Slider>
          <View style={{height: 20}} />
          <TextArea color="black" h={20} value={embedCode} style={styles.code} />
        </Modal.Body>
        <Modal.Footer bg='primary.100'>
          <Button.Group space={2}>
            <Button
              variant='cclink-light'
              startIcon={<Icon as={MaterialCommunityIcons} name={copyLabel === 'Copied!'?'check-circle-outline':'content-copy'} size={18} />}
              style={styles.button}
              onPress={()=>{
                navigator.clipboard.writeText(embedCode);
                ReactGA.event({
                  category: "sharing",
                  action: "embed code copied",
                });
                setCopyLabel('Copied!');
                setTimeout(() => setCopyLabel('Copy Embed Code'), 2000);
              }}
            >
              {copyLabel}
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

export default EmbedModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(0, 0, 0)',
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    borderRadius: 2,
    borderWidth: 0,
    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    left: 10,
  },
  modalBody: {
    paddingTop: 20,
    paddingRight: 40,
    paddingBottom: 20,
    paddingLeft: 40,
  },
  shareButtons: {
    marginBottom: 20
  },
  embedFrame: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
    marginTop: 0,
  },
  code: {
    fontFamily: 'monospace',
    color: 'rgba(0, 0, 0, 0.8)',
  }
});
