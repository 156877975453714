import { NativeBaseProvider, Text, View } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import theme from '../nativebase/theme';

function Legend({ data, title, isScreenshot, hasOverlayLegend }) {
  if (!data) return null;
  const bottom = hasOverlayLegend ? 106 : 10;
  return (
    <NativeBaseProvider theme={theme}>
      <View style={{...styles.container, bottom}}>
        {title && <Text style={styles.title} fontSize="sm" mb={2} color='primary.700'>{title}</Text>}
        {data.map((item, i) => {
          return (<View key={item.label} style={styles.legendItem} role="listitem">
            <View style={{ ...styles.symbol, ...styles[item.symbol || 'square'] }} backgroundColor={item.color} opacity={item.opacity} />
            <Text style={styles.label} color='primary.500' mx={2}>{item.label}</Text>
          </View>)
        })}
      </View>
    </NativeBaseProvider>
  );
}

export default Legend;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(0, 0, 0)',
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    borderRadius: 2,
    borderWidth: 0,
    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    left: 10,
  },
  legendItem: {
    height: '1.75em',
    display: 'block',
  },
  title: {
    fontWeight: 'bold',
  },
  label: {
    display: 'inline-block',
    marginLeft: '0.6em',
  },
  symbol: {
    position: 'relative',
    display: 'inline-block',
    width: '1.2em',
  },
  square: {
    top: '0.25em',
    height: '1.2em',
    border: '1px solid #333333',
  },
  line: {
    top: '-3px',
    height: '2px'
  }
});
