import React, { Fragment } from 'react';
import { StyleSheet } from 'react-native';
import { Square, useTheme, useContrastText, Column, Row, Box, Heading, Text, Divider } from 'native-base';
import { LinearGradient } from 'expo-linear-gradient';

import ScreenWrapper from '../ScreenWrapper';

const ColorSwatch = ({ colorName, shade, colorValue }) => {
  const boxColor = `${colorName}.${shade}`;
  const contrastColor = useContrastText(boxColor);
  return (
    <Square
      size={100}
      key={boxColor}
      p='3'
      bg={boxColor}
      style={styles.box}
      onClick={() => {
        navigator.clipboard.writeText(colorValue);
      }}
    >
      <Text bold color={contrastColor}>{shade}</Text>
      <Text bold color={contrastColor}>{colorValue}</Text>
    </Square>
  );
};

const ColorSeries = ({ colors, name }) => {
  if (typeof colors === 'string') {
    const contrastColor = useContrastText(colors);
    return (
      <Fragment>
        <Heading size='sm' color='darkText'>
          {name}
        </Heading>
        <Row>
          <Square
            size={100}
            key={colors + name}
            p='3'
            bg={colors}
            style={styles.box}
            _text={{ color: useContrastText(colors) }}
            onClick={() => {
              navigator.clipboard.writeText(colorValue);
            }}
          >
            <Text bold color={contrastColor}>{name}</Text>
            <Text bold color={contrastColor}>{colors}</Text>
          </Square>
        </Row>
      </Fragment>
    );
  }
  if (typeof colors === 'object') {
    return (
      <Fragment>
        <Heading size='sm' color='darkText'>
          {name}
        </Heading>
        <Row flexWrap='wrap'>
          {Object.entries(colors).map(([shade, colorValue]) => {
            return <ColorSwatch key={shade} colorName={name} shade={shade} colorValue={colorValue} />;
          })}
        </Row>
      </Fragment>
    );
  }
  return;
};

export const Colors = () => {
  const { colors } = useTheme();
  const themeColors = ['primary', 'warm', 'secondary', 'tertiary', 'transparent'];
  const neutralColors = ['black', 'white', 'darkText', 'lightText'];
  const otherColors = Object.keys(colors).filter(color => ![...themeColors, ...neutralColors].includes(color));
  const getColorValueArray = colors => {
    return Object.keys(colors).map(colorKey => {
      return colors[colorKey];
    });
  };
  const warmColors = getColorValueArray(colors.warm);
  return (
    <ScreenWrapper>
      <Column space={1} bg={'white'} style={styles.column}>
        <Heading size='md' color='darkText'>
          Theme Colors
        </Heading>
        {themeColors.map(colorKey => {
          if (!colorKey) return;
          return <ColorSeries name={colorKey} colors={colors[colorKey]} key={colorKey} />;
        })}
        <Divider />
        <Heading size='md' color='darkText'>
          Gradients
        </Heading>
        <Heading size='sm' color='darkText'>
          warm
        </Heading>
        <LinearGradient style={styles.box} colors={warmColors} start={[0, 0]} end={[1, 0]}>
          <Box p='8'>
            <Text bold color={'darkText'}>{warmColors.join(', ')}</Text>
          </Box>
        </LinearGradient>
        <Divider />
        <Heading size='md' color='darkText'>
          Neutral Colors
        </Heading>
        {neutralColors.map(colorKey => {
          if (!colorKey) return;
          return <ColorSeries name={colorKey} colors={colors[colorKey]} key={colorKey} />;
        })}
        <Divider />
        <Heading size='md' color='darkText'>
          Other Colors
        </Heading>
        {otherColors.map(colorKey => {
          if (!colorKey) return;
          return <ColorSeries name={colorKey} colors={colors[colorKey]} key={colorKey} />;
        })}
      </Column>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  column: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  button: {
    margin: 4,
  },
  box: {
    alignItems: 'center',
    borderRadius: 10,
    margin: 4,
  },
});
