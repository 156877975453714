import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { Row } from 'native-base';
import UiContext from '../UiContext';
import { Map } from './Map';
import URLUpdater from './URLUpdater';
import { getURLParamsFromNestedSettings } from '../utils';

export const MapOnlyScreen = () => {
  const uiContext = useContext(UiContext);
  const params = getURLParamsFromNestedSettings(uiContext.explore);
  return (
    <URLUpdater params={params}>
      <Row style={styles.main}>
         <Map />
      </Row>
    </URLUpdater>
  );
};

const styles = StyleSheet.create({
  main: {
    height: '100%'
  },
});
