import { Modal, Row, Alert, Column, Text } from 'native-base';
import React, { useRef, useContext, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import get from 'lodash/get';
import UiContext from '../../UiContext';
import { useIsTabLayout } from '../../utils';
import { MapScreen } from '../MapScreen';
import { ContentCards } from '../ContentCards';

function ErrorModal(props) {
  const initialRef = useRef(null);
  const uiContext = useContext(UiContext);
  const navigation = useNavigation();
  const route = useRoute();
  const [showModal, setShowModal] = useState(true);
  const isTabLayout = useIsTabLayout();
  navigation.addListener('focus', () => {
    setShowModal(true);
  });
  navigation.addListener('blur', () => {
    setShowModal(false);
  });
  const closeModal = ()=>navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Explore', { ...route.params });
  const errorBanners = get(uiContext, 'banners', []).filter(banner => banner.type === 'error');
  return (
      <Row style={styles.main}>
        <style>
          {`div[role="dialog"] {
            max-height: none !important;
          }`}
        </style>
        <MapScreen {...props} />
        <ContentCards {...props} />
        <Modal initialFocusRef={initialRef} isOpen={showModal} onClose={closeModal} borderRadius={0} size="full" safeAreaTop={true} style={styles.wrapper} bg='primary.700' color='primary.300' _text={{
              color: 'primary.300',
            }}>
          <Modal.Content bg='primary.700' color='primary.300' _text={{
              color: 'primary.300',
            }} borderRadius={0} style={isTabLayout ? styles.contentSmall : styles.content}>
            <Modal.Header style={styles.modalHeader} bg='primary.700' _text={{
              color: 'primary.300',
            }}>
              <Modal.CloseButton style={styles.closeButton} />
            </Modal.Header>
            <Modal.Body style={styles.modalBody} bg='primary.700' color='primary.300' _text={{
              color: 'primary.300',
            }}>
            {errorBanners.map((banner, i) => {
                return (
                  <Alert style={styles.banner} status='error' key={banner.text + i}>
                    <Column space={2} flexShrink={1} w='100%'>
                      <Row flexShrink={1} space={2} justifyContent='space-between'>
                        <Row space={2} flexShrink={1}>
                          <Alert.Icon mt='1' />
                          <Text fontSize='md' color='coolGray.800'>
                            {banner.text}
                          </Text>
                        </Row>
                      </Row>
                    </Column>
                  </Alert>
                );
              })}
            </Modal.Body>
          </Modal.Content>
        </Modal>
      </Row>
    );
}

export default ErrorModal;

const styles = StyleSheet.create({
  main: {
    height: '100%'
  },
  wrapper: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
    borderTopColor: 'transparent',
  },
  modalHeader: {
    height: 40,
    padding: 0,
    paddingTop: 0,
  },
  closeButton: {
    padding: 0,
  },
  content: {
    position: 'absolute',
    top: 20,
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '980px',
    margin: 'auto',
  },
  contentSmall: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    maxHeight: '100%',
  },
  modalBody: {
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  }
});

