import React from 'react';
import { Row, Column, Button, Text } from 'native-base';
import LogoIcon from './LogoIcon';
import { StyleSheet, Linking } from 'react-native';
import { useLinkTo } from '@react-navigation/native';

function FooterLink(props) {
  const { to, children, ...rest } = props;
  const linkTo = useLinkTo();
  const isExternal = to.startsWith('http');
  const isSmall = props.size && props.size === 'sm';
  return (
    <Button
      variant={isSmall?'cclink-footer-small':'cclink-footer'}
      style={styles.button}
      onPress={() => {
        if (isExternal) {
          Linking.openURL(to);
        } else {
          linkTo(to);
        }
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

export function Footer(props) {
  const isEmbedOrScreenshot = window.location.pathname.match(/^\/(embed|screenshot)/i);
  return (
   <React.Fragment>
       {!isEmbedOrScreenshot && <Row bg='primary.900' color='white' justifyItems='center' style={styles.header}>
        <Column alignItems='flex-start'>
          <Row alignItems='center'>
            <LogoIcon size='small' color='white' {...props} />
            <Text style={styles.copyright}>Copyright &copy; {new Date().getFullYear()} Climate Central</Text>
            <FooterLink size={'sm'} to='https://www.climatecentral.org/what-we-do/legal#content_licensing'>Terms of Use</FooterLink>
            <FooterLink size={'sm'} to='https://sealevel.climatecentral.org/about/disclaimer'>Disclaimer</FooterLink>
            <FooterLink size={'sm'} to='https://www.climatecentral.org/privacy-policy'>Privacy Policy</FooterLink>
            <FooterLink size={'sm'} to='https://sealevel.climatecentral.org/about/contact'>Contact Us</FooterLink>
          </Row>
        </Column>
        <Column flex={1} alignItems='flex-end'>
          <Row>
            <FooterLink to='https://www.climatecentral.org/what-we-do'>About Us</FooterLink>
            <FooterLink to='https://www.climatecentral.org/list-signup'>Newsletter Signup</FooterLink>
            <FooterLink to='https://giving.climatecentral.org/give/155650/#!/donation/checkout'>Support Our Work</FooterLink>
            <FooterLink to='https://go.climatecentral.org/products/'>Commercial Solutions</FooterLink>
          </Row>
        </Column>
      </Row>}
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  header: {
    padding: '8px'
  },
  copyright: {
    whiteSpace: 'nowrap',
    marginLeft: '8px',
    fontSize: '12px',
  },
  button: {
    padding: '0px',
    paddingLeft: '8px',
    paddingRight: '8px',
  }
});
