import * as React from 'react';
import { StyleSheet } from 'react-native';
import { View, Pressable, Skeleton } from 'native-base';
import { WarmBox } from './WarmBox';
import cardBackground from '../assets/card-bg.png';
import { renderTemplate } from '../utils';

const ContentCard = ({ card, show, background, isFull }) => {
  if (!card) return <View style={styles.wrapper}>
    <View style={styles.placeholder}>
      <Skeleton.Text px="4" lines="4" />
    </View>
  </View>;
  return (
    <Pressable onPress={show}>
      {({ isHovered }) => {
        return (
          <View style={isHovered ? styles.hoverWrapper : styles.wrapper}>
            <WarmBox style={isFull ? styles.fullCard : styles.card} backgroundImage={background || cardBackground}>
              {renderTemplate(card, false)}
            </WarmBox>
          </View>)
      }}</Pressable>
  );
};

export default ContentCard;

const styles = StyleSheet.create({
  wrapper: {
    marginTop: 0,
    marginRight: 15,
    marginBottom: 20,
    marginLeft: 0,
  },
  hoverWrapper: {
    marginTop: 0,
    marginRight: 15,
    marginBottom: 20,
    marginLeft: 0,
    transform: [{ translateY: 5 }],
  },
  card: {
    width: 210,
    height: 205,
    position: 'relative',
  },
  placeholder: {
    width: 210,
    height: 205,
    backgroundColor: 'rgba(230, 234, 237, 0.5)',
    position: 'relative',
    padding: 20,
  },
  fullCard: {
    width: '100%',
    height: 500,
    position: 'relative',
  }
});
