import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { Icon, Row } from 'native-base';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import UiContext from '../UiContext';
import { MapScreen } from './MapScreen';
import { ContentCards } from './ContentCards';
import { TabBar } from './TabBar';
import URLUpdater from './URLUpdater';
import { getURLParamsFromNestedSettings, useIsTabLayout } from '../utils';
import FocusAwareStatusBar from './FocusAwareStatusBar';

const Tab = createBottomTabNavigator();

export const ExploreScreen = props => {
  const uiContext = useContext(UiContext);
  const isTabLayout = useIsTabLayout();
  const params = getURLParamsFromNestedSettings(uiContext.explore);
  return (
    <URLUpdater params={params}>
      {isTabLayout ? (
        <Tab.Navigator screenOptions={{ headerShown: false }} tabBar={props => <TabBar {...props} />} initialRouteName='Maps'>
          <Tab.Screen
            name='Maps'
            component={MapScreen}
            options={() => {
              return { tabBarIcon: () => <Icon as={MaterialCommunityIcons} name='map' size={18} /> };
            }}
            initialParams={getURLParamsFromNestedSettings(uiContext.explore)}
          />
          <Tab.Screen
            name='Stats'
            component={ContentCards}
            initialParams={getURLParamsFromNestedSettings(uiContext.explore)}
            options={{ tabBarIcon: () => <Icon as={MaterialCommunityIcons} name='view-grid' size={18} /> }}
          />
        </Tab.Navigator>
      ) : (
        <Row style={styles.main}>
          <FocusAwareStatusBar barStyle='dark-content' backgroundColor='white' />
          <MapScreen {...props} />
          <ContentCards {...props} />
        </Row>
      )}
    </URLUpdater>
  );
};

const styles = StyleSheet.create({
  main: {
    height: '100%'
  },
});
