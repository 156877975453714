import { Modal, Button, Row, Column, Icon, Text, Heading } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet } from 'react-native';
import { useIsTabLayout } from '../../utils';

function DownloadTypeModal({ show, toggle, data, next }) {
  const open = (url) => window.open(url, '_blank').focus();
  const isTabLayout = useIsTabLayout();
  return (
    <Modal isOpen={show} onClose={toggle} borderRadius={0}>
      <Modal.Content maxWidth="550" bg='primary.100' borderRadius={0}>
        <Modal.CloseButton />
        <Modal.Header bg='primary.300' color='primary.900' _text={{
          color: 'primary.900',
        }}>Choose Your Download</Modal.Header>
        <Modal.Body>
          <Row direction={isTabLayout ? 'column':'row'}>
            <Column style={styles.button}>
              <Button
                variant='outline-lightbg'
                startIcon={<Icon as={MaterialCommunityIcons} name='download' size={18} />}
                onPress={next}
              >Map Image</Button>
            </Column>
            <Text style={styles.description} color='primary.700'>PNG of this map</Text>
          </Row>
          <Heading variant='subhead' size='sm' color='primary.700'>Commercial Solutions</Heading>
          <Row direction={isTabLayout ? 'column':'row'}>
            <Column style={styles.button}>
            <Button
              variant='outline-lightbg'
              startIcon={<Icon as={MaterialCommunityIcons} name='download' size={18} />}
              onPress={()=>open('https://go.climatecentral.org/coastaldem/?utm_campaign=new_crf&utm_content=CoastalDEM-button')}
            >Land Elevation Data</Button>
            </Column>
            <Text style={styles.description} color='primary.700'>CoastalDEM®: Climate Central's proprietary, more accurate Digital Elevation Model for coastal areas (no-cost research licenses available)</Text>
          </Row>
          <Row direction={isTabLayout ? 'column':'row'}>
            <Column style={styles.button}>
            <Button
              variant='outline-lightbg'
              startIcon={<Icon as={MaterialCommunityIcons} name='download' size={18} />}
              onPress={()=>open('https://go.climatecentral.org/layers/?utm_campaign=new_crf&utm_content=floodlayers-button')}
            >Flood Layers</Button>
            </Column>
            <Text style={styles.description} color='primary.700'>Global GIS map layers illustrating the extent and depth of sea level rise and coastal flooding</Text>
          </Row>
          <Row direction={isTabLayout ? 'column':'row'}>
            <Column style={styles.button}>
            <Button
              variant='outline-lightbg'
              startIcon={<Icon as={MaterialCommunityIcons} name='download' size={18} />}
              onPress={()=>open('https://go.climatecentral.org/portfolio/?utm_campaign=new_crf&utm_content=floodanalysis-button')}
            >Flood Analysis</Button>
            </Column>
            <Text style={styles.description} color='primary.700'>Climate Central's Portfolio Analysis Tool quantifies coastal flood risk to the coastal locations vou care about</Text>
          </Row>
        </Modal.Body>
        <Modal.Footer bg='primary.100'>
          <Button.Group space={2}>
            <Button
              variant='cclink-light'
              style={styles.button}
              onPress={() => toggle()}
            >
              Cancel
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

export default DownloadTypeModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(0, 0, 0)',
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    borderRadius: 2,
    borderWidth: 0,
    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    left: 10,
  },
  button: {
    flex: 1,
    marginBottom: 20
  },
  description: {
    flex: 1,
    marginLeft: 20,
    marginBottom: 20
  }
});
