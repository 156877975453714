import { NativeBaseProvider, Text, View } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import theme from '../nativebase/theme';

function OverlayLegend({ data, isScreenshot }) {
  if (!data || data.type !== 'continuous') return null;
  const colors = data.colors || [];
  const values = data.values || [];
  const labelWidth = 150 / (values.length);
  const bottom = isScreenshot ? 10 : 10;
  return (
    <NativeBaseProvider theme={theme}>
      <View style={{ ...styles.container, bottom }}>
        {data.title && <Text style={styles.title} fontSize="sm" mb={2} color='primary.700'>{data.title}</Text>}
        <View style={{...styles.gradient, backgroundImage: `linear-gradient(to right, ${colors.join(', ')})`}} />
        <View style={styles.label}>
        {values.map(value => (
          <View style={styles.legendValue} key={value} width={labelWidth}>{value}</View>
        ))}
        </View>
      </View>
    </NativeBaseProvider>
  );
}

export default OverlayLegend;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(0, 0, 0)',
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    borderRadius: 2,
    borderWidth: 0,
    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    left: 10,
    marginTop: 10,
  },
  gradient: {
    border: '1px solid #333333',
    height: '1.75em',
    display: 'block',
    width: 150
  },
  title: {
    fontWeight: 'bold',
  },
  label: {
    marginTop: 5,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  legendValue: {
    display: 'inline-block',
    textAlign: 'center',
    color: '#666'
  }
});
