import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Button, Row, Column } from 'native-base';

export const TabBar = ({ state, descriptors, navigation }) => {
  return (
    <Row space={0} direction='row' variant='primary' style={styles.tabbar} _dark={{ bg: 'primary.900' }} justifyContent='center'>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const renderIcon = options.tabBarIcon || (() => {});
        const label = options.tabBarLabel !== undefined ? options.tabBarLabel : options.title !== undefined ? options.title : route.name;
        const isFocused = state.index === index;
        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
          });
          if (!isFocused && !event.defaultPrevented) navigation.navigate(route.name);
        };
        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          });
        };
        return (
          <Column key={route.key} borderTopColor={isFocused ? 'warm.500' : 'transparent.none'} borderTopWidth={5}>
            <Button
              variant='cc-tabbar'
              size='lg'
              onPress={onPress}
              onLongPress={onLongPress}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              accessibilityStates={isFocused ? ['selected'] : []}
              accessibilityRole='button'
              borderRadius={0}
              {...(isFocused
                ? {
                    _dark: { _text: { color: 'primary.100' }, _icon: { color: 'primary.100' } },
                    _light: { _text: { color: 'tertiary.800' }, _icon: { color: 'tertiary.800' } },
                  }
                : {})}
              leftIcon={renderIcon()}
            >
              {label}
            </Button>
          </Column>
        );
      })}
    </Row>
  );
};

const styles = StyleSheet.create({
  tabbar: { paddingLeft: 60, paddingRight: 60, paddingTop: 0, paddingBottom: 0 },
});
