module.exports = {
  expo: {
    name: 'Coastal Risk Finder',
    slug: 'coastal-risk-finder',
    scheme: 'coastal-risk-finder',
    version: '1.0.0',
    orientation: 'portrait',
    icon: './assets/icon.png',
    userInterfaceStyle: 'dark',
    splash: {
      image: './assets/splash.png',
      resizeMode: 'contain',
      backgroundColor: '#ffffff',
    },
    updates: {
      fallbackToCacheTimeout: 0,
    },
    assetBundlePatterns: ['**/*'],
    ios: {
      supportsTablet: true,
      userInterfaceStyle: 'automatic',
      bundleIdentifier: 'org.climatecentral.coastal-risk-finder',
      config: {
        googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY_IOS,
      },
    },
    android: {
      userInterfaceStyle: 'automatic',
      adaptiveIcon: {
        foregroundImage: './assets/adaptive-icon.png',
        backgroundColor: '#FFFFFF',
      },
      package: 'org.climatecentral.coastal-risk-finder',
    },
    web: {
      favicon: './assets/favicon.png',
      config: {
        googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY_WEB,
      },
    },
    extra: {
      eas: {
        projectId: process.env.EAS_PROJECT_ID,
      },
      defaultLocation: {
        latitude: 40.7830603,
        longitude: -73.9712488,
      },
      MAX_ZOOM: 17,
      MIN_ZOOM: 2,
    },
  },
  // TODO: We probably don't need all of this, but it's a good starting point
  app: {
    baseURL: '',
    // List of potentially valid map settings
    mapSettings: [
      'annual_accretion_mm',
      'antarctica',
      'area_metric',
      'basemap',
      'boundaries',
      'contiguous',
      'eafre',
      'elevation_model',
      'esl_model',
      'estimate',
      'flood_metric',
      'flood_threshold',
      'forecast_year',
      'greenland',
      'housing_type',
      'ice_loss_level',
      'ice_model',
      'ice_sheet',
      'interval',
      'lockin_model',
      'map_type',
      'migration',
      'pathway',
      'percentile',
      'return_level',
      'rl_model',
      'rsl_model',
      'slr_model',
      'slr_year',
      'storm_surge',
      'sv_metric',
      'temperature_rise',
      'temperature_unit',
      'theme',
      'timeframe',
      'warming_comparison',
      'water_level',
      'water_unit',
      'years_ago',
    ],
    permissions: {
      master: {
        master: true,
        owner: true,
        admin: true,
        user: true,
      },
      owner: {
        master: false,
        owner: true,
        admin: true,
        user: true,
      },
      admin: {
        master: false,
        owner: false,
        admin: true,
        user: true,
      },
      user: {
        master: false,
        owner: false,
        admin: false,
        user: true,
      },
    },
  },
};
