import React from 'react';
import { Column, Row, Heading } from 'native-base';
import { Remark } from 'react-remark';

const DataSources = ({ content, anchorRef }) => {
  if (!content) return;
  return (
    <Column justifyContent='flex-start' maxWidth="800px">
      <Row pt="40px">
        <Heading ref={anchorRef} variant='h4' size='md'>Data &amp; Sources</Heading>
      </Row>
      <Row>
        <style>
          {`
            .mdStyle {
              color: white;
              font-family: 'Maitree_400Regular';
              max-width: 100%;
            }
            .mdStyle a, .mdStyle a:visited, .mdStyle a:active, .mdStyle a:hover, .mdStyle a:focus, .mdStyle a:link {
              color: #FEE003;
            }
          `}
        </style>
        <div className='mdStyle'>
          <Remark 
            rehypeReactOptions={{
              components: {
                a: (props) => <a target="_blank" rel="noopener noreferrer" {...props} />,
              },
            }}
          >
            {content}
          </Remark>
        </div>
      </Row>
    </Column>
  );
};

export default DataSources;
